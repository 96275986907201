import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from 'app/store'

import * as layoutApi from 'api/layout';
import * as types from 'types';

export interface DimensionState {
	size: types.sizeme;
	scale: number;
	isZoomedIn: boolean;
	loading: boolean;
	rowHeight: number;
}

const initialState: DimensionState = {
	size: {width: 1380, height: 0},
	scale: 1,
	isZoomedIn: false,
	loading: true,
	rowHeight: 25,
}

export const dimensionSlice = createSlice({
  name: 'dimension',
  initialState,
  reducers: {
		setSize(state, action: PayloadAction<{width: number, height: number}>) {
			//* height is unused, keep 0 for now
			state.size = {width: Math.max(action.payload.width - 40, 800), height: 0}

			if (action.payload.height > 800) {
				state.rowHeight = 30;
			} else {
				state.rowHeight = 25;
			}
		},
		setScale(state, action: PayloadAction<number>) {
			state.scale = action.payload;
			if (action.payload === 1) {
				state.isZoomedIn = false;
			} else {
				state.isZoomedIn = true;
			}
		},
		setLoading(state, action: PayloadAction<boolean>) {
			state.loading = action.payload
		},
		setRowHeight(state, action: PayloadAction<number>) {
			state.rowHeight = action.payload
		},
  },
})


export const {
	setSize,
	setScale,
	setLoading,
	setRowHeight,
} = dimensionSlice.actions;

export const selectSize = (state: RootState) => state.dimension.size
export const selectScale = (state: RootState) => state.dimension.scale
export const selectZoomedIn = (state: RootState) => state.dimension.isZoomedIn
export const selectLoading = (state: RootState) => state.dimension.loading
export const selectRowHeight = (state: RootState) => state.dimension.rowHeight;

export default dimensionSlice.reducer