import { createSlice, PayloadAction, createAsyncThunk, Store } from '@reduxjs/toolkit'
import type { RootState } from 'app/store'

// import anonymousUser from 'assets/images/icon-user-default.svg';

import * as authApi from 'api/auth';
import * as userApi from 'api/user';
import * as imageApi from 'api/image';

import * as types from 'types';
import { SignupFormInputs } from 'components/forms/SignupForm';


export const login = createAsyncThunk(
  "user/login",
  async (credentials: types.StringObject, { getState, requestId, rejectWithValue, dispatch }) => {
		//@ts-ignore
    const { currentRequestId, loading } = getState().user;

    // do nothing if another request is being processed
    if (loading !== "pending" || requestId !== currentRequestId) {
      return;
    }

    try {
      const { data } = await authApi.login(credentials);
			if (data.data.avatarId) dispatch(fetchAvatar(data.data.avatarId));
			else dispatch(setAvatar(''));
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const signup = createAsyncThunk(
  "user/signup",
  async (credentials: SignupFormInputs, { getState, requestId, rejectWithValue, dispatch }) => {
		//@ts-ignore
    const { currentRequestId, loading } = getState().user;

    // do nothing if another request is being processed
    if (loading !== "pending" || requestId !== currentRequestId) {
      return;
    }

    try {
			console.log(credentials);
      const { data } = await authApi.signup(credentials);
			console.log(data);
			if (data.data.avatarId) dispatch(fetchAvatar(data.data.avatarId));
			else dispatch(setAvatar(''));
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

/** Get user session from DB if it exists */
export const getSession = createAsyncThunk(
	"user/get",
	async (_, { dispatch }) => {
		try {
			const { data } = await authApi.getUserSession();

			if (data.data) {
				const { data: userData } = await userApi.fetchUserData();
				// console.log(data.data);
				dispatch(setUserState(userData));
				dispatch(setLoggedIn(true));
				if (userData.avatarId) dispatch(fetchAvatar(userData.avatarId));
				else dispatch(setAvatar(''));
			} else {
				console.log('No user session found');
			}
			dispatch(setInitialized(true));
		} catch (error) {
			console.error(error);
		}
	}
);

/** Log out front and backend */
export const logoutAsync = createAsyncThunk(
	"user/logout",
	async (_, { dispatch }) => {
		try {
			await authApi.logout();
			dispatch(logout());
		} catch (error) {
			console.error(error);
		}
	}
)


/** Fetch user avatar */
export const fetchAvatar = createAsyncThunk(
	"user/fetchAvatar",
	async (id: string, { dispatch, rejectWithValue }) => {
		console.log('fetchAvatar');
		if (!id) return rejectWithValue('No id provided');
		try {
			const avatar = await imageApi.fetchImage(id);
			console.log(avatar);
			dispatch(setAvatar(avatar.data.data));
		} catch (error) {
			console.error(error);
		}
	}
)


//@ts-ignore
const pending = (state/* : RootState */, action): void => {
  console.log("pending");
  if (state.loading === "idle") {
    state.loading = "pending";
    state.currentRequestId = action.meta.requestId;
  }
};

/** Adjust redux state to reflect successful authentication (login/signup)  */
//@ts-ignore
const fulfilled = (state/* : RootState */, action) => {
  console.log("fulfilled");
  const { requestId } = action.meta;

  if (state.loading === "pending" && state.currentRequestId === requestId) {
    state.loading = "idle";
    state.currentRequestId = undefined;

    // setting this flag with render the AuthenticatedApp
    state.isLoggedIn = true;

    state.data = action.payload.data;
  }
};

//@ts-ignore
const rejected = (state/* : RootState */, action) => {
  console.log("rejected");
  const { requestId } = action.meta;
  if (state.loading === "pending" && state.currentRequestId === requestId) {
    state.loading = "idle";
    state.error = action.payload;
    state.currentRequestId = undefined;
  }
};


const nullUser: types.User = {
  _id: undefined,
  email: undefined,
  firstName: undefined,
  lastName: undefined,
	userType: undefined,
	organizationIds: [],
	avatarId: undefined,
};

export const initialState: types.UserState = {
	data: nullUser,
  loading: "idle",
	initialized: false,
  isLoggedIn: false,
  currentRequestId: undefined,
  error: undefined,
	avatar: undefined,
}

//@ts-ignore
export const userSlice = createSlice({
  name: 'user',
  initialState,
	reducers: {
    clearErrors(state) {
      state.loading = "idle";
      state.currentRequestId = undefined;
      state.error = undefined;
    },
    logout(state) {
      state.data = nullUser;
      state.isLoggedIn = false;
			state.avatar = undefined;
			// for (let i in state) {
			// 	//@ts-ignore
			// 	state[i] = initialState[i];
			// }
    },
		setUserState(state, action: PayloadAction<types.User>) {
			state.data = action.payload;
		},
		setLoggedIn(state, action: PayloadAction<boolean>) {
			state.isLoggedIn = action.payload;
		},
		setInitialized(state, action: PayloadAction<boolean>) {
			state.initialized = action.payload;
		},
		setAvatar(state, action: PayloadAction<string>) {
			state.avatar = action.payload;
		}
  },
  extraReducers: {
    [login.pending.type]: pending,
    [login.fulfilled.type]: fulfilled,
    [login.rejected.type]: rejected,

    [signup.pending.type]: pending,
    [signup.fulfilled.type]: fulfilled,
    [signup.rejected.type]: rejected,
  },
})

export const selectUser = (state: RootState) => state.user;
export const selectError = (state: RootState) => state.user.error;
export const selectLoggedIn = (state: RootState) => state.user.isLoggedIn;
export const selectInitialized = (state: RootState) => state.user.initialized;
export const selectAvatar = (state: RootState) => state.user.avatar;

export const { clearErrors, logout, setUserState, setLoggedIn, setInitialized, setAvatar } = userSlice.actions;

export default userSlice.reducer;