import React, { useState, useEffect } from 'react';
import GridLayout from "react-grid-layout";

// import Popup from '../Popup';
import GridItemPopup from '../Popup/GridItemPopup';

import GridItemEditModal from './GridItemEditModal';

// Redux
import { useAppSelector, useAppDispatch } from 'app/hooks'
import { selectDragging, drawArrow, selectActionState, setActionState, selectCurrentArrow, setCurrentArrow, updateRow, editItem } from 'slices/projectSlice';
import { setMovingItem } from 'slices/miscSlice';

// images
import { ReactComponent as IconArrows } from 'assets/images/icon-arrows.svg';
import { ReactComponent as IconTrash } from 'assets/images/icon-trash.svg';
import { ReactComponent as IconCopy } from 'assets/images/icon-copy.svg';
import { ReactComponent as IconEdit } from 'assets/images/icon-edit.svg';

import * as types from "types";
import './style.scss';

type GridItemProps = {
	rowData: types.GridRow;
	id: string;
	className?: string;
	/** title of this grid item */
	title: string;
	layout?: GridLayout.Layout[];
	size: types.sizeme;
	/** title of current row */
	container: string;
	/** if specified, then override container as title of current row */
	row?: string;
	dataGrid?: GridLayout.Layout;
	children: React.ReactNode;
	draggable?: boolean;
	style?: React.CSSProperties;
	itemData: types.GridItem;
	onDragStart?: React.DragEventHandler<HTMLDivElement>;
};

const GridItem = React.forwardRef<HTMLDivElement, GridItemProps>(({ rowData, id, children, className, title, layout, size, container, row, dataGrid, draggable, style, itemData, onDragStart, ...props }, ref) => {
	// /** Whether moving item between grids */
	// const [isMoving, setIsMoving] = useState(false);

	const [showPopup, setShowPopup] = useState(false);
	const [popupRoot, setPopupRoot] = useState<HTMLElement | null>(null);
	const [styleState, setStyleState] = useState<React.CSSProperties>({});
	const [showEditModal, setShowEditModal] = useState(false);
	const [confirmDelete, setConfirmDelete] = useState(false);

	const isDragging = useAppSelector(selectDragging);
	const actionState = useAppSelector(selectActionState);
	const currentArrow = useAppSelector(selectCurrentArrow);
	// const rowData = useAppSelector(state => selectRow(state, row ? row : container));
	const dispatch = useAppDispatch();

	useEffect(() => {
		// hide items outside of minimized area
		if (rowData.isMinimized && dataGrid && dataGrid.y + dataGrid.h > 5) {
			setStyleState({
				visibility: 'hidden',
			})
			// dispatch(setHasHiddenItems({row: row ? row : container, nestedKey: id, hasHiddenItems: true}))
		} else {
			setStyleState({
				visibility: 'visible',
			})
		}

		let newPopupRoot = null;

		// set popup root
		if (rowData.type === 'standard') {
			newPopupRoot = document.getElementById(rowData._id);
		} else if (rowData.type === 'nested') {
			newPopupRoot = document.getElementById(container);
		} else {
			console.error('Invalid row type');
		}

		setPopupRoot(newPopupRoot);

	}, [rowData]);

	const handleClick = (e: React.MouseEvent<HTMLElement>) => {
		e.preventDefault();
		// if drawing an arrow, set this element as the endpoint and draw the arrow
		if (actionState === 'drawArrow') {
			const updatedArrow = {...currentArrow}
			updatedArrow.end = id;
			dispatch(setCurrentArrow(updatedArrow));

			const updatedRow: types.GridRow = {...rowData};

			console.log(updatedRow.arrows);

			// if arrow array exists, add to it
			if (updatedRow.arrows) {
				const newArrows = [...updatedRow.arrows];

				newArrows.push({
					start: updatedArrow.start,
					end: updatedArrow.end,
					key: `${updatedArrow.start}-${updatedArrow.end}`
				});
				updatedRow.arrows = newArrows;

			// if arrow array does not exist, create it
			} else {
				updatedRow.arrows = [{
					start: updatedArrow.start,
					end: updatedArrow.end,
					key: `${updatedArrow.start}-${updatedArrow.end}`
				}]
			}

			dispatch(updateRow(updatedRow));

			// reset to default state after drawing arrow
			dispatch(setActionState('normal'));

		// otherwise, if not dragging element, open popup
		} else if (!isDragging) {
			setShowPopup(!showPopup);
		}
	};

	const closePopup = () => {
		setShowPopup(false);
	}

	const handleDrawArrow = () => {
		setShowPopup(false);
		dispatch(drawArrow({ start: id }));
	}

	const handleMove = () => {
		setShowPopup(false);
		setShowEditModal(false);
		// setIsMoving(true);

		dispatch(setMovingItem(itemData!));
	}

	const handleEdit = () => {
		// setShowEditModal(true);
		// setShowPopup(false);
	}

	const openEditModal = () => {
		setShowEditModal(true);
		setShowPopup(false);
	}

	const closeEditModal = () => {
		setShowEditModal(false);
	}

	/** Delete this item */
	const handleDelete = () => {
		dispatch(editItem({
			_id: itemData._id,
			page: itemData.page,
			row: itemData.row,
			subPhase: itemData.subPhase,
			action: 'remove',
		}));
	}

	const controls = <>
		<button
			className={`btn btn-control ${confirmDelete ? 'active' : ''}`}
			title="Delete Item"
			aria-label="Delete Item"
			onClick={() => {setConfirmDelete(!confirmDelete)}}
		>
			{/* <FontAwesomeIcon icon={faArrowsAlt} /> */}
			<IconTrash />
		</button>
		{itemData?.row == 'Actions' && <button
			className="btn btn-control btn-move"
			title="Move to different grid"
			aria-label="Move to different grid"
			onClick={handleMove}
		>
			{/* <FontAwesomeIcon icon={faArrowsAlt} /> */}
			<IconArrows />
		</button>}
		<button
			className="btn btn-control btn-disabled"
			title="Copy Item"
			aria-label="Copy Item"
			// onClick={handleMove}
		>
			{/* <FontAwesomeIcon icon={faArrowsAlt} /> */}
			<IconCopy />
		</button>
		<button
			className="btn btn-control btn-edit"
			title="Edit"
			aria-label="Edit"
			onClick={openEditModal}
		>
			{/* <FontAwesomeIcon icon={faArrowsAlt} /> */}
			<IconEdit />
		</button>
	</>


	return (
		<>
			<div
				className={`GridItem ${showPopup && 'active'} ${container === 'Phases' ? 'GridItem-Phases' : ''} ${className}`}
				// key={key}
				id={id}
				ref={ref}
				data-grid={dataGrid}
				draggable={draggable}
				onDragStart={onDragStart}
				style={{...style, ...styleState}}
				{...props}
			>
				{container !== 'Phases' &&
					<button onClick={handleClick} className="modalButton" title={title}></button>
				}

				{(showPopup && popupRoot) && 
					<GridItemPopup
						title={title}
						parentId={id}
						layout={layout && layout} // for rerender
						size={size}
						// rowData={rowData}
						popupRoot={popupRoot}
						itemData={itemData}
						closePopup={closePopup}
						drawArrow={handleDrawArrow}
						handleMove={handleMove}
						onEdit={handleEdit}
						openModal={openEditModal}
						controls={controls}
						confirmDelete={confirmDelete}
						handleDelete={handleDelete}
					/>
				}

				<div className="content">
					{children}
				</div>

				{showEditModal &&
					<GridItemEditModal 
						openModal={openEditModal}
						closeModal={closeEditModal}
						modalIsOpen={showEditModal}
						itemData={itemData}
						controls={controls}
						confirmDelete={confirmDelete}
						handleDelete={handleDelete}
						drawArrow={handleDrawArrow}
					/>
				}

			</div>
			
			{/* if moving this item between grids, render a draggable div representing this item which can be dropped into another grid */}
			{/* {isMoving && 
				<div
					className="droppable-element"
					draggable={true}
					unselectable="on"
					// this is a hack for firefox
					// Firefox requires some kind of initialization
					// which we can do by adding this attribute
					// @see https://bugzilla.mozilla.org/show_bug.cgi?id=568313
					onDragStart={e => e.dataTransfer.setData("text/plain", "")}
				>
					Droppable Element
				</div> 
			} */}
		</>
	)
});

export default GridItem;
