import React, { useLayoutEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";

import { ImageListType } from 'react-images-uploading';
import ImageUpload from './ImageUpload';

// Redux
import { useAppSelector, useAppDispatch } from 'app/hooks'
import { signup, selectError, clearErrors } from 'slices/userSlice';

import anonymousUser from 'assets/images/anonymousUser.png';

export type SignupFormInputs = {
	firstName: string;
	lastName: string;
	email: string;
	email2: string;
	password: string;
	password2: string;
	image?: string;
}

const SignupForm = () => {
	const [finalImage, setFinalImage] = useState('');

	const error = useAppSelector(selectError);
	const dispatch = useAppDispatch();

	useLayoutEffect(() => {
    // cleanup error state in Redux store on unmount
    return () => {
      dispatch(clearErrors());
    };
  }, [dispatch]);

	const { register, handleSubmit, formState: { errors }, getValues } = useForm<SignupFormInputs>({
		mode: "onTouched",
	});

	const handleSignup = (data: SignupFormInputs) => {
		const dataToSend = {...data}
		// if (images.length) {
		// 	console.log(images[0].dataURL);
		// 	dataToSend.image = images[0].dataURL
		// }
		if (finalImage) {
			// console.log(finalImage);
			dataToSend.image = finalImage;
		}

		dispatch(signup(dataToSend));
	}

	return (
		<>
			<form onSubmit={handleSubmit(handleSignup)} className="form form-signup">
				<h2>Create an account</h2>

				<div className="inputs">
					<div className="input span-1">
						<label htmlFor="firstName" className="sr-only">First Name</label>
						<input
							type="text"
							autoComplete="given-name"
							id="firstName"
							placeholder="First Name"
							{...register("firstName", { required: true })}
						/>
						{errors.firstName && <span className="error">* This field is required</span>}
					</div>
					<div className="input span-1">
						<label htmlFor="lastName" className="sr-only">Last Name</label>
						<input
							type="text"
							autoComplete="family-name"
							id="lastName"
							placeholder="Last Name"
							{...register("lastName", { required: true })}
						/>
						{errors.lastName && <span className="error">* This field is required</span>}
					</div>
					<div className="input">
						<label htmlFor="email" className="sr-only">Email</label>
						<input
							type="email"
							autoComplete="email"
							id="email"
							placeholder="Email"
							{...register("email", { required: true, pattern: /^\S+@\S+\.\S{2,}$/ })}
						/>
						{errors.email?.type === 'required' && <span className="error">* This field is required</span>}
						{errors.email?.type === 'pattern' && <span className="error">* Please enter a valid e-mail</span>}
					</div>
					<div className="input">
						<label htmlFor="email2" className="sr-only">Re-enter Email</label>
						<input
							type="email"
							autoComplete="email"
							id="email2"
							placeholder="Re-enter Email"
							{...register("email2", { required: true, validate: (value) => value === getValues("email") })}
						/>
						{errors.email2?.type === 'required' && <span className="error">* This field is required</span>}
						{errors.email2?.type === 'validate' && <span className="error">* Email does not match</span>}

					</div>
					<div className="input">
						<label htmlFor="password" className="sr-only">Password</label>
						<input
							type="password"
							autoComplete="new-password"
							id="password"
							placeholder="Password"
							{...register("password", { required: true, minLength: 8 })}
						/>
						{errors.password?.type === 'required' && <span className="error">* This field is required</span>}
						{errors.password?.type === 'minLength' && <span className="error">* Password must be at least 8 characters</span>}
					</div>
					<div className="input">
						<label htmlFor="password2" className="sr-only">Re-enter Password</label>
						<input
							type="password"
							autoComplete="new-password"
							id="password2"
							placeholder="Re-enter Password"
							{...register("password2", { required: true, validate: (value) => value === getValues("password") })}
							/>
							{errors.password2?.type === 'required' && <span className="error">* This field is required</span>}
							{errors.password2?.type === 'validate' && <span className="error">* Password does not match</span>}
					</div>

				</div>

				<h3 className="profilePicture">Profile Picture (optional):</h3>
				<ImageUpload
					image={finalImage}
					setImage={setFinalImage}
					placeholder={anonymousUser}
					round
				/>

				<button type="submit" className="btn btn-form">Sign Up {'>'}</button>

				{error && <span className="error">{error}</span>}

				<div className="links">
					<p>
						<Link to="/login" className="Link">Log in</Link>
					</p>
				</div>
			</form>
			<div className="pageBackground"></div>
		</>
	)
}

export default SignupForm;
