import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import GridLayout, { Responsive as ResponsiveGridLayout } from 'react-grid-layout';
import { SizeMe } from 'react-sizeme';

import GridItem from '../GridItem';

// Redux
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { selectColumns,/*  setRowAsync, */ setRow, updateRowHeight, setDragging, selectEditMode, setNestedLoading, selectNestedLoading/* , setHasHiddenItems */, updateRow, addItems } from 'slices/projectSlice';
import { setMovingItem, selectMovingItem/* , selectCurrentPopup */, selectCreatingItem, setCreatingItem, } from 'slices/miscSlice';
import { selectRowHeight, selectScale } from 'slices/dimensionSlice';


// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH, faPlus } from '@fortawesome/free-solid-svg-icons'

import * as types from 'types';
import './style.scss';
import GridItemEditModal from '../GridItem/GridItemEditModal';

type NestedGridProps = {
	children?: React.ReactNode;
	items?: React.ReactElement<types.GridItem>[];
	rowData: types.GridRow;
	key?: string;
	id: string;
	phase: string;
	subPhase: string;
	layout: GridLayout.Layout[];
	/** Title of row */
	title: string;
	zIndex?: number;
	style?: React.CSSProperties;
	className?: string;
	dataGrid: GridLayout.Layout;
};

const NestedGrid = React.forwardRef<HTMLDivElement, NestedGridProps>(({children, /* size, */ items, rowData, key, id, phase, subPhase, title, zIndex, style, layout, className, dataGrid, ...props}, ref) => {
	const scale = useAppSelector(selectScale);
	// const title = 'Activites';
	const editMode = useAppSelector(selectEditMode);

	// const rowData = useAppSelector(state => selectRow(state, title));
	// const allRowData = useAppSelector(selectAllRows);

	const movingItem = useAppSelector(selectMovingItem);
	const creatingItem = useAppSelector(selectCreatingItem);

	const rowHeight = useAppSelector(selectRowHeight);
	// const columnData = useAppSelector(selectColumns);

	// const currentPopup = useAppSelector(selectCurrentPopup);

	const dispatch = useAppDispatch();

	// const [thisZIndex, setThisZIndex] = useState(zIndex);

	const [hiddenItems, setHiddenItems] = useState(false);

	// const [showEditModal, setShowEditModal] = useState(false);
	const [newItem, setNewItem] = useState<types.GridItem>();

	// const [useStyle, setUseStyle] = useState({...style});

	// const [itemsToDisplay, setItemsToDisplay] = useState<React.ReactElement<types.GridItem>[]>([]);
	// const [hiddenItems, setHiddenItems] = useState<types.GridItem[]>();

	// const [layouts, setLayouts] = useState<ReactGridLayout.Layouts>({});
	// const [layout, setLayout] = useState<ReactGridLayout.Layout[]>([]);

	// console.log(rowData);
	// console.log(dataGrid);

	/** True if this component is on it's first render */
  const firstUpdate = useRef(true);

	useLayoutEffect(() => {
		// dispatch(setNestedLoading(false));
		if (firstUpdate.current) {
			dispatch(setNestedLoading(false));
			firstUpdate.current = false;
			return;
		}
	})

	// refresh rows on edit mode toggle to allow/disallow control
	useEffect(() => {
		dispatch(setRow({...rowData}))
	}, [editMode, scale])


	useEffect(() => {
		if (hasHiddenItems()) {
			setHiddenItems(true)
		} else {
			setHiddenItems(false)
		}
	}, [rowData.isMinimized, rowData.layout])


	// // set the zIndex of this grid higher if it currently has an active popup (so the popup isn't obscured by other grids)
	// useEffect(() => {
	// 	if (currentPopup?.popupRootId === id) {
	// 		setThisZIndex(601);
	// 	} else {
	// 		setThisZIndex(zIndex);
	// 	}
	// }, [currentPopup?.popupRootId])

	
	// const [newLayout, setNewLayout] = useState<GridLayout.Layout[]>();

	// useEffect(() => {
	// 	const updatedLayout = [...rowData.layout!];

	// 	updatedLayout.forEach((item, i) => {
	// 		const updatedItem = {...item};
	// 		if (rowData.isMinimized) {
	// 			updatedItem.h = 6;
	// 		}
	// 	})

	// 	setNewLayout([...layout]);

	// }, [rowData.isMinimized, rowData.layout])


	// // set items to display depending on if row is minimized or not
	// useEffect(() => {
	// 	const newItems: React.ReactElement<types.GridItem>[] = [];

	// 	/** layouts of the hidden items to be appended to data on update */
	// 	const newHiddenItems: types.GridItem[] = [];

	// 	// console.log(rowData.isMinimized);
	// 	// console.log(items);
	// 	items && React.Children.forEach(items, (item: React.ReactElement<types.GridItem>) => {
	// 		// console.log(item);
	// 		// if (rowData.isMinimized) {
	// 			//@ts-ignore
	// 			if (item.props.dataGrid.y < 5) {
	// 				newItems.push(item);
	// 			} else {
	// 				// for items outside of minimized grid, push to hidden items array (data to be merged on updates)
	// 				newHiddenItems.push({
	// 					key: item.key?.toString()!,
	// 					title: item.props.title,
	// 					//@ts-ignore
	// 					'data-grid': item.props.dataGrid,
	// 				});
	// 			}
	// 		// } else {
	// 			// newItems.push(item);
	// 		// }
	// 	})
	// 	console.log(newHiddenItems);

	// 	setHiddenItems(newHiddenItems);
	// 	setItemsToDisplay(newItems);
	// }, [rowData])



	// useEffect(() => {
	// 	// const newLayouts: ReactGridLayout.Layouts = {};

	// 	// const lgLayout: ReactGridLayout.Layout[] = [];
	// 	// const mdLayout: ReactGridLayout.Layout[] = [];
	// 	// const smLayout: ReactGridLayout.Layout[] = [];
	// 	// const xsLayout: ReactGridLayout.Layout[] = [];
	// 	// const xxsLayout: ReactGridLayout.Layout[] = [];

	// 	// items.forEach((item, index) => {
	// 	// 	lgLayout.push({i:`nested${index}`, x: index % 6, y: Math.floor(index / 6), w: 1, h: 1});
	// 	// 	mdLayout.push({i:`nested${index}`, x: index % 4, y: Math.floor(index / 4), w: 1, h: 1});
	// 	// 	smLayout.push({i:`nested${index}`, x: index % 3, y: Math.floor(index / 3), w: 1, h: 1});
	// 	// 	xsLayout.push({i:`nested${index}`, x: index % 2, y: Math.floor(index / 2), w: 1, h: 1});
	// 	// 	xxsLayout.push({i:`nested${index}`, x: index, y: index, w: 1, h: 1});
	// 	// });

	// 	// newLayouts.lg = [...lgLayout];
	// 	// newLayouts.md = [...mdLayout];
	// 	// newLayouts.sm = [...smLayout];
	// 	// newLayouts.xs = [...xsLayout];
	// 	// newLayouts.xxs = [...xxsLayout];

	// 	// setLayouts(newLayouts);

	// 	const newLayout: GridLayout.Layout[] = [];

	// 	items.forEach((item, index) => {
	// 		if (dataGrid.i === 'engage') {
	// 			newLayout.push({i:`nested${index}`, x: Math.floor(dataGrid.w / 3) * (index % 3), y: Math.floor(index / 6), w: Math.floor(dataGrid.w / 3), h: 1});
	// 		} else {
	// 			newLayout.push({i:`nested${index}`, x: 0, y: Math.floor(index / 6), w: dataGrid.w, h: 1});
	// 		}
	// 	});

	// 	setLayout(newLayout)

	// }, [items])


	// const handleDrop = (layout: GridLayout.Layout[], item: GridLayout.Layout, e: Event) => {
	// 	console.log('drop');
	// 	console.log(layout);
	// 	console.log(item);
	// 	console.log(e);
	// }

	/** update layout in state when changed */
	const handleLayoutChange = async (newLayout: GridLayout.Layout[]) => {
		// Run only after first render
		if (!firstUpdate.current) {
			if (!rowData) console.log('No row data for ' + title);
			else {
				/** The new grid items after layout change */
				const newGridItems: types.GridItem[] = [];

				// loop through each NestedGrid
				rowData.items.forEach((item) => {
					// only update this grid
					if (item.key === id) {
						const newItem = {...item};
	
						if (!item.items) console.error('Invalid row data: ', item);
						else {
							const newNestedItems: types.GridItem[] = [];

							// loop through nested items and update their data-grids
							item.items.forEach((nestedItem, index) => {
								newNestedItems[index] = {...nestedItem};
								newNestedItems[index]['data-grid'] = newLayout[index];
							})
	
							newItem.items = newNestedItems;
							newGridItems.push(newItem);	
						}
					} else {
						newGridItems.push(item);
					}
				})
				// console.log(rowData);

				// await dispatch(setRowAsync({ ...rowData, items: newGridItems }));
				await dispatch(updateRow({ ...rowData, items: newGridItems }));

				// update row height to accommodate items that have moved
				dispatch(updateRowHeight({ title: title }));

				if (hasHiddenItems()) {
					setHiddenItems(true)
				} else {
					setHiddenItems(false)
				}
			}
		}
	};


	/** Determine if this nested grid contains hidden items */
	const hasHiddenItems = (): boolean => {
		let result = false;
		rowData.items.find(item => item.key === id)?.items?.forEach(nestedItem => {
			// console.log(nestedItem);
			if (nestedItem['data-grid'].y + nestedItem['data-grid'].h > 5) {
				// console.log(id, 'hasHiddenItems: true');
				result = true;
			}
		})
		// console.log(id, 'hasHiddenItems: false');
		return result;
	}



	const handleDrag = () => {
		// console.log('dragging');
		dispatch(setDragging(true));
	}

	const handleDragStop = () => {
		// console.log('stopped dragging');
		setTimeout(() => {
			dispatch(setDragging(false));
		}, 100);
	}

	const handleMaximizeClick = () => {

		// console.log('maximize');
		// dispatch(setRowAsync({ title: title, items: rowData.items, arrows: rowData.arrows, isMinimized: false }));
		dispatch(updateRowHeight({ title: title, isMinimized: false }));
	}

	// //@ts-ignore
	// const handleDrop = (layout, layoutItem, _event) => {
	// 	// console.log('Drop:', layout, layoutItem, _event);
	// 	alert(`Dropped element props:\n${JSON.stringify(layoutItem, ['x', 'y', 'w', 'h'], 2)}`);
	// }


	/** What to do when an item is moved to this grid (on click) */
	const handleMoveItem = async () => {
		console.log('move item to:', id);
		console.log('rowData:', rowData);

		// const newRowData = {...allRowData.Actions};
		const newRowData = { ...rowData };

		/** Remove an item from original nested grid */
		const popItem = (item: types.GridItem) => {
			console.log('removeItem');
	
			const newItems = [...newRowData.items];
	
			rowData.items.forEach((nestedGrid, i) => {
				if (nestedGrid.subPhase === item.subPhase) {
					const newNestedGrid = {...nestedGrid}
					const newNestedItems = [...nestedGrid.items!];
					nestedGrid.items!.forEach((nestedItem, j) => {
						// console.log(nestedItem, item);
						if (nestedItem.key === item.key) {
							// console.log(nestedItem.key);
							// console.log(newNestedItems);
							newNestedItems.splice(j, 1);
							// console.log(newNestedItems);
							// console.log(item);
						}
					})
					newNestedGrid.items = newNestedItems;
					newItems[i] = newNestedGrid;
				}
			})
			newRowData.items = newItems;
	
			// console.log(newRowData);
	
			// dispatch(setRow(newRowData));
		}
	
	
		/** Add an item to this grid */
		const addItem = async (item: types.GridItem) => {
			console.log('addItem');
			// const newRowData = {...rowData};
			const newItems = [...newRowData.items];
	
			// add item to this nested grid
			rowData.items.forEach((nestedGrid, i) => {
				if (nestedGrid.key === id) {
					const newNestedGrid = {...nestedGrid}
					const newNestedItems = [...nestedGrid.items!];
					const newItem = {...item};
					newItem.phase = phase;
					newItem.subPhase = subPhase;
					newNestedItems.push(newItem);
					newNestedGrid.items = newNestedItems;
					newItems[i] = newNestedGrid;
				}
			})
			newRowData.items = newItems;
	
			await dispatch(updateRow(newRowData));
		}

		// remove item from original nested grid
		popItem(movingItem!);
		
		// add item to this nested grid
		await addItem(movingItem!);

		dispatch(setMovingItem(undefined));
	}


	const handleCreateItem = async () => {
		dispatch(setCreatingItem(false));

		// send request to db to create new item
		const response = await dispatch(addItems({
			items: [
				{
					page: rowData.page,
					row: rowData.title,
					phase: phase,
					subPhase: subPhase,
					properties: {
						title: {
							title: 'Title',
							values: ['New Item'],
							required: true,
							type: 'string',
						}
					}
				}
			],
			method: 'append',
		}));

		console.log(response);
		if (response.payload[0]) {
			setNewItem(response.payload[0]);
		}
	}

	// // if moving an item, highlight this grid as a valid target
	// useEffect(() => {
	// 	if (isMovingItem) {
	// 		setUseStyle({
	// 			...style,
	// 			border: '1px solid red',
	// 		})
	// 	} else {
	// 		setUseStyle({
	// 			...style,
	// 			// border: '1px solid red',
	// 		})
	// 	}
	// 	setTimeout(() => {
	// 		console.log(useStyle);
	// 	}, 1000)
	// }, [isMovingItem])


	// console.log(style);
	// console.log(dataGrid);

	// console.log(ref);

	return (
		<SizeMe>
			{({ size }) => (
				<div
					ref={ref}
					key={key}
					id={id}
					// id={`Actions-${dataGrid.i}`}
					className={`NestedGrid ${className ? className : ''}`}
					style={{
						...style,
						height: rowData.height,
						zIndex: zIndex,
						// border: isMovingItem ? '1px solid red' : '',
					}}
					{...props}
				>
					
					<GridLayout
						className="layout"
						layout={layout}
						cols={dataGrid.w}
						// layouts={rowData.items.}
						// breakpoints={{lg: 180, md: 120, sm: 90, xs: 60, xxs: 30}}
						// cols={{lg: 6, md: 4, sm: 3, xs: 2, xxs: 1}}
						rowHeight={rowHeight}
						margin={[8, 5]}
						width={size.width ? size.width : 100}
						data-grid={dataGrid}
						onLayoutChange={handleLayoutChange}
						onDrag={handleDrag}
						onDragStop={handleDragStop}
						isDraggable={editMode} // prevent dragging when zoomed (temp bug fix/hack)
						isResizable={editMode}
						resizeHandles={!editMode ? [] : undefined}
						autoSize={false} // to prevent container from overlapping rows beneath when items are invisible
						transformScale={scale}
						// isDroppable={true} // allow dropping items from outside
						// onDrop={handleDrop}
						// droppingItem={{ i: "abc123", w: 4, h: 1 }}
						// style={{ height: rowData.height }}
					>
						{items}
					</GridLayout>
					{children}

					{rowData.isMinimized && hiddenItems && (
						<button
							className="btn btn-control btn-maximize"
							onClick={handleMaximizeClick}
							title="Expand"
							aria-label="Expand"
						>
							<FontAwesomeIcon icon={faEllipsisH} />
						</button>
					)}

					{/* <button
						className="btn btn-control btn-add"
						onClick={handleAddItem}
						title="Add New Item"
						aria-label="Add New Item"
					>
						<FontAwesomeIcon icon={faPlus} />
					</button> */}

					{(movingItem || creatingItem) && (
						<button
							onClick={movingItem ? handleMoveItem : creatingItem ? handleCreateItem : undefined}
							style={{
								width: style?.width,
								height: rowData.height,
								transform: "translateX(0, 5px)",
								position: 'absolute',
								zIndex: 1000,
								border: "1px solid red",
							}}
						></button>
					)}

				{newItem &&
					<GridItemEditModal 
						// openModal={openEditModal}
						closeModal={() => setNewItem(undefined)}
						modalIsOpen={newItem !== undefined}
						itemData={newItem}
						activeField="title"
					/>
				}

				</div>
			)}
		</SizeMe>
	);
});

export default NestedGrid;
