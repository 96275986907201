import React, { useRef, useEffect, useState } from 'react';
import Editor from 'react-avatar-editor';

// images
import { ReactComponent as IconPlus } from 'assets/images/icon-plus.svg';
import { ReactComponent as IconMinus } from 'assets/images/icon-minus.svg';

type AvatarEditorProps = {
	image: string;
	setEditedImage: React.Dispatch<React.SetStateAction<string>>;
	round?: boolean;
	onCancel: () => void;
	changePicture: () => void;
}

const AvatarEditor = ({ image, setEditedImage, onCancel, round, changePicture }: AvatarEditorProps) => {
	const [scale, setScale] = useState(10);
	// const [borderRadius, setBorderRadius] = useState(0);

	const ref = useRef<Editor>(null);

	const handleSave = () => {
		if (ref.current) {
			// console.log(ref.current);
			const image = ref.current.getImage();
			// console.log(image);
			const imageData = image.toDataURL();
			// console.log(imageData);
			setEditedImage(imageData);
		}
	}

	// useEffect(() => {
	// 	if (ref.current) {
	// 		console.log(ref.current);
	// 		// const image = ref.current.getImage();
	// 		const scaledImage = ref.current.getImageScaledToCanvas();

	// 		// console.log(image);
	// 		console.log(scaledImage);
	// 	}
	// }, [image])

	// useEffect(() => {
	// 	console.log(scale/10);
	// }, [scale])

	return (
		<div className="AvatarEditor">
			<div className="editorAndControls">
				<Editor
					ref={ref}
					image={image}
					width={150}
					height={150}
					border={50}
					color={[255, 255, 255, 0.6]} // RGBA
					scale={scale / 10}
					rotate={0}
					borderRadius={round ? 125 : 0}
					className="editor"
				/>
				<div className="input">
					<label htmlFor="avatarScale" className="sr-only">Zoom</label>
					<IconMinus className="IconMinus" />
					<input type="range" id="avatarScale" min="5" max="30" /* step="0.1" */ defaultValue="10" onChange={(e: React.ChangeEvent<HTMLInputElement>) => setScale(parseInt(e.target.value))} />
					<IconPlus className="IconPlus" />
				</div>
			</div>

			{/* <label htmlFor="avatarBorderRadius">Border Radius</label>
			<input type="range" id="avatarBorderRadius" min="0" max="125" defaultValue="0" onChange={(e: React.ChangeEvent<HTMLInputElement>) => setBorderRadius(parseInt(e.target.value))} /> */}

			<div className="buttons">
				<button 
					type="button" 
					onClick={changePicture} 
					className="btn btn-form btn-form--white"
				>
					change picture
				</button>
				<button type="button" className="btn btn-form btn-form--white" onClick={onCancel}>cancel</button>
				<button type="button" className="btn btn-form btn-form--white btn-save" onClick={handleSave}>save</button>
			</div>

		</div>
	)
}

export default AvatarEditor;
