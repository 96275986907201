import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import type { RootState } from 'app/store'

import * as imageApi from 'api/image';

import * as types from 'types';


// const defaultFilters: {[key:string] : { key: string, values: string[] }} = {
// 	'Activity Type' : {
// 		key : 'activityType',
// 		values : [
// 			'Focused',
// 			'Passive',
// 			'Social',
// 			'Transactional',
// 			'Transitional',
// 			'Active',
// 		],
// 	},
// 	'Location(s)': {
// 		key: 'locations',
// 		values: [
// 			'Offsite',
// 			'Exterior',
// 			'Building entrance',
// 			'Lobby',
// 			'Restaurant',
// 			'Event space',
// 			'Washroom',
// 			'Building exit',
// 		]
// 	},
// 	'Digital Touchpoint(s)': {
// 		key: 'touchpoints',
// 		values: [
// 			'Smartphone',
// 			'Tablet',
// 			'Computer',
// 			'In person',
// 			'Phone',
// 			'App',
// 			'Interactive screen',
// 		],
// 	},
// 	'Opportunity Type(s)' : {
// 		key: 'opportunityType',
// 		values: [
// 			'Data Capture',
// 			'Personalization',
// 			'Wayfinding',
// 			'Personalized',
// 			'Immersive',
// 			'Experiential',
// 			'Gamification',
// 			'Interactive',
// 		]
// 	}
// }



const defaultFilters = [
	{
		title: 'Solution Type',
		key: 'solutionType',
		values: [
			'Personalization',
			'Wayfinding',
			'Self-service',
			'Convenience',
			'Gamification',
			'Engagement',
			'Experiential',
			'Loyalty',
			'Data capture',
		],
	},
	{
		title: 'Business Value',
		key: 'businessValue',
		values: [
			'Awareness',
			'Data capture',
			'Acquisition',
			'Employee engagement',
			'Operational efficiency',
			'Retention',
		]
	},
	{
		title: 'Impact',
		key: 'impact',
		values: [],
	},
	{
		title: 'Risk',
		key: 'risk',
		values: [],
	},
	{
		title: 'Revenue Type',
		key: 'revenueStream',
		values: [],
	},
	{
		title: 'Team',
		key: 'people',
		values: [],
	},
];

export interface MiscState {
	// /** Id of current popup */
	// currentPopup?: types.Popup;
	notification: string;
	/** Whether an item is being moved to another grid */
	movingItem?: types.GridItem;
	/** If has a value, */
	creatingItem: boolean;
	/** Title of current page */
	currentPage: string;
	/** All filters, to populate filter panel */
	filters: types.Filters;
	/** Currently active filters */
	activeFilters: types.Filters;
	showFilterPanel: boolean;
}



const initialState: MiscState = {
	// currentPopup: undefined,
	notification: '',
	movingItem: undefined,
	creatingItem: false,
	currentPage: 'Experience Landscape',
	filters: {},
	activeFilters: {},
	showFilterPanel: false,
}

export const miscSlice = createSlice({
  name: 'misc',
  initialState,
  reducers: {
		// setCurrentPopup(state, action: PayloadAction<types.Popup>) {
		// 	state.currentPopup = action.payload;
		// },
		setNotification(state, action: PayloadAction<string>) {
			state.notification = action.payload;
		},
		setMovingItem(state, action: PayloadAction<types.GridItem | undefined>) {
			state.movingItem = action.payload;
		},
		setCreatingItem(state, action: PayloadAction<boolean>) {
			state.creatingItem = action.payload;
		},
		setCurrentPage(state, action: PayloadAction<string>) {
			state.currentPage = action.payload;
		},
		setFilters(state, action: PayloadAction<types.Filters>) {
			state.filters = action.payload;
		},
		initializeFilters(state, action: PayloadAction<types.GridItem[]>) {
			// add default filters
			// for (let i in defaultFilters) {
			// 	defaultFilters[i].values.forEach(filter => {
			// 		if (!state.filters[i]) {
			// 			state.filters[i] = {
			// 				key: defaultFilters[i].key,
			// 				options: [{
			// 					value: filter,
			// 					label: filter,
			// 				}]
			// 			}
			// 		} else if (!state.filters[i].options.find(option => option.value === filter)) {
			// 			state.filters[i].options.push({
			// 				value: filter,
			// 				label: filter,
			// 			})
			// 		}
			// 	});
			// }

			defaultFilters.forEach((filter, idx) => {
				state.filters[filter.title] = {
					key: filter.key,
					options: [],
					default: true,
				}
				filter.values.forEach(value => {
					if (!state.filters[filter.title]) {
						// state.filters[filter.title] = {
						// 	key: filter.key,
						// 	options: [{
						// 		value: value,
						// 		label: value,
						// 	}]
						// }
					} else if (!state.filters[filter.title].options.find(option => option.value === value)) {
						state.filters[filter.title].options.push({
							value: value,
							label: value,
						})
					}
				});
			})

			action.payload.forEach(nestedItem => {
				nestedItem.items?.forEach(item => {

					// update filters if properties/values don't yet exist
					// loop through each property on this item
					Object.keys(item.properties).forEach(propertyKey => {
						const title = item.properties[propertyKey].title;

						// loop through all values in property
						item.properties[propertyKey].values.forEach(value => {
							// if the property doesn't exist create it
							if (!state.filters[title] && value) {
								state.filters[title] = {
									key: propertyKey,
									options: [{
										value: value,
										label: value,
									}]
								}
							// if the property exists, add to it
							} else {
								// if option doesn't already exist in filter then add it
								if (state.filters[title] && !state.filters[title].options.find(option => option.value === value)) {
									state.filters[title].options.push({
										value: value,
										label: value,
									})
								}
							}
						})
					})
				})
			})
		},
		setActiveFilters(state, action: PayloadAction<types.Filters>) {
			state.activeFilters = action.payload;
		},
		updateActiveFilter(state, action: PayloadAction<{value: any, filterKey: string}>) {
			// console.log(action);
			if (!action.payload.value.length) delete state.activeFilters[action.payload.filterKey];
			else {
				state.activeFilters[action.payload.filterKey] = {
					key: action.payload.filterKey,
					options: action.payload.value,
				};
			}
		},
		clearActiveFilters(state) {
			state.activeFilters = {};
		},
		setShowFilterPanel(state, action: PayloadAction<boolean>) {
			state.showFilterPanel = action.payload;
		}
  },
})

// /** Get requested image by id */
// export const fetchImage = createAsyncThunk(
// 	"user/fetchAvatar",
// 	async (id: string, { dispatch }) => {
// 		try {
// 			imageApi
// 		} catch (error) {
// 			console.error(error);
// 		}
// 	}
// )


export const { /* setCurrentPopup, */ setNotification, setMovingItem, setCurrentPage, setFilters, setActiveFilters, updateActiveFilter, clearActiveFilters, initializeFilters, setCreatingItem, setShowFilterPanel } = miscSlice.actions

// export const selectCurrentPopup = (state: RootState) => state.misc.currentPopup
export const selectNotification = (state: RootState) => state.misc.notification
export const selectMovingItem = (state: RootState) => state.misc.movingItem
export const selectCurrentPage = (state: RootState) => state.misc.currentPage
export const selectFilters = (state: RootState) => state.misc.filters
export const selectActiveFilters = (state: RootState) => state.misc.activeFilters
export const selectCreatingItem = (state: RootState) => state.misc.creatingItem
export const selectShowFilterPanel = (state: RootState) => state.misc.showFilterPanel

export default miscSlice.reducer