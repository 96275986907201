import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import GridLayout from 'react-grid-layout';

// // Redux
import { useAppDispatch } from 'app/hooks';
// import {
// 	selectSize,
// } from "slices/dimensionSlice";

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTimes,
  faEdit,
  faLongArrowAltRight,
  faArrowsAlt,
} from '@fortawesome/free-solid-svg-icons';

// images
import { ReactComponent as IconConnectArrow } from 'assets/images/icon-connect-arrow.svg';

import Popup from '.';

import * as types from 'types';
import './style.scss';

type GridItemPopupProps = {
  popupRoot: HTMLElement;
  title: string;
  description?: string;
  tags?: {}[];
  parentId: string;
  layout?: GridLayout.Layout[];
  size: types.sizeme;
  itemData?: types.GridItem;
  closePopup: () => void;
  drawArrow?: () => void;
  handleMove?: () => void;
  onEdit: () => void;
  openModal: () => void;
  controls?: React.ReactNode;
  confirmDelete?: boolean;
  handleDelete?: () => void;
};

const GridItemPopup = ({
  popupRoot,
  title,
  description,
  tags,
  parentId,
  layout,
  size,
  itemData,
  closePopup,
  drawArrow,
  handleMove,
  onEdit,
  openModal,
  controls,
  confirmDelete,
  handleDelete,
}: GridItemPopupProps) => {
  return (
    // loading ? <div></div> :
    <Popup parentId={parentId} closePopup={closePopup} controls={controls}>
      {/* <button
				className="btn btn-control btn-edit"
				title="Edit"
				aria-label="Edit"
				onClick={onEdit}
			>
				<FontAwesomeIcon icon={faEdit} />
			</button> */}

      {drawArrow && itemData?.row === 'Locations' && (
        <button
          className="btn btn-control btn-arrow"
          title="Draw Arrow"
          aria-label="Draw Arrow"
          onClick={drawArrow}
        >
          {/* <FontAwesomeIcon icon={faLongArrowAltRight} /> */}
          Connectors
          <IconConnectArrow />
        </button>
      )}

      {/* Title */}
      <h4 className="title">
        <button onClick={openModal} title="Open item details">
          {title}
        </button>
      </h4>

      {/* Description */}
      {/* <p title={itemData?.description} aria-label="Description">
				{itemData?.description
					? itemData.description.length > 100
						? `${itemData.description.substring(0, 100)}...`
						: itemData.description
					: ""}
			</p> */}

      {/* Properties */}
      {itemData && (
        <div className="itemData">
          {/* Activity Type */}
          {!itemData.properties.activityType?.values.length ? (
            ''
          ) : (
            <div className="property property--activityType">
              <span className="property-label">Action Type:</span>
              <span>{itemData.properties.activityType.values.join(', ')}</span>
            </div>
          )}

          {/* Location */}
          {!itemData.properties.locations?.values.length ? (
            ''
          ) : (
            <div className="property property--locations">
              <span className="property-label">Location:</span>
              <span>{itemData.properties.locations.values.join(', ')}</span>
            </div>
          )}

          {/* Touchpoint */}
          {!itemData.properties.touchpoints?.values.length ? (
            ''
          ) : (
            <div className="property property--touchpoints">
              <span className="property-label">Touchpoint:</span>
              <span>{itemData.properties.touchpoints.values.join(', ')}</span>
            </div>
          )}

          {/* Pain Points */}
          {!itemData.properties.challenges?.values.length ? (
            ''
          ) : (
            <div className="property property--challenges">
              <span className="property-label">Pain Points:</span>
              <span>{itemData.properties.challenges.values.join(', ')}</span>
            </div>
          )}

          {/* Opportunity */}
          {!itemData.properties.opportunities?.values.length ? (
            ''
          ) : (
            <div className="property property--opportunities">
              <span className="property-label">Opportunity:</span>
              <span>{itemData.properties.opportunities.values.join(', ')}</span>
            </div>
          )}

          {/* List all properties */}
          {/* {itemData.properties &&
						Object.keys(itemData.properties).map((key) => {
							return (
								itemData.properties[key].values.length ? (
									<p key={key}>
										{itemData.properties[key].title}:{" "}
										{itemData.properties[key].values.join(', ')}
									</p>
								)
								: ''
							);
						})} */}
        </div>
      )}

      {confirmDelete && (
        <div className="confirmDelete">
          <button
            className="btn btn-form btn-delete"
            title="Confirm Delete"
            aria-label="Confirm Delete"
            onClick={() => {
              handleDelete && handleDelete();
              closePopup();
            }}
          >
            Confirm Delete
          </button>
        </div>
      )}
    </Popup>
  );
};

export default GridItemPopup;
