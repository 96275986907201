import React, { useRef, useEffect, useState } from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

const orange = '#EF6C00';

const ReactSelect = React.forwardRef<Select, any>(({ creatable, filter, ...props }, ref) => {
	// const ref = useRef<Select>(null);

	const [showClear, setShowClear] = useState<boolean>(false);

	const selectStyles = {
	control: (provided: any) => ({
		...provided,
		"&:hover": {
			color: orange,
		}
	}),
	clearIndicator: (provided: any) => ({
		...provided,
		position: filter ? 'absolute' : undefined,
		top: filter ? 0 : undefined,
		right: filter ? 0 : undefined,
		display: !filter ? undefined : showClear ? 'block' : 'none',
		"&:hover": {
			color: orange,
		},
		// "&:focus": {
		// 	color: orange,
		// },
		// "&:focus-within": {
		// 	color: orange,
		// },
	}),
	dropdownIndicator: (provided: any) => ({
		...provided,
		position: filter ? 'absolute' : undefined,
		bottom: filter ? 0 : undefined,
		right: filter ? 0 : undefined,
		"&:hover": {
			color: orange,
		}
	}),
	indicatorSeparator: (provided: any) => ({
		...provided,
		marginRight: filter ? 25 : undefined,
		visibility: filter ? 'hidden' : undefined,
	}),
	multiValue: (provided: any) => ({
		...provided,
		"&:hover": {
			color: orange,
		}
		// color: orange,
	}),
	multiValueLabel: (provided: any) => ({
		...provided,
		"&:hover": {
			color: orange,
		}
		// color: orange,
	}),
	multiValueRemove: (provided: any) => ({
		...provided,
		color: 'black',
		"&:hover": {
			color: orange,
			background: '#ECB384',
		},
	}),
	placeholder: (provided: any) => ({
		...provided,
		color: 'inherit',
		// "&:hover": {
		// 	color: orange,
		// }
	}),
	option: (provided: any) => ({
		...provided,
		fontSize: '1.4rem',
		// color: 'inherit',
		// "&:hover": {
		// 	color: orange,
		// }
	}),
}
// console.log(ref);

	useEffect(() => {
		//@ts-ignore
		if (ref?.current) {
			//@ts-ignore
			const controlRef = ref.current.select.controlRef;
			//@ts-ignore
			const height = controlRef?.clientHeight;

			if (height && height > 50) {
				setShowClear(true);
			} else {
				setShowClear(false);
			}
		}
		//@ts-ignore
	}, [ref?.current, props.value])

	return (
		<>
			{creatable ? <CreatableSelect {...props} styles={selectStyles} ref={ref} />
			: <Select {...props} styles={selectStyles} ref={ref} />
			}
		</>
	)
})

export default ReactSelect;
