import React, { useState, useEffect, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';


import DropdownMenu from '../DropdownMenu';

import Modal from '../Modal';
import ImportModal from './ImportModal';

import FilterPanel from './FilterPanel';

// Redux
import { useAppSelector, useAppDispatch } from 'app/hooks' 
import { /* resetDefault, */resetProjectToDefault, selectActionState, setEditMode, selectEditMode/* , setRowAsync */, setRow, selectPages, selectProject, addItems, editItem, setActionState } from 'slices/projectSlice';
import { setNotification, selectNotification, selectMovingItem, setMovingItem, selectCurrentPage, setCreatingItem, selectCreatingItem, selectShowFilterPanel, setShowFilterPanel } from 'slices/miscSlice';
import { selectLoggedIn, logoutAsync, selectUser, selectAvatar } from 'slices/userSlice';

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH, faWrench, faUndo, faUpload, faTimes, faTimesCircle, faBars, faUser, faSignOutAlt, faFilter, faSearch, faPlus } from '@fortawesome/free-solid-svg-icons'

// import anonymousUser from 'assets/images/anonymousUser.png';
// import building from 'assets/images/building.svg';

// images
import { ReactComponent as IconOrganizationDefault } from 'assets/images/icon-organization-default.svg';
import { ReactComponent as IconPlusCircle } from 'assets/images/icon-plus-circle.svg';
import { ReactComponent as IconSearch } from 'assets/images/icon-search.svg';
import { ReactComponent as IconFilter } from 'assets/images/icon-filter.svg';
import { ReactComponent as IconHamburger } from 'assets/images/icon-hamburger.svg';
import { ReactComponent as IconXCircle } from 'assets/images/icon-x-circle.svg';
import { ReactComponent as IconPlus } from 'assets/images/icon-plus.svg';
import { ReactComponent as IconEdit } from 'assets/images/icon-edit.svg';
import { ReactComponent as IconReset } from 'assets/images/icon-reset.svg';
import { ReactComponent as IconImport } from 'assets/images/icon-import.svg';

import './style.scss';
import * as types from 'types';
import * as organizationsApi from 'api/organizations';
import * as imageApi from 'api/image';

// import { AddItemsItem } from 'api/projects';
// import * as layoutApi from 'api/layout';

// type MenuProps = {
// 	children: React.ReactNode;
// 	className?: string;
// }

const Menu = (/* { children, className }: MenuProps */): JSX.Element => {
	const { org, project } = useParams<{ org: string, project: string }>();
	const actionState = useAppSelector(selectActionState);
	const editMode = useAppSelector(selectEditMode);
	const notification = useAppSelector(selectNotification);
	const movingItem = useAppSelector(selectMovingItem);
	const creatingItem = useAppSelector(selectCreatingItem);
	const isLoggedIn = useAppSelector(selectLoggedIn);
	const user = useAppSelector(selectUser);
	const projectData = useAppSelector(selectProject);
	const currentPage = useAppSelector(selectCurrentPage);
	const dispatch = useAppDispatch();

	const [showImportModal,setShowImportModal] = useState(false);
	const [showResetConfirmModal, setShowResetConfirmModal] = useState(false);

	const showFilterPanel = useAppSelector(selectShowFilterPanel);

	const userAvatar = useAppSelector(selectAvatar);
	// const [orgLogo, setOrgLogo] = useState('');


	// const [userAvatar, setUserAvatar] = useState('');
	
	// // get user avatar
	// useEffect(() => {
	// 	if (user.avatar) {

	// 	}
	// }, [user.avatar])

	// useEffect(() => {
	// 	fetchOrgLogo();
	// }, [projectData])

	// /** Get the logo of the organization */
	// const fetchOrgLogo = async () => {
	// 	try {
	// 		// fetch organization
	// 		const { data: organization } = await organizationsApi.fetchbyId(projectData.organizationId);
	// 		const { data: logo } = await imageApi.fetchImage(organization.logoId);

	// 		setOrgLogo(logo.data);
	// 	} catch (error) {
	// 		console.error(error);
	// 	}
	// }


	const handleEditMode = () => {
		dispatch(setEditMode(!editMode));
	}

	const handleReset = () => {
		// TODO: fix bug before re-enabling, potentially crashing the server on project reset (maybe introduced with https://github.com/fivepointsventures/venture-mapping-tool/pull/44)
		// setShowResetConfirmModal(true);
	}

	const resetProject = async () => {
		dispatch(resetProjectToDefault({org, project}));
	}

	const handleImport = () => {
		openModal();
	}

  const openModal = () => {
    setShowImportModal(true);
  }

	const closeModal = () => {
    setShowImportModal(false);
  }

	const handleLogout = () => {
		dispatch(logoutAsync());
	}

	const handleDisplayFilterPanel = () => {
		dispatch(setShowFilterPanel(!showFilterPanel));
	}

	const handleSearch = () => {
		console.log('Search');
	}

	const handleCreateItem = () => {
		console.log('Create new item');
		// figure out which row/column to create new item (clickable interface like with item move)
		dispatch(setCreatingItem(true));
	
	}

	return (
		<div className="Menu">
			<div className="wrapper">
				<div className="Menu-top">
					<div className="left">
						<nav className="nav">
							<Link to="/" className="organization" title="Organization Name">
								{projectData.orgLogo ? 
									<img src={projectData.orgLogo} alt="Logo" />
								:
									<IconOrganizationDefault />
								}
								{projectData.organizationName}
							</Link>
							<span>/</span>
							<Link to="/" title="Project Name">{projectData.name}</Link>
						</nav>
					</div>
					<div className="notifications">
						{notification && <span>{notification}</span>}
						{editMode === true && (
							<div className="notification">
								<span>Edit Mode</span>
								<button
									onClick={() => dispatch(setEditMode(false))}
									title="Cancel"
									aria-label="Cancel"
									className="btn btn-control"
								>
									{/* <FontAwesomeIcon icon={faTimesCircle} /> */}
									<IconXCircle />
								</button>
							</div>
						)}
						{actionState === "drawArrow" && (
							<div className="notification">
								<span>Connect arrow to another item</span>
									<button
										onClick={() => dispatch(dispatch(setActionState('normal')))}
										title="Cancel"
										aria-label="Cancel"
										className="btn btn-control"
									>
										<IconXCircle />
									</button>
							</div>
						)}
						{movingItem && (
							<div className="notification">
								<span>Moving: {movingItem.properties.title.values[0]}</span>
								<button
									onClick={() => dispatch(setMovingItem(undefined))}
									title="Cancel"
									aria-label="Cancel"
									className="btn btn-control"
								>
									{/* <FontAwesomeIcon icon={faTimesCircle} /> */}
									<IconXCircle />
								</button>
							</div>
						)}
						{creatingItem && (
							<div className="notification">
								<span>Click to place new item</span>
								<button
									onClick={() => dispatch(setCreatingItem(false))}
									title="Cancel"
									aria-label="Cancel"
									className="btn btn-control"
								>
									{/* <FontAwesomeIcon icon={faTimesCircle} /> */}
									<IconXCircle />
								</button>
							</div>
						)}
					</div>
					<div className="right">
						{/* Add */}
						<DropdownMenu heading={
							// <FontAwesomeIcon icon={faPlus} title="Add new..." className="dropdown-add" />
							// <img src={iconPlusCircle} alt="Add new..." title="Add new..." className="btn-svg btn-svg--black" />
							<IconPlusCircle title="Add new..." />
						}>
							<li>
								<button className="btn btn-control" onClick={handleCreateItem}>
									<IconPlus />
									<span>New Item</span>
								</button>
							</li>
							<li>
								<button className="btn btn-disabled">
									<IconPlus />
									<span>New Row</span>
								</button>
							</li>
						</DropdownMenu>
						{/* User */}
						{
						// 	<DropdownMenu
						// 	heading={
						// 		<div className="dropdown-user">
						// 			{
						// 				<img
						// 					className="userAvatar"
						// 					src={userAvatar}
						// 					alt="User Avatar"
						// 					key={/* user.data.avatarId */ Date.now()}
						// 				/>
						// 			}
						// 			{`${user.data.firstName} ${user.data.lastName?.charAt(0)}.`}
						// 		</div>
						// 	}
						// >
						// 	{isLoggedIn ? (
						// 		<>
						// 			<li>
						// 				<Link to="/" className="btn btn-control">
						// 					<FontAwesomeIcon icon={faUser} /> My Account
						// 				</Link>
						// 				{/* <button className="btn btn-control"><FontAwesomeIcon icon={faUser} /> My Account</button> */}
						// 			</li>
						// 			<li>
						// 				<button onClick={handleLogout} className="btn btn-control">
						// 					<FontAwesomeIcon icon={faSignOutAlt} /> Log Out
						// 				</button>
						// 			</li>
						// 		</>
						// 	) : (
						// 		<li>
						// 			<button className="btn btn-control">Log In</button>
						// 		</li>
						// 	)}
						// </DropdownMenu>
						}
						{/* Controls */}
						<ul className="controls">
							{currentPage === "Opportunity Compass" && (
								<>
									{/* Search button */}
									<li>
										<button
											className="btn btn-menu btn-disabled"
											title="Search"
											aria-label="Search"
											onClick={handleSearch}
										>
											{/* <FontAwesomeIcon icon={faSearch} /> */}
											{/* <img src={iconSearch} alt="Search" title="Search" /> */}
											<IconSearch title="Search" />
										</button>
									</li>
									{/* Filter button */}
									<li>
										<button
											className={`btn btn-control btn-menu ${showFilterPanel ? 'active' : ''}`}
											title="Filter"
											aria-label="Filter"
											onClick={handleDisplayFilterPanel}
											// style={{
											// 	color: showFilterPanel ? "#ef6c00" : undefined,
											// }}
										>
											{/* <FontAwesomeIcon icon={faFilter} /> */}
											{/* <img src={showFilterPanel ? iconFilterActive : iconFilter} alt="Filter" title="Filter" className="icon-filter" /> */}
											<IconFilter title="Filter" />
										</button>
									</li>
								</>
							)}
						</ul>
						{/* Options */}
						<DropdownMenu heading={
							// <FontAwesomeIcon icon={faEllipsisH} />
							// <img src={iconHamburger} alt="More Options" title="More Options"  className="btn-svg btn-svg--black" />
							<IconHamburger title="More Options" />
						}>
							<li>
								<button onClick={handleEditMode} className="btn btn-control">
									<IconEdit /> <span>Edit Mode</span>
								</button>
							</li>
							<li>
								<button onClick={handleReset} className="btn btn-control">
									<IconReset /> <span>Reset {projectData.projectType === 'demo' ? 'Demo' : 'Project'}</span>
								</button>
							</li>
							<li>
								<button onClick={handleImport} className="btn btn-control">
									<IconImport /> <span>Import</span>
								</button>
							</li>
						</DropdownMenu>
					</div>
					<ImportModal
						openModal={openModal}
						closeModal={closeModal}
						modalIsOpen={showImportModal}
					/>
					<Modal
						closeModal={() => setShowResetConfirmModal(false)}
						modalIsOpen={showResetConfirmModal}
					>
						<h2>Reset this project?</h2>
						<p>This action is irreversible!</p>
						<button className="btn btn-form" onClick={() => {
							resetProject();
							setShowResetConfirmModal(false);
						}}>
							Confirm
						</button>
						<button className="btn btn-form" onClick={() => {
							setShowResetConfirmModal(false);
						}}>
							Cancel
						</button>
					</Modal>
				</div>
			</div>

			{currentPage === "Opportunity Compass" /* && showFilterPanel */ && (
				<FilterPanel onClose={() => dispatch(setShowFilterPanel(false))} />
			)}
		</div>
	);
}

export default Menu;
