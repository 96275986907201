import React, { useState, useEffect } from 'react';
import { CSVReader } from 'react-papaparse';
import { ParseResult } from 'papaparse';
import TableTypes from 'react-table';
import { useParams } from 'react-router-dom';

import Modal from '../Modal';
import ImportForm, { ImportFormInputs } from '../forms/ImportForm';
import ReactTable from '../ReactTable';

// Redux
import { useAppDispatch } from 'app/hooks' 
import { addItems, fetchProject } from 'slices/projectSlice';

import { AddItemsItem } from 'api/projects';

// images
import { ReactComponent as IconImport } from 'assets/images/icon-import.svg';

import * as types from 'types';
import semicolonSplit from 'utils/semicolonSplit';

const defaultImportTypes: {[key: string] : string} = {
	'Sub-phase': 'subPhase',
	'Title': 'title',
	'Action Type': 'activityType',
	'Location(s)': 'locations',
	'Digital Touchpoint(s)': 'touchpoints',
	'Touchpoint Type': 'touchpointType',
	'Opportunity(ies)': 'opportunities',
	'Opportunity Description': 'opportunityDescription',
	'Opportunity Type(s)': 'opportunityType',
	'Business Value': 'businessValue',
	'Challenge / Pain Point': 'challenges',
	'Team': 'people',
	'Solution Type': 'solutionType',
	'Revenue Stream': 'revenueStream',
	'Data Source': 'dataSource',
	'Risk': 'risk',
	'Impact': 'impact',
}

type MenuModalProps = {
	openModal?: () => void;
	closeModal: () => void;
	modalIsOpen: boolean;
}

const ImportModal = ({ openModal, closeModal, modalIsOpen }: MenuModalProps) => {
	const { org, project } = useParams<{ org: string, project: string }>();

	const [importData, setImportData] = useState<ParseResult<any>[]>();
		
	const [tableData, setTableData] = useState<{ [key: string]: string }[]>([]);
	const [tableColumns, setTableColumns] = useState<TableTypes.Column<{ [key: string]: string }>[]>([]);

	/** The columns selected by the user and their corresponding id in data */
	const [selectedColumns, setSelectedColumns] = useState<{ [id: string]: string }>({});

	const defaultSelectOptions = {
		// phase: {
		// 	selected: false,
		// 	id: '',
		// 	text: 'Phase',
		// 	required: true,
		// },
		subPhase: {
			selected: false,
			id: '',
			text: 'Sub-phase',
			required: true,
		},
		title:{ 
			selected: false,
			id: '',
			text: 'Title',
			required: true,
		},
		activityType: {
			text: 'Action Type',
			selected: false,
			id: '',
		},
		locations: {
			text: 'Location(s)',
			selected: false,
			id: '',
		},
		touchpoints: {
			text: 'Digital Touchpoint(s)',
			selected: false,
			id: '',
		},
		touchpointType: {
			text: 'Touchpoint Type',
			selected: false,
			id: '',
		},
		opportunities: {
			text: 'Opportunity(ies)',
			selected: false,
			id: '',
		},
		opportunityDescription: {
			text: 'Opportunity Description',
			selected: false,
			id: '',
		},
		opportunityType: {
			text: 'Opportunity Type(s)',
			selected: false,
			id: '',
		},
		businessValue: {
			text: 'Business Value',
			selected: false,
			id: '',
		},
		challenges: {
			text: 'Challenge / Pain Point',
			selected: false,
			id: '',
		},
		people: {
			text: 'Team',
			selected: false,
			id: '',
		},
		solutionType: {
			text: 'Solution Type',
			selected: false,
			id: '',
		},
		revenueStream: {
			text: 'Revenue Stream',
			selected: false,
			id: '',
		},
		dataSource: {
			text: 'Data Source',
			selected: false,
			id: '',
		},
		impact: {
			text: 'Impact',
			selected: false,
			id: '',
		},
		risk: {
			text: 'Risk',
			selected: false,
			id: '',
		},
		// property:{ 
		// 	// selected: false,
		// 	id: '',
		// 	text: 'Other Property',
		// 	properties: {}
		// },
	}

	const [selectOptions, setSelectOptions] = useState<{ [key: string]: { selected?: boolean, id: string, text: string, required?: boolean, properties?: types.GridItemProperties } }>(defaultSelectOptions);

	const [isMissingColumns, setIsMissingColumns] = useState(false);

	const [displayErrors, setDisplayErrors] = useState(false);

	const dispatch = useAppDispatch();


		// update selected columns
	useEffect(() => {
		// console.log(selectedColumns);
		const newSelectOptions = {...selectOptions};

		// reset
		for (let i in newSelectOptions) {
			newSelectOptions[i].selected = false;
			newSelectOptions[i].id = '';
		}

		for (let i in selectedColumns) {
			newSelectOptions[selectedColumns[i]].selected = true;
			newSelectOptions[selectedColumns[i]].id = i;
		}

		// console.log(newSelectOptions);
		// console.log('test');
		setSelectOptions(newSelectOptions);
	}, [selectedColumns, importData])


	useEffect(() => {
		// console.log('selectOptions');
		let missingColumns = false;
		for (let i in selectOptions) {
			// console.log(selectOptions[i].selected, selectOptions[i].required);
			if (!selectOptions[i].selected && selectOptions[i].required) {
				missingColumns = true;
			}
		}
		// console.log(missingColumns);
		setIsMissingColumns(missingColumns);
	}, [selectOptions, importData])

	const handleOnDrop = (data: ParseResult<any>[]) => {
    console.log('---------------------------')
    console.log(data)
    console.log('---------------------------')

		setImportData(data);

		const newTableData: { [key: string]: string }[] = [];
		const newTableColumns: TableTypes.Column<{ [key: string]: string }>[] = [];

		data.forEach((tableRow, i) => {
			// set first row as headers // TODO give option to incl. headers or not
			if (i === 0) {
				// const newSelectOptions = {...selectOptions};
				const newSelectedColumns = {...selectedColumns};
				tableRow.data.forEach((item, j) => {
					if (selectOptions[defaultImportTypes[item]]) {
						// newSelectOptions[defaultImportTypes[item]].selected = true;
						// newSelectOptions[defaultImportTypes[item]].id = `col${j}`;
						newSelectedColumns[`col${j}`] = defaultImportTypes[item];
					}
					newTableColumns.push({
						Header: item,
						accessor: `col${j}`,
					})
				})
				// setSelectOptions(newSelectOptions);
				setSelectedColumns(newSelectedColumns);
			} else {
				const newTableRow: { [key: string]: string } = {};
				tableRow.data.forEach((item, j) => {
					newTableRow[`col${j}`] = item;
				})
				newTableData.push(newTableRow);
			}
		})

		setTableColumns(newTableColumns);
		setTableData(newTableData);
  }

  const handleOnError = (err: any, file: any, inputElem: any, reason: any) => {
    console.log(err)
  }

  const handleOnRemoveFile = (data: null) => {
    console.log('---------------------------')
    console.log(data)
    console.log('---------------------------')

		setImportData(undefined);
		// setTableColumns([]);
		// setTableData([]);
  }


	/** What to do when the user specifies a column */
	const handleSpecifyColumn = async (e: React.ChangeEvent<HTMLSelectElement>, columnId: string, header?: string) => {
		const selectedColumn = e.target.value;
		const newSelectedColumns = {...selectedColumns};
		// console.log(selectedColumn);
		// console.log(newSelectedColumns);


		// if no column is selected, then remove from object, otherwise update object
		if (!selectedColumn) {
			delete newSelectedColumns[columnId];
		} else if (selectedColumn === 'property') {
			if (header) {
				const newProperties = {...selectOptions.property.properties};
				// newProperties[header]

			} else {
				console.error('No header');
			}
		} else {
			newSelectedColumns[columnId] = selectedColumn;
		}
		
		// console.log(tableData);
		// console.log(newSelectedColumns);
		
		setSelectedColumns(newSelectedColumns);
	}
	
	const isRequiredMissing = () => {
		for (let i in selectOptions) {
			if (!selectOptions[i].selected && selectOptions[i].required) {
				return true;
			}
		}
		return false;
	}


	/** What to do when the user confirms import */
	const handleImportSubmit = async (data: ImportFormInputs) => {
		// console.log(selectOptions);
		// console.log(selectedColumns);

		// console.log('test');
		// console.log(isRequiredMissing());

		// first validate all required columns are selected
		if (!isRequiredMissing()) {
			setDisplayErrors(false);

			const row = data.selectRow;
			const importMethod = data.importMethod;

			/** The updated table data with only required fields */
			const itemsToSend: AddItemsItem[] = [];
	
			tableData.forEach(rowData => {
				// console.log(rowData);
				const newRowData: AddItemsItem = {
					page: 'Experience Landscape', //* temporarily hardcoded
					row: row,
					subPhase: '',
					properties: {
						title: {
							title: 'Title',
							values: [],
							required: true,
							type: 'string'
						},
					},
				};
				for (let i in rowData) {
					// console.log(rowData);
					// console.log(i);
					// console.log('selectOptions', selectOptions);
					// console.log(selectedColumns, selectedColumns[i]);
					if (selectedColumns && selectedColumns[i]) {
						if (selectedColumns[i] === 'subPhase') {
							newRowData.subPhase = rowData[i];
						} else if (selectedColumns[i] === 'title') {
							newRowData.properties.title.values = [rowData[i]];
						} else {
							// console.log('test');
							// console.log(i, newRowData.properties[i], selectOptions[i].text, semicolonSplit(rowData[i]));
							newRowData.properties[selectedColumns[i]] = {
								title: selectOptions[selectedColumns[i]].text,
								values: semicolonSplit(rowData[i]),
								required: true,
							}
						}
					}
				}
				// console.log(newRowData);
				itemsToSend.push(newRowData);
			})

			// console.log(itemsToSend);
	
			/** The data to send, including method (append or overwrite) */
			// const dataToSend = { items: itemsToSend, overwrite: importMethod === 'overwrite' };
	
			try {
				// const response = await layoutApi.addItemsToRow(row, dataToSend);
				const response = await dispatch(addItems({ items: itemsToSend, method: importMethod }));
				// console.log(response.payload);

				if (response.payload.length > 1) {
					dispatch(fetchProject({org, project}));
				}


				// clear selected columns
				setSelectedColumns({});
				setSelectOptions(defaultSelectOptions);
		
				// clear table data
				setImportData(undefined);

				closeModal();
			} catch (error) {
				console.error(error);
			}
		// if missing columns, display errors
		} else {
			setDisplayErrors(true);
		}
	}


	return (
		<Modal
				openModal={openModal}
				closeModal={closeModal}
				modalIsOpen={modalIsOpen}
			>
				<h2>IMPORT</h2>

				<p>Data will be imported into the specified row.</p>
				<p>
					Please provide the following:
				</p>
				<ul className="list">
					<li>Sub-phase (Column)</li>
					<li>Item Text</li>
				</ul>

				<CSVReader
					onDrop={handleOnDrop}
					onError={handleOnError}
					addRemoveButton
					removeButtonColor="#659cef"
					onRemoveFile={handleOnRemoveFile}
					config={{
						skipEmptyLines: true,
					}}
					style={{
						dropArea: {
							borderRadius: 5,
							maxHeight: 120,
							padding: '1rem',
						},
					}}
				>
					<IconImport className="IconImport" />
				</CSVReader>
				{!importData && <span className="importInstruction">Drop CSV file here or click to upload.</span>}

				{importData && (
					<div className="importData">
						<h3>Data ({importData.length - 1} entries):</h3>
						{/* list import data */}
						<ReactTable
							data={tableData}
							columns={tableColumns}
							onSpecifyColumn={handleSpecifyColumn}
							/*  selectedColumns={selectedColumns} */ 
							selectOptions={
								selectOptions
							}
							// setSelectOptions={setSelectOptions}
							defaultImportTypes={defaultImportTypes}
						/>

						{
							// importData.map((item, index) => {
							// 	/** Limit of items to display */
							// 	const displayLimit = 5;
							// 	if (index < displayLimit) {
							// 		return <p>{item.data[0]}</p>
							// 	} else if (index === displayLimit) {
							// 		return <p>...</p>
							// 	}
							// })
						}

						{/* Display import options */}

						{displayErrors && isMissingColumns && (
							<span className="msg msg-error">Please select all required columns (*)</span>
						)}

						<ImportForm onSubmit={handleImportSubmit} />
					</div>
				)}
			</Modal>
	)
}

export default ImportModal;
