import React, { useState, useEffect } from 'react';
// import GridLayout from 'react-grid-layout';

import { useWindowDimensions } from 'app/hooks';

import GridRow from "../";
import NestedGrid from '../../NestedGrid';
import GridItem from '../../GridItem';

// Redux
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { setRow, /* selectRows, */ selectPages, updateRowHeight } from 'slices/projectSlice';
import { selectSize } from 'slices/dimensionSlice';

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faMinusCircle } from '@fortawesome/free-solid-svg-icons'
import { ReactComponent as IconMinusCircle } from 'assets/images/icon-minus-circle.svg';

// import * as layoutApi from 'api/layout';

import * as types from "types";
import "./style.scss";

type GridRowProps = {
	rowData: types.GridRow;
}

const GridRowContainer = ({ rowData }: GridRowProps): JSX.Element => {
	// const { width, height } = useWindowDimensions();
	const size = useAppSelector(selectSize);
	const dispatch = useAppDispatch();
	
	const handleMinimizeClick = () => {
		dispatch(updateRowHeight({ title: rowData.title, isMinimized: true }));
	}

	// console.log(rowData.layout);

	const ref = React.createRef<HTMLDivElement>();


	return (
		<>
			<div className="GridRow-container">
				{/* Title was here and took up 42px, some code depended on that. Quick hack to fix */}
				<div className="spacer"></div>

				<GridRow
					id={rowData._id}
					size={size}
					title={rowData.title}
					layout={rowData.layout}
					// margin={rowData.margin?.length ? rowData.margin : [10, 10]}
					rowData={rowData}
				>
					{rowData.type === 'standard' ?
						rowData.items.map((item: types.GridItem)=> 
							<GridItem
								key={item.key}
								id={item.key}
								title={item.properties?.title.values[0]}
								ref={ref}
								layout={rowData.layout && rowData.layout} // for rerender
								size={size}
								container={rowData.title}
								rowData={rowData}
								itemData={item}
							>
								<p>{item.properties?.title.values[0]}</p>
							</GridItem>
						)
					: rowData.type === 'nested' ?
						rowData.items.map((item, index) => {
								return <NestedGrid
									ref={ref}
									key={item.key}
									id={item.key}
									phase={item.phase!}
									subPhase={item.subPhase!}
									title={rowData.title}
									layout={rowData.items[index].layout!}
									dataGrid={item['data-grid']}
									rowData={rowData}
									items={
										item.items?.map(nestedItem => {
											return <GridItem
												key={nestedItem.key}
												id={nestedItem.key}
												title={nestedItem.properties.title.values[0]}
												row={rowData.title}
												dataGrid={nestedItem['data-grid']}
												ref={ref}
												layout={rowData.layout && rowData.layout} // for rerender
												size={size}
												container={item.key}
												itemData={nestedItem}
												rowData={rowData}
											>
												<p>{nestedItem.properties.title.values[0]}</p>
											</GridItem>
										})
									}
								>
								</NestedGrid>
						})
					: ''
				}
				</GridRow>

				<div
					className="title-area"
				>
					<h2>{rowData.title}</h2>
					{(rowData.type === 'standard' || rowData.isMinimized) ? ''
					:
						<button
							onClick={handleMinimizeClick}
							className="btn btn-control btn-minimize"
							title="Minimize Row"
							aria-label="Minimize Row"
						>
							{/* <FontAwesomeIcon icon={faMinusCircle} /> */}
							<IconMinusCircle />
						</button>
					}
				</div>

			</div>
			<div className="backgroundMask"></div>
		</>
	)
}

export default GridRowContainer;
