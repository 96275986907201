import axios from "axios";

import { ProjectFormInputs } from "components/forms/ProjectForm";
import * as types from 'types';

const url = "/projects";


export const fetch = (org: string, project: string) => axios.get(`${url}/${org}/${project}`)


export const createNew = (data: ProjectFormInputs, orgId: string) => axios.post(`${url}/create`, {data, orgId});


export const resetToDefault = (organizationId: string, projectId: string) => axios.post(`${url}/reset/${organizationId}/${projectId}`);


export const update = ({orgId, projectId}: {orgId: string, projectId: string}, updatedProject: types.Project) => axios.put(`${url}/${orgId}/${projectId}`, {updatedProject});



export interface AddItemsItem { 
	page: string,
	row: string,
	phase?: string,
	subPhase?: string,
	properties: types.GridItemProperties;
}

export interface AddItemsData {
	/** Array of items to be added (with subPhase and row) */
	items: AddItemsItem[], 
	method: 'overwrite' | 'append' | 'new',
};

export const addItems = ({orgId, projectId}: {orgId: string, projectId: string}, data: AddItemsData) => axios.patch(`${url}/addItems/${orgId}/${projectId}`, data);


export interface EditItemData {
	_id?: string,
	page: string,
	row: string,
	subPhase?: string,
	field?: string,
	value?: string | string[],
	/** If true then this input is for a label instead of value */
	label?: boolean,
	/** What action to take when editing (eg. 'remove') */
	action?: 'remove',
}

export const editItem = ({orgId, projectId}: {orgId: string, projectId: string}, data: EditItemData) => axios.patch(`${url}/editItem/${orgId}/${projectId}`, data)


// export const deleteItem = ({orgId, projectId}: {orgId: string, projectId: string}, data: EditItemData) => axios.delete(`${url}/editItem/${orgId}/${projectId}`, data)