import React, { useEffect, useLayoutEffect, useState } from 'react';
import GridLayout from 'react-grid-layout';
import TableTypes from 'react-table';
import { SizeMe } from 'react-sizeme';
import ReactSelect from 'react-select';

import { useWindowDimensions } from 'app/hooks';

import DotRowTable from './DotRowTable';
import Popup from '../Popup';

// Redux
import { useAppSelector, useAppDispatch } from 'app/hooks';
import {
	selectColumns,
	selectTotalColumns,
} from "slices/projectSlice";
import {
	selectSize,
} from "slices/dimensionSlice";
import { selectPages } from 'slices/projectSlice';
import { setFilters, selectActiveFilters, selectFilters } from 'slices/miscSlice';

import semicolonSplit from 'utils/semicolonSplit';

import * as types from 'types';
import './style.scss';



/**
 *	Get object containing all properties on the provided item
 */
const getProperties = (item: types.GridItem) => {
	const properties: { [key: string]: Set<string> } = {};

	for (let propertyKey in item.properties) {
		const property = item.properties[propertyKey];

		if (!properties[property.title]) {
			properties[property.title] = new Set(property.values);
		} else {
			property.values.forEach(value => {
				properties[property.title].add(value)
			})
		}
	}

	return properties;
}



type DotProps = {
	props: any;
	children: React.ReactNode;
	items: types.GridItem[];
	// engType?: string;
	// expType?: string;
	// activityType?: string;
	label: { title: string, value: string };
	subPhase: string;
	location: string;
	className: string;
}

const Dot = ({children, props, items, /* engType, expType, */ label, subPhase, location, className}: DotProps): JSX.Element => {
	const [showPopup, setShowPopup] = useState(false);
	// const [popupStyle, setPopupStyle] = useState({});

	// const parent = document.getElementById(props.id);
	// const popupRoot = document.getElementById("root");
	// const popupRoot = document.getElementById("Opportunity-Row-TypeLocation-container");


	const handleClick = (e: React.MouseEvent<HTMLElement>) => {
		setShowPopup(!showPopup);

		//@ts-ignore
		// const rect = e.target.getBoundingClientRect();
		// console.log(rect);

		// setPopupStyle({
		// 	// @ts-ignore
		// 	top: e.target.parentElement.offsetParent.offsetTop + e.target.parentElement.offsetTop,
		// 	//@ts-ignore
		// 	left: e.target.parentElement.offsetParent.offsetLeft + e.target.parentElement.offsetLeft/*  + e.target.clientWidth */,
		// 	// transform: `translate(${Math.round(rect.left)}, ${Math.round(rect.top)})`
		// })
	}

	const handleClosePopup = () => {
		setShowPopup(false);
	}

	
	return (
		<div {...props} className={`Dot ${showPopup && 'active'} ${className ? className : undefined}`}>
			<button onClick={handleClick}></button>
			{children}
			 
			{showPopup && 
				<Popup
					// popupRoot={popupRoot!}
					parentId={props.id}
					closePopup={handleClosePopup}
					// style={popupStyle}
				>
					<h4>Actions:</h4>

					<ul>
						{items.map(item => 
							<li key={item._id}>{item.properties.title.values[0]}</li>
						)}
					</ul>

					<div className="itemData">
						{/* {engType && <p>Engagement Type: {engType}</p>} */}
						{/* {expType && <p>Experience Type: {expType}</p>} */}
						{/* {activityType && <p>Activity Type: {activityType}</p>} */}
						{(label.title && label.value) && <p>{label.title}: {label.value}</p>}
						{<p>Location: {location}</p>}
					</div>
				
				</Popup>
			}

		</div>
	)
}



type DotRowProps = {
	type: {
		title: 'Locations' | 'Touchpoints' | 'Team' | 'Data Sources' | 'Revenue Type' | 'Opportunities' | string;
		key: 'activityType' | 'touchpoints' | 'people' | 'dataSource' | 'revenueStream' | 'opportunities' | string;
		label: 'Action Type' | 'Digital Touchpoint(s)' | 'Team' | 'Data Source' | 'Revenue Stream' | 'Opportunity(ies)' | string;
	};
}

const DotRow = ({ type }: DotRowProps): JSX.Element => {
	// console.log(type);
	const allFilters = useAppSelector(selectFilters);
	const rowTitle = type.title;
	// const rowTitle = type === 'Type/Location' ? 'Type + Location' : type === 'Touchpoint' ? 'Digital Touchpoint' : type;
	// const yAxisType = type === 'Type/Location' ? 'activityType' : type === 'Touchpoint' ? 'touchpoints' : '';

	/** The current y-axis type */
	// const [yAxisType, setYAxisType] = useState(type === 'Type/Location' ? 'activityType' : type === 'Touchpoint' ? 'touchpoints' : '');
	const [yAxisType, setYAxisType] = useState(type.key);

	// The current value for the graph type select
	// const [graphType, setGraphType] = useState(type === 'Type/Location' ? { value: 'Activity Type', label: 'Activity Type'} : { value: 'Digital Touchpoint(s)', label: 'Digital Touchpoint(s)'});
	const [graphType, setGraphType] = useState({ value: type.label === 'Action Type' ? 'Action Type' :type.label , label: type.label === 'Action Type' ? 'Action Type' :type.label});
	// const [graphType, setGraphType] = useState({ value: 'Action Type', label:'Action Type' });

	useEffect(() => {
		// console.log(allFilters[graphType.value].key);

		// update row title
		allFilters[graphType.value] && setYAxisType(allFilters[graphType.value].key);
	}, [graphType])


	const { height, width } = useWindowDimensions();
	const columnData = useAppSelector(selectColumns);
	const totalColumns = useAppSelector(selectTotalColumns);

	// get Actions row data in order to map
	const pages = useAppSelector(selectPages);
	const rowData = pages.find(page => page.name === 'Experience Landscape')?.rows.find(row => row.title === 'Actions');

	const activeFilters = useAppSelector(selectActiveFilters);

	const dispatch = useAppDispatch();

		/** The locations to display across the x-axis */
	const [locations, setLocations] = useState<{ [key: string]: { [key: string]: string[] } }>({});

	const [tableStyle, setTableStyle] = useState<React.CSSProperties>({});

	const [tableData, setTableData] = useState<{ [key: string]: React.ReactNode }[]>([]);
	const [tableColumns, setTableColumns] = useState<TableTypes.Column<{ [key: string]: { [key: string]: string }[] }>[]>([]);

	/** object for easily accessing column dimensions */
	const subPhaseDimensions: types.GridColumnDimensions = columnData.subPhases.dimensions;


	const setStyles = () => {
		/** The width of all columns to set the table */
		const columnWidths = columnData.subPhases.columns.reduce((acc, col) => {
			acc += col.w;
			return acc;
		}, 0)

		// set styling for table
		setTableStyle({
			top: 0,
			// left: 40 + (subPhaseDimensions[columnData.subPhases.columns[0].title].x / totalColumns) * (size.width - 40), // 40px space for title
			// left: 40,
			// width: columnWidths * (size.width - 40),
			// width: (columnWidths / totalColumns) * ((width * 0.8) - 40),
		})
	}

	// on width change, update table styles
	useEffect(() => {
		setStyles();

		// console.log(tableStyle);
		// console.log(width);
	}, [width])


	// on initial render & rowData update
	useEffect(() => {

		// loop through each nested grid and identify columns to extract/display information
		const relevantData: { [key: string]: types.GridItem[] } = {};

		// console.log(activeFilters);
		rowData?.items.forEach(nestedGrid => {
			const columnName = nestedGrid.subPhase;

			// add items to be mapped, filtered based on currently active filters
			if (columnName) relevantData[columnName] = nestedGrid.items!.filter(item => {
				const properties = getProperties(item);

				let result = false;

				if (!Object.keys(activeFilters).length) return true; // if no filters, all items are ok
				else {
					// loop through each property
					for (let key in properties) {
						// loop through each value in this property
						properties[key].forEach(value => {
							// console.log(activeFilters[key]?.options.find(option => option.value === value));

							// if value exists in active filters, then return true
							if (activeFilters[key] && activeFilters[key].options.find(option => option.value === value))
								result = true;
						})
					}
				}
				return result;
			});

		})


		// console.log(relevantData);

		/** Object containing objects of arrays, with keys corresponding to subPhase column headings */
		// const newLocations = {...locations};
		const newLocations: { [key: string]: { [key: string]: string[] } } = {};

		// loop through each column
		for (let i in relevantData) {
			// if there are no items in this column, insert placeholder so column doesn't collapse
			if (!relevantData[i].length) {
				newLocations[i] = { '': [''] };
			} else {
				// loop through each item in this column
				relevantData[i].forEach(item => {

					// // get array of item locations from string
					// const itemLocations = semicolonSplit(item.properties.location?.value);
				
					// find this item's location property if it exists
					for (let propertyKey in item.properties) {
						if (item.properties[propertyKey].title === "Location(s)") {

							// loop through this item's array of locations, and add to locations object
							item.properties[propertyKey].values.forEach(location => {
								// console.log(location);
								if (!newLocations[i]) newLocations[i] = { [location]: [item.key] };
								else if (!newLocations[i][location]) newLocations[i][location] = [item.key];
								else {newLocations[i][location].push(item.key);};
							})
						}
					}
				})
			}
		}
				
		setLocations(newLocations);

		// add locations as table headers
		const newTableColumns: TableTypes.Column<{ [key: string]: { [key: string]: string }[] }>[] = [];

		// first column is for y-axis labels
		newTableColumns.push({
			Header: '',
			accessor: `col0`,
			// note: width of columns was off - so changed -40 (from row header) to compensate
			width: (0.1 * width) - 43,
		})

		let count = 1;
		for (let i in newLocations) {
			// console.log(newLocations[i]);
			/** The new columns for this grid column */
			const newColumns: { [key: string]: string }[] = [];

			// location columns
			for (let j in newLocations[i]) {
				newColumns.push({
					Header: j,
					accessor: `col${count}`,
					//@ts-ignore
					// note: width of columns was slightly too narrow, so dropped -40 to -31 to compensate (refactoring needed at some point)
					width: (subPhaseDimensions[i].w / totalColumns) * ((width * 0.8) - 31) / Object.keys(newLocations[i]).length,
				})
				count++;
			}

			// add all grid columns to table
			newTableColumns.push({
				Header: i,
				// accessor: `col${count}`,
				//@ts-ignore
				columns: newColumns,
				// width: (subPhaseDimensions[i].w / totalColumns) * (size.width! - 40),
				width: (subPhaseDimensions[i].w / totalColumns) * ((width * 0.8) - 40),
			})
			
		}

		// console.log({newTableColumns});
		setTableColumns(newTableColumns);


		// get y-axis labels
		const newLabels: { [key: string]: { items: string[] } } = {};

		for (let i in relevantData) {

			relevantData[i].forEach(item => {

				item.properties[yAxisType].values.forEach(type => {
					if (newLabels[type]) {
						newLabels[type].items.push(item.key);
					} else {
						newLabels[type] = { items: [item.key] };
					}
				})

			})
		}


		// add y-axis labels
		const newTableData: { [key: string]: React.ReactNode }[] = [];

		for (let property in newLabels) {
			newTableData.push({ col0: <div className="tr-label" title={property}>{property}</div> });
		}

		// arrays containing axis labels
		const xAxis: { column: string, label: string }[] = [];
		const yAxis: string[] = [];

		// console.log(newLocations);

		// get dimensions of array to prepopulate with empty values
		for (let i in newLocations) {
			// console.log(i, newLocations[i]);
			for (let j in newLocations[i]) {
				// console.log(j, newLocations[i][j]);
				xAxis.push({column: i, label: j});
			}
		}

		for (let i in newLabels) {
			yAxis.push(i);
		}

		// console.log(yAxis);

		/** 2D array containing table data, to be mapped into react-table after */
		const tableArray: types.GridItem[][][] = Array.from({length: yAxis.length}, e => 
			Array.from({length: xAxis.length}, e => new Array)
		);


		// loop through items (by column) and place each item in table data
		for (let column in relevantData) {
			// loop through each item in this column
			relevantData[column].forEach(item => {
				// console.log(item);
				let properties: { [key: string]: Set<string> } = {};

				for (let propertyKey in item.properties) {

					const property = item.properties[propertyKey];

					if (!properties[property.title]) {
						properties[property.title] = new Set(property.values);
					} else {
						property.values.forEach(value => {
							properties[property.title].add(value)
						})
					}
				}

				// console.log(graphType.value);

				// console.log(properties);

				// loop through y-axis labels and add to table
				yAxis.forEach((rowLabel, y) => {
					// console.log(graphType.value);
					// console.log(properties[yAxisType]);
					// console.log(properties, graphType.value);
					properties[graphType.value]?.forEach(type => {
						// console.log(type, rowLabel);
						if (type === rowLabel) {
							// console.log(xAxis);
							xAxis.forEach((columnLabel, x) => {
								// console.log('test1');
								properties['Location(s)']?.forEach(location => {
									// console.log(location);
									// console.log(columnLabel.column, column, columnLabel.label, location);
										// if item goes in this column, add it to this location in tableArray
									if (columnLabel.column === column && columnLabel.label === location) {
											// console.log('test');
										// console.log(y, x);
										// console.log(tableArray);
										// console.log(tableArray[y]);

										tableArray[y][x].push(item);
									}
								})
							})
						}
					})
				})
			})
		}

		// console.log(tableArray);

		// populate tableData from array
		tableArray.forEach((row, y) => {
			row.forEach((items, x) => {
				if (items.length) {


					newTableData[y][`col${x + 1}`] = 
						<Dot 
							props={{
								title: `${items.length} Actions`,
								id: `Opportunity-Row-${type.key}-${x}-${y}`,
							}}
							items={items}
							label={{ title: graphType.label, value: yAxis[y] }}
							subPhase={xAxis[x].column}
							location={xAxis[x].label}
							className={type.key === 'touchpoints' ? 'rectangle' : ''}
						>
							{type.key !== 'touchpoints' ? items.length : ''}
							{/* {items.length} */}
						</Dot>
				}
			})
		})

		setTableData(newTableData);
	}, [rowData, width, activeFilters, yAxisType])


	/** What to do when graph type select is changed */
	const handleGraphTypeChange = (value: any) => {
		setGraphType(value);
	}

	return (
		<>
			<div className={`DotRow GridRow-container DotRow-${type.key}`}>

				{type.key === 'activityType' &&
					<div className="type-select">
						<label htmlFor="opportunity-type-select" className="sr-only">Select Opportunity Type</label>
						<ReactSelect
							id="opportunity-type-select"
							// placeholder={filters[filterKey].title}
							className="Select"
							options={Object.keys(allFilters)
								.sort()
                .map(filter => {
								return { value: filter, label: filter}
								})
								.filter(filter => filter.value !== 'Title')}
							onChange={handleGraphTypeChange}
							value={graphType}
						/>
					</div>
				}

				<SizeMe
				monitorHeight
				//@ts-ignore
				render={({ size }) => 
					<>
						<div 
							className="DotRowTable-container"
							id={`Opportunity-Row-${type.key}-container`}
							style={{
								...tableStyle,
							}}
						>
							{/* @ts-ignore */}
							<DotRowTable columns={tableColumns} data={tableData} id={`Opportunity-Row-${type.key}`} />
						</div>
						<div className="spacer" style={{ height: size.height }}></div>
					</>
				} />

				<div 
					className="title-area"
				>
					<h2>{rowTitle}</h2>
				</div>
			</div>
			<div className="backgroundMask"></div>
		</>
	)
}

export default DotRow;
