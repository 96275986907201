import { combineReducers, configureStore, DeepPartial } from '@reduxjs/toolkit';

import userReducer, {
  initialState as userInitialState,
	// UserState,
} from "slices/userSlice";
import projectReducer from 'slices/projectSlice';
import dimensionReducer, {DimensionState} from 'slices/dimensionSlice';
// import layoutReducer from 'slices/layoutSlice';
import miscReducer from 'slices/miscSlice';

import * as authApi from "api/auth";

import * as types from 'types';


// /**
//  * Sets preloadedState with logged in user data if there is an active session
//  * @returns preloadedState
//  */
// export async function setPreloadedUserState() {
//   let preloadedState = {
//     user: { ...userInitialState },
//   };

//   try {
//     const response = await authApi.getUserSession();

//     if (response.data.data) {
//       preloadedState.user.data = response.data.data;
//       preloadedState.user.isLoggedIn = true;
//     }
//   } catch (error) {
//     console.log(error);
//   }

//   return preloadedState;
// }


const store = configureStore({
	reducer: {
		user: userReducer,
		project: projectReducer,
		dimension: dimensionReducer,
		misc: miscReducer,
	},
	devTools: process.env.NODE_ENV !== 'production',
	// preloadedState: await setPreloadedUserState(),
})

export default store;


// /**
//  * Returns the configured redux store after setting the preloaded user state object
//  */
// export const initializeStore = async () => {
// 	const store = configureStore({
// 		reducer: {
// 			user: userReducer,
// 			dimension: dimensionReducer,
// 			row: rowReducer,
// 			misc: miscReducer,
// 		},
// 		devTools: process.env.NODE_ENV !== 'production',
// 		preloadedState: await setPreloadedUserState(),
// 	});

// 	type RootState = ReturnType<typeof store.getState>
// 	type AppDispatch = typeof store.dispatch;

// 	return store;
// };


// type ThenArgRecursive<T> = T extends PromiseLike<infer U> ? ThenArgRecursive<U> : T;
// type Store = ThenArgRecursive<Promise<Promise<Promise<ReturnType<typeof initializeStore>>>>>;


// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

// // Infer the `RootState` and `AppDispatch` types from the store itself
// export type RootState = ReturnType<typeof store.getState>
// // Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
// export type AppDispatch = typeof store.dispatch;