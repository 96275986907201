import React, { useState } from "react";
import Xarrow from "react-xarrows";

import ArrowPopup from '../Popup/ArrowPopup';

import * as types from 'types';

type XarrowProps = {
	rowData: types.GridRow;
	id: string;
	start: string;
	end: string;
	curveness: number;
	SVGcanvasStyle?:React.CSSProperties;
	arrowId: string;
	passProps?: object;
}

export default ({ rowData, id, start, end, curveness, SVGcanvasStyle, arrowId, passProps, ...props }: XarrowProps) => {
	const containerId = `${id}-container`;

	const arrowProps = {
		color: "#555555",
		strokeWidth: 1,
		headSize: 8,
		path: "grid" as const,
	};

  const [color, setColor] = useState("#454443");
	const [showPopup, setShowPopup] = useState(false);
	const [popupPosition, setPopupPosition] = useState<{ x: number, y: number }>({ x: 0, y: 0 });
  const defProps = {
    passProps: {
			id: id,
      className: "xarrow",
      onMouseEnter: () => setColor("#EF6C00"),
      onMouseLeave: () => setColor("#555555"),
      onClick: (e: React.MouseEvent<SVGRectElement>) => {
        e.stopPropagation(); //so only the click event on the box will fire on not on the container itself
				console.log(e);
				console.log(arrowId);
				setShowPopup(!showPopup);

				const position = { x: e.clientX, y: e.clientY };
				setPopupPosition(position);
      },
      cursor: "pointer",
			...passProps,
    },
  };

	const closePopup = () => {
		setShowPopup(false);
	}

  return (
		<div className="Xarrow-container" id={containerId}>
			<Xarrow 
				start={start}
				end={end}
				curveness={curveness}
				SVGcanvasStyle={SVGcanvasStyle}
				{...{  ...defProps, ...props, ...arrowProps, color }}
			/>

			{showPopup && 
				<ArrowPopup
					rowData={rowData}
					parentId={id}
					containerId={containerId}
					closePopup={closePopup}
					popupPosition={popupPosition}
				/>
			}
		</div>
	)
};