import React, { useState, useEffect } from 'react';

import Modal from '../../Modal';
import PropertyDisplay from './PropertyDisplay';
import InputForm from './InputForm';

// Redux
import { useAppDispatch } from 'app/hooks';
import { editItem } from 'slices/projectSlice';
import { setMovingItem } from 'slices/miscSlice';

// images
import { ReactComponent as IconPlus } from 'assets/images/icon-plus.svg';
import { ReactComponent as IconConnectArrow } from 'assets/images/icon-connect-arrow.svg';

import * as types from 'types';

import './style.scss';

interface ActiveProperties {
  [key: string]: {
    active: boolean;
    values: boolean;
  };
}

interface PropertyWithKey extends types.GridItemProperty {
  key: string;
}

interface ActiveInputs {
  title: boolean;
  description: boolean;
  properties: ActiveProperties;
}

interface ToggleEditProps {
  shouldOpen: boolean;
  field: keyof ActiveInputs;
  property?: string;
  isValue?: boolean;
}

export type InputType = 'input' | 'textarea' | 'select' | 'multiSelect';

const additionalProperties = [
  'Challenge',
  'Impact',
  'Priority',
  'Effort',
  'Notes',
  'Scenario(s)',
];

type GridItemEditModalProps = {
  openModal?: () => void;
  closeModal: () => void;
  modalIsOpen: boolean;
  itemData: types.GridItem;
  /**
   * If provided, the specified field will be open for editing when modal is opened
   * (only set up for title currently)
   */
  activeField?: string;
  controls?: React.ReactNode;
  confirmDelete?: boolean;
  handleDelete?: () => void;
  drawArrow?: () => void;
};

const GridItemEditModal = ({
  openModal,
  closeModal,
  modalIsOpen,
  itemData,
  activeField,
  controls,
  confirmDelete,
  handleDelete,
  drawArrow,
}: GridItemEditModalProps) => {
  /** True if currently adding a new property */
  const [isAddingNewProperty, setIsAddingNewProperty] = useState(false);

  interface PropertyWithKey extends types.GridItemProperty {
    key: string;
  }

  /** Array of custom properties to list for this item */
  const [customProperties, setCustomProperties] = useState<PropertyWithKey[]>(
    []
  );
  useEffect(() => {
    const newCustomProperties: PropertyWithKey[] = [];
    for (let i in itemData.properties) {
      if (itemData.properties[i].type === 'custom') {
        newCustomProperties.push({ ...itemData.properties[i], key: i });
      }
    }
    setCustomProperties(newCustomProperties);
  }, [itemData.properties]);

  /** Object containing property names to check if exists already */
  const [propertyNames, setPropertyNames] = useState<{
    [key: string]: boolean;
  }>({});
  useEffect(() => {
    const newPropertyNames: { [key: string]: boolean } = {};
    for (let i in itemData.properties) {
      newPropertyNames[itemData.properties[i].title] = true;
    }
    setPropertyNames(newPropertyNames);
  }, [itemData.properties]);

  const dispatch = useAppDispatch();

  /** Add a new field with provided name */
  const addProperty = (fieldName: string) => {
    dispatch(
      editItem({
        _id: itemData._id,
        page: itemData.page,
        row: itemData.row,
        subPhase: itemData.subPhase,
        field: fieldName,
        value: [],
      })
    );
  };

  /** If true, show actual delete button */
  // const [confirmDelete, setConfirmDelete] = useState(false);

  // /** Delete this item */
  // const handleDelete = () => {
  // 	dispatch(editItem({
  // 		_id: itemData._id,
  // 		page: itemData.page,
  // 		row: itemData.row,
  // 		subPhase: itemData.subPhase,
  // 		action: 'remove',
  // 	}));

  // 	closeModal();
  // }

  const handleMove = () => {
    dispatch(setMovingItem(itemData));
    closeModal();
  };

  return (
    <Modal
      openModal={openModal}
      closeModal={closeModal}
      modalIsOpen={modalIsOpen}
      className="GridItemEditModal"
      controls={controls}
    >
      {/* Title */}
      <h2>
        <PropertyDisplay
          itemData={itemData}
          inputType="input"
          field="title"
          startActive={activeField === 'title'}
          required
        />
      </h2>

      <div className="content">
        <div className="properties">
          {/* Activity Type */}
          {itemData.properties.activityType && (
            <div className="property property--activityType">
              <span className="property-label">Action Type:</span>
              <PropertyDisplay
                itemData={itemData}
                inputType="multiSelect"
                field="activityType"
                // required
              />
            </div>
          )}
          {/* Phase / Sub-phase */}
          {itemData.phase && itemData.subPhase && (
            <div className="property property--phase">
              <span className="property-label">Phase / Sub-phase:</span>
              <span className="input">
                {itemData.phase} / {itemData.subPhase}
              </span>
            </div>
          )}
          {/* Location */}
          {itemData.properties.locations && (
            <div className="property property--locations">
              <span className="property-label">Location:</span>
              <PropertyDisplay
                itemData={itemData}
                inputType="multiSelect"
                field="locations"
                // required
              />
            </div>
          )}
          {/* Touchpoint */}
          {itemData.properties.touchpoints && (
            <div className="property property--touchpoints">
              <span className="property-label">Touchpoint:</span>
              <PropertyDisplay
                itemData={itemData}
                inputType="multiSelect"
                field="touchpoints"
              />
            </div>
          )}
          {/* Touchpoint Type */}
          {itemData.properties.touchpointType && (
            <div className="property property--touchpointType">
              <span className="property-label">Touchpoint Type:</span>
              <PropertyDisplay
                itemData={itemData}
                inputType="multiSelect"
                field="touchpointType"
              />
            </div>
          )}
          {/* Team */}
          {itemData.properties.people && (
            <div className="property property--people">
              <span className="property-label">Team:</span>
              <PropertyDisplay
                itemData={itemData}
                inputType="multiSelect"
                field="people"
              />
            </div>
          )}
          {/* Pain Points */}
          {itemData.properties.challenges && (
            <div className={`property property--challenges`}>
              <span className="property-label">Pain Points:</span>
              <PropertyDisplay
                itemData={itemData}
                inputType="multiSelect"
                field="challenges"
              />
            </div>
          )}
          {/* Opportunity */}
          {itemData.properties.opportunities && (
            <div className={`property property--opportunities`}>
              <span className="property-label">Opportunity:</span>
              <PropertyDisplay
                itemData={itemData}
                inputType="multiSelect"
                field="opportunities"
              />
            </div>
          )}
          {/* Opportunity Description */}
          {itemData.properties.opportunityDescription && (
            <div
              className={
                `property property--opportunityDescription` /* ${ itemData.properties['opportunityDescription'].values[0]?.length > 30 ? 'newLine' : '' } */
              }
            >
              <span className="property-label">Opportunity Description:</span>
              <PropertyDisplay
                itemData={itemData}
                inputType="textarea"
                field="opportunityDescription"
              />
            </div>
          )}
          {/* Solution Type */}
          {itemData.properties.solutionType && (
            <div className="property property--solutionType">
              <span className="property-label">Solution Type(s):</span>
              <PropertyDisplay
                itemData={itemData}
                inputType="multiSelect"
                field="solutionType"
              />
            </div>
          )}
          {/* Business Value */}
          {itemData.properties.businessValue && (
            <div className="property property--businessValue">
              <span className="property-label">Business Value:</span>
              <PropertyDisplay
                itemData={itemData}
                inputType="multiSelect"
                field="businessValue"
              />
            </div>
          )}
          {/* Impact */}
          {itemData.properties.impact && (
            <div className="property property--impact">
              <span className="property-label">Impact:</span>
              <PropertyDisplay
                itemData={itemData}
                inputType="multiSelect"
                field="impact"
              />
            </div>
          )}
          {/* Risk */}
          {itemData.properties.risk && (
            <div className="property property--risk">
              <span className="property-label">Risk:</span>
              <PropertyDisplay
                itemData={itemData}
                inputType="multiSelect"
                field="risk"
              />
            </div>
          )}
          {/* Revenue Type */}
          {itemData.properties.revenueStream && (
            <div className="property property--revenueStream">
              <span className="property-label">Revenue Type:</span>
              <PropertyDisplay
                itemData={itemData}
                inputType="multiSelect"
                field="revenueStream"
              />
            </div>
          )}
          {/* Data Sources */}
          {itemData.properties.dataSource && (
            <div className="property property--dataSource">
              <span className="property-label">Data Source:</span>
              <PropertyDisplay
                itemData={itemData}
                inputType="multiSelect"
                field="dataSource"
              />
            </div>
          )}
          {/* New/Custom Properties */}
          {customProperties.map(
            (property) =>
              itemData.properties[property.key] && (
                <div className="property" key={property.title}>
                  <span className="property-label">
                    {property.required ? (
                      property.title
                    ) : (
                      <PropertyDisplay
                        itemData={itemData}
                        inputType="input"
                        field={property.key}
                        label
                        required
                      />
                    )}
                    :
                  </span>
                  <PropertyDisplay
                    itemData={itemData}
                    inputType="multiSelect"
                    field={property.key}
                  />
                </div>
              )
          )}
          {/* Custom Property */}
          {isAddingNewProperty && (
            <div className="property property--custom">
              <span className="property-label">New Property:</span>
              <InputForm
                itemData={itemData}
                // field={addingNewProperty}
                defaultValue={[]}
                inputType="input"
                onCancel={() => setIsAddingNewProperty(false)}
                required
                propertyNames={propertyNames}
              />
            </div>
          )}
        </div>

        <div className="actions">
          <div className="controls--properties">
            <h4>Properties</h4>
            <ul>
              {additionalProperties.map(
                (property) =>
                  !propertyNames[property] && (
                    <li key={property}>
                      <button
                        className="btn"
                        onClick={() => addProperty(property)}
                      >
                        {property}
                      </button>
                    </li>
                  )
              )}
              <li>
                <button
                  className="btn btn-addProperty"
                  onClick={() => setIsAddingNewProperty(true)}
                >
                  <IconPlus />
                  <span>Add property</span>
                </button>
              </li>
            </ul>
          </div>
          {/* <div className="controls--actions">
						<h4>Actions</h4>
						<ul>
							<li><button className="btn" onClick={handleMove}>Move</button></li>
							<li><button className="btn">Copy</button></li> */}
          {/* <li className="delete">
								{!confirmDelete ?
									<button className="btn" onClick={() => setConfirmDelete(true)}>Delete</button>
								:
									<div className="confirmDelete">
										<button className="btn" onClick={handleDelete}>Confirm Delete</button>
										<button className="btn" onClick={() => setConfirmDelete(false)}>Cancel</button>
									</div>
								}
							</li> */}
          {/* </ul>
					</div> */}
        </div>
      </div>

      {confirmDelete && (
        <div className="confirmDelete">
          <button
            className="btn btn-form btn-delete"
            title="Confirm Delete"
            aria-label="Confirm Delete"
            onClick={() => {
              handleDelete && handleDelete();
              closeModal();
            }}
          >
            Confirm Delete
          </button>
        </div>
      )}

      {drawArrow && itemData?.row === 'Locations' && (
        <button
          className="btn btn-control btn-arrow"
          title="Draw Arrow"
          aria-label="Draw Arrow"
          onClick={() => {
            drawArrow && drawArrow();
            closeModal();
          }}
        >
          {/* <FontAwesomeIcon icon={faLongArrowAltRight} /> */}
          Connectors
          <IconConnectArrow />
        </button>
      )}
    </Modal>
  );
};

export default GridItemEditModal;
