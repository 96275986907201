import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import GridLayout from 'react-grid-layout';
// import Xarrow from "react-xarrows";

import Xarrow from '../Xarrow';

// Redux
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { 	
	addItems,
	selectTotalColumns,
} from 'slices/projectSlice';
import {
	selectScale,
	selectZoomedIn,
	selectRowHeight,
} from "slices/dimensionSlice";
import { updateRow, /* setRowAsync, */ setRow, /* selectRows, */ setDragging, selectEditMode, selectNestedLoading } from 'slices/projectSlice';
import { selectCreatingItem, selectMovingItem, setCreatingItem, setMovingItem } from 'slices/miscSlice';

// import { useForceUpdate } from 'app/hooks';

import * as layoutApi from 'api/layout';

import * as types from '../../../types';
import './style.scss';
import GridItemEditModal from '../GridItem/GridItemEditModal';


type GridRowProps = {
	rowData: types.GridRow;
	id?: string;
	size: types.sizeme;
	title: string;
	layout?: GridLayout.Layout[];
	children: React.ReactNode;
	margin?: [number, number];
	rowHeight?: number;
	className?: string;
	verticalCompact?: boolean;
	onLayoutChange?: (layout: GridLayout.Layout[]) => void;
	onDragStart?: GridLayout.ItemCallback;
	onResizeStart?: GridLayout.ItemCallback;
	onDragStop?: GridLayout.ItemCallback;
	onResizeStop?: GridLayout.ItemCallback;
}

const GridRow = ({ rowData, id, size, title, layout, children, margin, rowHeight, className, verticalCompact, onLayoutChange, onDragStart, onResizeStart, onDragStop, onResizeStop }: GridRowProps): JSX.Element => {
	// console.log(children);

	const editMode = useAppSelector(selectEditMode);
	const nestedLoading = useAppSelector(selectNestedLoading);

	const totalColumns = useAppSelector(selectTotalColumns);
	const defaultRowHeight = useAppSelector(selectRowHeight);

	// const rowData = useAppSelector(state => selectRow(state, title));
	const dispatch = useAppDispatch();

	const [, setRender] = useState({});
	const forceRerender = () => setRender({});

	const [style, setStyle] = useState<React.CSSProperties>({});

	// /** bolean for rerendering */
	// const [bool, setBool] = useState(true);

	/** temporary hack - change this state to trigger arrows to rerender */
	const [arrowRender, setArrowRender] = useState(0);

	const scale = useAppSelector(selectScale);

	// const movingItem = useAppSelector(selectMovingItem);
	const creatingItem = useAppSelector(selectCreatingItem);

	const [newItem, setNewItem] = useState<types.GridItem>();

	// const [isMinimized, setIsMinimized] = useState(true);

	// const [isZoomedIn, setZoomedIn] = useState(false);

	// const isZoomedIn = useAppSelector(selectZoomedIn);

	/** True if this component is on it's first render */
  const firstUpdate = useRef(true);

	// set firstUpdate to false after first render
  useLayoutEffect(() => {
		if (firstUpdate.current) {
			firstUpdate.current = false;
			return;
		}
	})

	useEffect(() => {
		dispatch(setRow({...rowData}))
	}, [editMode, scale])

	// useEffect(() => {
	// 	forceRerender();
	// }, [editMode])

	// useEffect(() => {
	// 	// console.log(scale);
	// 	// if (scale === 1) setZoomedIn(false);
	// 	// else setZoomedIn(true);
	// 	// // forceUpdate();
	// 	// console.log(isZoomedIn);
	// }, [scale])

	
	/** Trigger a delayed rerender to account for initial placement animation */
	// useEffect(() => {
	// 	delayedRerender(400);
	// 	// forceRerender && forceRerender();
	// }, [])

	// /** force rerender after delay to account for animation time */
	// const delayedRerender = (delay = 200) => {
	// 	console.log('delayedRerender');
	// 	forceRerender && setTimeout(() => {
	// 		 forceRerender();
	// 	}, delay);
	// }
	
	// const handleDelayedRerender = () => {
	// 	delayedRerender();
	// }

	// const childrenWithProps = React.Children.map(children, child => {
	// 	// checking isValidElement is the safe way and avoids a typescript error too
	// 	if (React.isValidElement(child)) {
	// 		return React.cloneElement(child, { ...child.props, forceRerender: forceRerender });
	// 	}
	// 	return child;
	// });


	/** force rerender after delay to account for animation time */
	const delayedRerender = (delay = 200) => {
		// console.log('delayedRerender');
		setTimeout(() => {
			 forceRerender();
			 setArrowRender(arrowRender+0.1);
		}, delay);

		// don't let value get too high, avoid potential bugs
		if (arrowRender > 100) {
			setArrowRender(0)
		}
	}

	useEffect(() => {
		if (rowData.arrows && rowData.arrows.length) {
			delayedRerender(600);
			delayedRerender(800);
			delayedRerender(1000);
		}
	}, [])


	useEffect(() => {
		if (rowData.arrows && rowData.arrows.length) {
			setArrowRender(arrowRender+0.1);
			// hack - delay rerender to account for animations
			delayedRerender(50);
			delayedRerender(100);
			delayedRerender(150);
			delayedRerender(200);
			delayedRerender(300);
		}
	}, [size, rowData.layout])



	const handleDrag = () => {
		// console.log('dragging');
		dispatch(setDragging(true));
	}

	const handleDragStop = () => {
		// console.log('stopped dragging');
		setTimeout(() => {
			dispatch(setDragging(false));
		}, 100);
	}



	/** update layout in state when changed */
	const handleLayoutChange = async (newLayout: GridLayout.Layout[]) => {
		// Run only after first render
		if (!firstUpdate.current) {
			if (!rowData) console.log('No row data for ' + title);
			else {
				const newGridItems = rowData.items.map((item: types.GridItem, index: number) => {
					// requires item keys to be equal to sequential index integers (refactor in future to allow unique keys)
					// if (parseInt(item.key) !== index) console.error('array misalignment');
					// else {
						const newItem = {...item};
						newItem['data-grid'] = newLayout[index];
						return newItem;
					// }
				})
				console.log({newLayout, newGridItems});
				dispatch(updateRow({ ...rowData, items: newGridItems }));
				// dispatch(setRowAsync({ ...rowData, items: newGridItems }));
			}
		}
	};

	// //@ts-ignore
	// const handleDrop = (layout, layoutItem, _event) => {
	// 	// console.log('Drop:', layout, layoutItem, _event);
	// 	alert(`Dropped element props:\n${JSON.stringify(layoutItem, ['x', 'y', 'w', 'h'], 2)}`);
	// }

	useEffect(() => {
		if (rowData.type === 'nested') {
			setStyle({height: rowData.height})
		}
	}, [rowData])


	// console.log(layout, style);
	// console.log(children);


	const handleCreateItem = async () => {
		dispatch(setCreatingItem(false));

		// send request to db to create new item
		const response = await dispatch(addItems({
			items: [
				{
					page: rowData.page,
					row: rowData.title,
					properties: {
						title: {
							title: 'Title',
							values: ['New Item'],
							required: true,
							type: 'string',
						}
					}
				}
			],
			method: 'append',
		}));

		console.log(response);
		if (response.payload[0]) {
			setNewItem(response.payload[0]);
		}
	}

	// console.log(rowData.title, margin);

	return (
		<div className={`GridRow ${className ? className : ''}`} id={id}>
			{/* {title && <h2>{title}</h2>} */}
			<GridLayout
				className="GridRow-content"
				layout={layout ? layout: rowData.layout}
				cols={totalColumns}
				rowHeight={rowData.title === 'Phases' ? 25 : rowHeight ? rowHeight : defaultRowHeight}
				margin={margin ? margin : [0, 0]}
				// containerPadding={[5, 5]}
				width={(size.width * 0.8)}
				draggableCancel='.draggableCancel'
				// onResize={forceRerender}
				// onResizeStop={forceRerender}
				// onDrag={forceRerender}
				// onDragStop={forceRerender}
				onDrag={handleDrag}
				onDragStop={handleDragStop}
				// onDragStart={handleDragStart}
				onResizeStart={onResizeStart}
				onResizeStop={onResizeStop}
				onLayoutChange={handleLayoutChange/* onLayoutChange ? (layout) => onLayoutChange(layout) : undefined */}
				verticalCompact={verticalCompact}
				transformScale={scale}
				isDraggable={editMode} // prevent dragging when zoomed (temp bug fix/hack)
				isResizable={editMode}
				resizeHandles={!editMode ? [] : undefined}

				style={style}
				containerPadding={(rowData.title !== 'Phases' && rowData.title !== 'Actions') ? [4, 10] : undefined}

				resizeHandle={rowData.title === 'Phases' ? <span></span> : undefined}
				// isDroppable={true}
				// onDrop={handleDrop}

				{...rowData.gridLayoutProps}
			>
				{children}
			</GridLayout>

			{/* {isZoomedIn ? 'zoomed IN' : 'zoomed OUT'} */}

			{/* Arrows */}
			{nestedLoading ? '' : !rowData.arrows?.length ? '' : rowData.arrows.map((arrow, index) => {
				if (arrow.start && arrow.end)
					return <Xarrow
						key={arrow.key}
						id={arrow.key!}
						arrowId={arrow.key!}
						start={arrow.start}
						end={arrow.end}
						rowData={rowData}
						
						curveness={arrowRender} // used to force rerender - don't actually use curves with this property!
						// SVGcanvasStyle={{
						// 	zIndex: 600,
						// 	transform: `scale(${1 / scale})`
						// }}
						// passProps={{
						// 	transform: `scale(${1 / scale})`
						// }}
						// passProps={{
						// 	cursor: 'pointer',
						// 	onClick: () => console.log(arrow.key + " clicked!")
						// }}
						// {...arrowProps}
					/>
			}
			)}

			{/* Create item button */}
			{(creatingItem && (rowData.title !== 'Phases' && rowData.title !== 'Actions')) && (
				<button
					onClick={handleCreateItem}
					style={{
						width: (size.width * 0.8),
						height: '100%',
						// transform: "translateX(0, 5px)",
						position: 'absolute',
						left: 'calc(10% - 20px)',
						top: 0,
						zIndex: 1000,
						border: "1px solid red",
					}}
				></button>
			)}

			{newItem &&
				<GridItemEditModal 
					// openModal={openEditModal}
					closeModal={() => setNewItem(undefined)}
					modalIsOpen={newItem !== undefined}
					itemData={newItem}
					activeField="title"
				/>
			}

		</div>
	);
}

export default GridRow;
