import React, { useEffect, useLayoutEffect } from 'react';
import { Link, Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import PublicRoute from 'routes/PublicRoute';
import PrivateRoute from 'routes/PrivateRoute';

import LoginForm from './components/forms/LoginForm';
import SignupForm from './components/forms/SignupForm';

import HomePage from 'components/HomePage';
import AccountPage from 'components/AccountPage';

import NotFoundPage from './components/NotFoundPage';

import VentureMappingTool from './components/VentureMappingTool';

// Redux
import { useAppSelector, useAppDispatch } from 'app/hooks';
import {
  selectUser,
  getSession,
  selectInitialized,
  selectAvatar,
  selectLoggedIn,
  logoutAsync,
} from 'slices/userSlice';

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

import './styles/scss/main.scss';

// images
import logo from './assets/images/5-10-light-logo.png';
import fivepointsLogo from './assets/images/five points logo.svg';
import { ReactComponent as IconQuestion } from './assets/images/icon-question-mark.svg';
import { ReactComponent as IconBell } from './assets/images/icon-bell.svg';
import { ReactComponent as IconUserDefault } from 'assets/images/icon-user-default.svg';

import DropdownMenu from 'components/VentureMappingTool/DropdownMenu';

/** Browser history for React Router */
const hist = createBrowserHistory();

function App() {
  // const user = useAppSelector(selectUser);
  const initialized = useAppSelector(selectInitialized);
  const userAvatar = useAppSelector(selectAvatar);
  const user = useAppSelector(selectUser);
  const isLoggedIn = useAppSelector(selectLoggedIn);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getSession());
  }, []);

  useLayoutEffect(() => {
    chooseFavicon();
  }, []);

  /** Change the favicon depending on if the browser is in dark mode or light mode */
  const chooseFavicon = () => {
    const lightSchemeIcon = document.querySelector('link#light-scheme-icon');
    const darkSchemeIcon = document.querySelector('link#dark-scheme-icon');
    const matcher = window.matchMedia('(prefers-color-scheme: dark)');

    const matchColorScheme = () => {
      if (lightSchemeIcon && darkSchemeIcon) {
        if (matcher.matches) {
          lightSchemeIcon.remove();
          console.log(darkSchemeIcon);
          document.head.append(darkSchemeIcon);
        } else {
          document.head.append(lightSchemeIcon);
          console.log(lightSchemeIcon);
          darkSchemeIcon.remove();
        }
      }
    };

    // call once on page load
    matchColorScheme();

    matcher.addEventListener('change', matchColorScheme);
  };

  const handleLogout = () => {
    dispatch(logoutAsync());
  };

  return (
    <div className="App">
      {/* <div className="wrapper"> */}

      {initialized ? (
        <Router history={hist}>
          <header>
            <div className="wrapper">
              <div className="logoTitle">
                <Link to="/">
                  <img src={logo} alt="logo" />
                </Link>
                <span className="copyright">{`© ${new Date().getFullYear()} Five Points Ventures Inc.`}</span>
                {/* <img src={fivepointsLogo} alt="Five Points" title="Five Points" className="fivepointsLogo" /> */}
              </div>

              {isLoggedIn && (
                <div className="menu">
                  {/* User */}

                  <DropdownMenu
                    heading={
                      <div className="dropdown-user" title="My Account">
                        {userAvatar ? (
                          <img
                            className="userAvatar"
                            src={userAvatar}
                            alt="User Avatar"
                            key={/* user.data.avatarId */ Date.now()}
                          />
                        ) : (
                          <IconUserDefault />
                        )}
                        {`${user.data.firstName}`}
                      </div>
                    }
                  >
                    {isLoggedIn ? (
                      <>
                        <li>
                          <Link
                            to="/"
                            className="btn btn-control"
                            title="My Account"
                          >
                            <FontAwesomeIcon icon={faUser} /> My Account
                          </Link>
                          {/* <button className="btn btn-control"><FontAwesomeIcon icon={faUser} /> My Account</button> */}
                        </li>
                        <li>
                          <button
                            onClick={handleLogout}
                            className="btn btn-control"
                            title="Log Out"
                          >
                            <FontAwesomeIcon icon={faSignOutAlt} /> Log Out
                          </button>
                        </li>
                      </>
                    ) : (
                      <li>
                        <button className="btn btn-control">Log In</button>
                      </li>
                    )}
                  </DropdownMenu>

                  <button className="btn btn-disabled" title="Help">
                    {/* <img src={iconQuestion} alt="Help" /> */}
                    <IconQuestion />
                  </button>
                  <button className="btn btn-disabled" title="Notifications">
                    {/* <img src={iconBell} alt="Notifications" /> */}
                    <IconBell />
                  </button>
                </div>
              )}
            </div>
          </header>

          <main>
            <Switch>
              <PublicRoute
                component={SignupForm}
                exact
                path="/signup"
                restricted
              />
              <PublicRoute
                component={LoginForm}
                exact
                path="/login"
                restricted
              />

              <PrivateRoute component={AccountPage} exact path="/account" />
              <PrivateRoute
                component={VentureMappingTool}
                exact
                path="/project/:org/:project"
              />

              <PrivateRoute component={HomePage} exact path="/" />

              <PublicRoute component={NotFoundPage} path="/**/" />
            </Switch>
          </main>
        </Router>
      ) : (
        <p>loading...</p>
      )}
      {/* </div> */}

      {/* <footer> */}
      {/* <div className="wrapper"> */}
      {/* <p className="copyright">© 2021 Five Points Ventures Inc.</p> */}
      {/* </div> */}
      {/* </footer> */}
    </div>
  );
}

export default App;
