import React, { useState } from 'react';
import { useForm } from "react-hook-form";

import * as projectsApi from 'api/projects';

import * as types from 'types';
import './style.scss';

export type ProjectFormInputs = {
	name: string,
	description: string,
	projectType: 'new' | 'demo';
}

type ProjectFormProps = {
	organization: types.Organization;
	onSubmit: () => void;
}

const ProjectForm = ({ organization, onSubmit }: ProjectFormProps) => {
	const [error, setError] = useState('');

	const { register, handleSubmit, formState: { errors } } = useForm<ProjectFormInputs>({
		mode: "onTouched",
	});

	const handleCreateProject = async (inputData: ProjectFormInputs) => {
		try {
			// const newData = {...inputData, organization}

			const { data }: any = await projectsApi.createNew(inputData, organization._id);

			console.log(data);

			onSubmit();
		} catch (error) {
			console.error(error);
			if (error.response.data.message) {
				setError(error.response.data.message);
			} else {
				setError('Error creating project');
			}
		}
	};

	return (
		<form onSubmit={handleSubmit(handleCreateProject)} className="form ProjectForm">
			<h2>Create New Project</h2>

			<h3><img src={organization.logo} alt="Logo" /> {organization?.name}</h3>

			<div className="inputs">
				<div className="input">
					<label htmlFor="projectName" className="sr-only">Project Name</label>
					<input
						type="text"
						// autoComplete="organization"
						id="projectName"
						placeholder="Project Name"
						{...register("name", { required: true })}
					/>
					{errors.name && <span className="error">* This field is required</span>}
				</div>
				<div className="input">
					<label htmlFor="projectDescription" className="sr-only">Project Description</label>
					<input
						type="text"
						id="projectDescription"
						placeholder="Project Description"
						{...register("description")}
					/>
				</div>
				<div className="input radio">
					<label htmlFor="newProjectRadio">New Project</label>
					<input
						type="radio"
						id="newProjectRadio"
						value="new"
						{...register("projectType", { required: true })}
					/>
				</div>
				<div className="input radio">
					<label htmlFor="demoProjectRadio">Demo Project</label>
					<input
						type="radio"
						id="demoProjectRadio"
						value="demo"
						{...register("projectType", { required: true })}
					/>
				</div>
				{errors.projectType && <span className="error">* This field is required</span>}
			</div>

			

			<button type="submit" className="btn btn-form">Create Project {'>'}</button>

			{error && <span className="error">{error}</span>}
		</form>
	)
}

export default ProjectForm;
