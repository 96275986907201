import React from 'react';
import { useForm, SubmitHandler } from "react-hook-form";

// Redux
import { useAppSelector } from 'app/hooks' 
import { selectProject } from 'slices/projectSlice';

import './style.scss';


export type ImportFormInputs = {
	/** The selected row */
  selectRow: string,
	/** append or overwrite */
  importMethod: 'overwrite' | 'append' | 'new',
};

type ImportFormProps = {
	onSubmit: SubmitHandler<ImportFormInputs>;
}

const ImportForm = ({ onSubmit }: ImportFormProps) => {
	const projectData = useAppSelector(selectProject);

	const { register, handleSubmit, formState: { errors } } = useForm<ImportFormInputs>({
		mode: "onTouched",
	});

	return (
		<form onSubmit={handleSubmit(onSubmit)} className="ImportForm">
			<div className="input">
				<label htmlFor="selectRow">Select Row:</label>
				<select
					// name="selectRow"
					id="selectRow"
					{...register("selectRow", { required: true })}
				>
					{/*//* Page is hardcoded for now */}
					{projectData.pages.find(page => page.name === 'Experience Landscape')?.rows.map(row =>
						<option value={row.title}>{row.title}</option>
					)}
				</select>
			</div>

			{errors.selectRow && <span className="formError">This field is required</span>}

			{/* <fieldset> */}
				<div className="input">
					<input
						type="radio"
						// name="importMethod"
						value="append"
						id="append"
						{...register("importMethod", { required: true })}
					/>
					<label htmlFor="append">Append To Row</label>
				</div>
				<div className="input">
					<input
						type="radio"
						// name="importMethod"
						value="overwrite"
						id="overwrite"
						{...register("importMethod", { required: true })}
					/>
					<label htmlFor="overwrite">Overwrite Row</label>
				</div>
			{/* </fieldset> */}
			{errors.importMethod && <span className="formError">This field is required</span>}

			<button type="submit" className="btn btn-form">Confirm</button>
		</form>
	);
}

export default ImportForm;
