import React, { useMemo, useCallback, useEffect, useState } from 'react'
import Table, { useTable, useBlockLayout } from 'react-table';
import { FixedSizeList } from 'react-window';
import scrollBarWidth from 'utils/scrollBarWidth';

import * as types from 'types';

import './style.scss';

type ReactTableProps = {
	data: { [key: string]: string }[];
	columns: Table.Column<{ [key: string]: string }>[];
	// selectedColumns?: { [id: string]: string };
	selectOptions: { [key: string]: { selected?: boolean, id: string, text: string, required?: boolean } };
// 	setSelectOptions: React.Dispatch<React.SetStateAction<{
//     [key: string]: {
//         selected?: boolean | undefined;
//         id: string;
//         text: string;
//         required?: boolean | undefined;
//         properties?: types.GridItemProperties | undefined;
//     };
// }>>;
	onSpecifyColumn: (e: React.ChangeEvent<HTMLSelectElement>, columnId: string, header?: string) => void;
	defaultImportTypes: {[key: string] : string};
}

const ReactTable = ({data, columns, /* selectedColumns, */ selectOptions/* , setSelectOptions */, onSpecifyColumn, defaultImportTypes}: ReactTableProps): JSX.Element => {
	// const [selectOptions, setSelectOptions] = useState<{ [key: string]: { selected: boolean, id: string } }>({
	// 	// default: {
	// 	// 	text: '-Specify column-',
	// 	// 	selected: true,
	// 	// },
	// 	subPhase: {
	// 		// text: 'Subphase',
	// 		selected: false,
	// 		id: '',
	// 	},
	// 	text:{ 
	// 		// text: 'Item Text',
	// 		selected: false,
	// 		id: '',
	// 	},
	// });


	// useEffect(() => {
	// 	console.log(selectedColumns);
	// 	const newSelectOptions = {...selectOptions};

	// 	// reset
	// 	for (let i in newSelectOptions) {
	// 		newSelectOptions[i].selected = false;
	// 		newSelectOptions[i].id = '';
	// 	}

	// 	for (let i in selectedColumns) {
	// 		newSelectOptions[selectedColumns[i]].selected = true;
	// 		newSelectOptions[selectedColumns[i]].id = i;
	// 	}

	// 	// console.log(newSelectOptions);
	// 	setSelectOptions(newSelectOptions);
	// }, [selectedColumns])


	const defaultColumn = useMemo(
    () => ({
			maxWidth: 150,
			minWidth: 100,
      // width: 150,
    }),
    []
  )
	
	const scrollBarSize = useMemo(() => scrollBarWidth(), [])

	const tableInstance = useTable({ columns, data, defaultColumn }, useBlockLayout);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		totalColumnsWidth,
		prepareRow,
 	} = tableInstance


	 const RenderRow = useCallback(
    ({ index, style }) => {
      const row = rows[index]
      prepareRow(row)
      return (
        <div
          {...row.getRowProps({
            style,
          })}
          className="tr"
        >
          {row.cells.map(cell => {
            return (
              <div {...cell.getCellProps()} className="td" title={cell.value}>
                {cell.render('Cell')}
              </div>
            )
          })}
        </div>
      )
    },
    [prepareRow, rows]
  )

	// const getDefaultSelectValue = (header: string, columnId: string) => {
	// 	const newSelectOptions = {...selectOptions};
	// 	if (newSelectOptions[defaultImportTypes[header]]) {
	// 		newSelectOptions[defaultImportTypes[header]].selected = true;
	// 		newSelectOptions[defaultImportTypes[header]].id = columnId;

	// 		setSelectOptions(newSelectOptions);
	// 	}

	// 	return defaultImportTypes[header] || undefined;
	// }


	return (
		<div {...getTableProps()} className="ReactTable">
      <div>
        {headerGroups.map(headerGroup => (
          <div {...headerGroup.getHeaderGroupProps()} className="tr">
            {headerGroup.headers.map(column => (
              <div {...column.getHeaderProps()} className="th">
                {column.render('Header')}
								{/* {console.log(column.Header?.toString())} */}
								<select /* name="" id="" */ 
									onChange={e => onSpecifyColumn(e, column.id, column.Header?.toString())} 
									defaultValue={
										(column.Header && defaultImportTypes[column.Header.toString()])
											? defaultImportTypes[column.Header.toString()]
											: undefined
									}
								>
									<option value="">-Specify column-</option>
									{/* <option value="" selected></option> */}
									{/* <option value="phase">Phase</option> */}
									{/* {selectOptions.map(option =>
										)} */}
									{Object.keys(selectOptions).map(option => {
										if (selectOptions[option].id === column.id || !selectOptions[option].selected) {
											return <option value={option}>{selectOptions[option].required && '*'}{selectOptions[option].text}</option>
										}

									})}


									{/* {(selectOptions.subPhase.id === column.id || !selectOptions.subPhase.selected) && 
										<option value="subPhase">Subphase</option>
									}
									{(selectOptions.text.id === column.id || !selectOptions.text.selected) &&
										<option value="text">Item Text</option>
									} */}
								</select>
              </div>
            ))}
          </div>
        ))}
      </div>

      <div {...getTableBodyProps()}>
        <FixedSizeList
          height={300}
          itemCount={rows.length}
          itemSize={35}
          width={totalColumnsWidth+scrollBarSize}
        >
          {RenderRow}
        </FixedSizeList>
      </div>
    </div>
	)
}

export default ReactTable;