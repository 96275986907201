import React, { useEffect, useState, useRef } from 'react';
import Select from 'react-select';

import ReactSelect from 'components/VentureMappingTool/ReactSelect';

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

// Redux
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { selectFilters, updateActiveFilter, clearActiveFilters, selectActiveFilters, selectShowFilterPanel } from 'slices/miscSlice';

// import iconXCircle from 'assets/images/icon-x-circle.svg';
import { ReactComponent as IconXCircle } from 'assets/images/icon-x-circle.svg';

import './style.scss';
import * as types from 'types';

// const filters: types.Filter[] = [
// 	{ 
// 		title: 'Opportunity' as const,
// 		// key: 'opportunity',
// 		options: [
// 			{ value: 'Personalization', label: 'Personalization' },
// 			{ value: 'wayfinding', label: 'Wayfinding' },
// 			{ value: 'selfService', label: 'Self-Service' },
// 			{ value: 'gamification', label: 'Gamification' },
// 			{ value: 'engagement', label: 'Engagement' },
// 			{ value: 'dataCapture', label: 'Data Capture' },
// 			{ value: 'opEfficiency', label: 'Op Efficiency' },
// 			{ value: 'newService', label: 'New Service' },
// 			{ value: 'acquisition', label: 'Acquisition' },
// 			{ value: 'loyalty', label: 'Loyalty' },
// 			{ value: 'vip', label: 'VIP' },
// 		]
// 	},
// 	{ 
// 		title: 'Impact' as const,
// 		// key: 'impact',
// 		options: [
// 			{ value: 'chocolate', label: 'Chocolate' },
// 			{ value: 'strawberry', label: 'Strawberry' },
// 			{ value: 'vanilla', label: 'Vanilla' }
// 		]
// 	},
// 	{ 
// 		title: 'Priority' as const,
// 		// key: 'priority',
// 		options: [
// 			{ value: 'chocolate', label: 'Chocolate' },
// 			{ value: 'strawberry', label: 'Strawberry' },
// 			{ value: 'vanilla', label: 'Vanilla' }
// 		]
// 	},
// 	{ 
// 		title: 'Cost' as const,
// 		// key: 'cost',
// 		options: [
// 			{ value: 'chocolate', label: 'Chocolate' },
// 			{ value: 'strawberry', label: 'Strawberry' },
// 			{ value: 'vanilla', label: 'Vanilla' }
// 		]
// 	},
// 	{ 
// 		title: 'Time' as const,
// 		// key: 'time',
// 		options: [
// 			{ value: 'chocolate', label: 'Chocolate' },
// 			{ value: 'strawberry', label: 'Strawberry' },
// 			{ value: 'vanilla', label: 'Vanilla' }
// 		]
// 	},
// 	{ 
// 		title: 'KPI' as const,
// 		// key: 'kpi',
// 		options: [
// 			{ value: 'chocolate', label: 'Chocolate' },
// 			{ value: 'strawberry', label: 'Strawberry' },
// 			{ value: 'vanilla', label: 'Vanilla' }
// 		]
// 	},
	
// ]

export type FilterFormInputs = {
	Opportunity: string;
	Impact: string;
	Priority: string;
	Cost: string;
	Time: string;
	KPI: string;
}


const FilterForm = () => {
	const filters = useAppSelector(selectFilters);
	const activeFilters = useAppSelector(selectActiveFilters);
	const dispatch = useAppDispatch();


	useEffect(() => {
		// clear active filters on close
		// return () => {
		// 	dispatch(clearActiveFilters());
		// }
	}, [])


	/** Array of refs to store each Select */ 
	const selectInputRefs = useRef(Array.from({length: Object.keys(filters).length}, a => React.createRef<Select>()));

	/** Clear all filters */
	const handleClearFilters = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.preventDefault();

		if (selectInputRefs.current) {
			selectInputRefs.current.forEach(ref => {
				ref.current?.select.clearValue();
			})
		}

		dispatch(clearActiveFilters());
	}

	/** On change of any filter, update active filters in state */
	const handleChange = (value: any, filterKey: string) => {
		dispatch(updateActiveFilter({value, filterKey}))
	}



	return (
		<form className="FilterForm">
			<p className="filterBy">Filter by:</p>

			{Object.keys(filters).filter(filter => filters[filter].default)
				.map((filterKey, idx) =>
					<div className="input" key={filterKey}>
						<label htmlFor={`filter-${filterKey}`}>{filterKey}</label>
						<ReactSelect
							//@ts-ignore
							ref={selectInputRefs.current[idx]}
							isClearable={true}
							isMulti={true}
							id={`filter-${filterKey}`}
							// placeholder={filters[filterKey].title}
							className="Select"
							options={filters[filterKey].options}
							onChange={(value: any) => handleChange(value, filterKey)}
							value={activeFilters[filterKey]?.options}
							// styles={selectStyles}
							filter
						/>
					</div>
				)
			}

			<div className="input">
				<label htmlFor={`filter-more`}>More Filters</label>
				<ReactSelect
					//@ts-ignore
					// ref={selectInputRefs.current[idx]}
					// isClearable={true}
					// isMulti={true}
					id={`filter-more`}
					// placeholder={filters[filterKey].title}
					className="Select"
					// options={filters[filterKey].options}
					// onChange={(value) => handleChange(value, filterKey)}
					// value={activeFilters[filterKey]?.options}
					// isDisabled
					filter
				/>
			</div>

			{!Object.keys(filters).length ? <p>No properties to filter by</p> : ''}

			<button type="button" onClick={handleClearFilters} className="btn btn-clear btn-control">
				Clear all filters <FontAwesomeIcon icon={faTimes} />
			</button>
		</form>
	)
}

type FilterPanelProps = {
	onClose: () => void;
}

const FilterPanel = ({ onClose }: FilterPanelProps) => {
	const showFilterPanel = useAppSelector(selectShowFilterPanel);

	const [style, setStyle] = useState<React.CSSProperties>({});
	useEffect(() => {
		if (!showFilterPanel) {
			setStyle({
				height: 0,
				padding: 0,
				border: 'none',
				// overflow: 'hidden',
			})
		} else {
			const height = '125px';
			
			setStyle({
				height: height,
			});

			setTimeout(() => {
				setStyle({
					height: height,
					overflow: 'visible',
				});
			}, 400);
		}
	}, [showFilterPanel])

	return (
		<div className="FilterPanel" style={style}>
			<FilterForm />

			<button onClick={onClose} title="Close" aria-label="Close" className="btn btn-control btn-close">
				{/* <FontAwesomeIcon icon={faTimes} /> */}
				{/* <img src={iconXCircle} alt="Close" /> */}

				{/* <svg xmlns={iconXCircle} fill="black"></svg> */}
				<IconXCircle />
			</button>
		</div>
	)
}

export default FilterPanel;
