import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import store from './app/store'
import { Provider } from 'react-redux'

// // Redux
// import { useAppDispatch } from 'app/hooks'
// import { getSession } from 'slices/userSlice';

// import * as authApi from "api/auth";

(async function initializeStoreAndRenderApp() {
	try {
		// const preLoadedState = await setPreloadedUserState();
		// const store = await initializeStore();
		// const dispatch = useAppDispatch();
		// await dispatch(getSession());

		ReactDOM.render(
			<Provider store={store}>
				<App />
			</Provider>,
			document.getElementById('root')
		);
	} catch (error) {
		console.error(error);
	}
})();


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
