import React, { useState } from 'react';
import { useForm } from "react-hook-form";

import ImageUpload from './ImageUpload';

import building from 'assets/images/building.svg'

import * as organizationsApi from 'api/organizations';

// images
// import { ReactComponent as IconOrganizationDefault } from 'assets/images/icon-organization-default.svg';

import './style.scss';

export type OrganizationFormInputs = {
	name: string,
	description: string,
	image?: string,
}

type OrganizationFormProps = {
	onSubmit: () => void;
}

const OrganizationForm = ({ onSubmit }: OrganizationFormProps) => {
	const [error, setError] = useState('');
	const [logo, setLogo] = useState('');

	const { register, handleSubmit, formState: { errors } } = useForm<OrganizationFormInputs>({
		mode: "onTouched",
	});

	const handleCreateOrganization = async (inputData: OrganizationFormInputs) => {
		const dataToSend = {...inputData}
		if (logo) {
			dataToSend.image = logo;
		}
		
		try {
			const { data }: any = await organizationsApi.createNew(dataToSend);
			console.log(data);

			onSubmit();
		} catch (error) {
			console.error(error);
			if (error.response.data.message) {
				setError(error.response.data.message);
			} else {
				setError('Error creating organization');
			}
		}
	};

	return (
		<form onSubmit={handleSubmit(handleCreateOrganization)} className="form">
			<h2>Create New Organization</h2>

			<div className="inputs">
				<div className="input">
					<label htmlFor="orgName" className="sr-only">Organization Name</label>
					<input
						type="text"
						autoComplete="organization"
						id="orgName"
						placeholder="Organization Name"
						{...register("name", { required: true })}
					/>
					{errors.name && <span className="error">* This field is required</span>}
				</div>
				<div className="input">
					<label htmlFor="orgDescription" className="sr-only">Organization Description</label>
					<input
						type="text"
						id="orgDescription"
						placeholder="Organization Description"
						{...register("description")}
					/>
				</div>
			</div>

			<h3 className="profilePicture">Logo (optional):</h3>
			<ImageUpload
				image={logo}
				setImage={setLogo}
				placeholder='organization'
			/>

			<button type="submit" className="btn btn-form">Create Organization {'>'}</button>

			{error && <span className="error">{error}</span>}
		</form>
	)
}

export default OrganizationForm;
