import React, { useState, useEffect } from 'react';

import InputForm from './InputForm';

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/free-solid-svg-icons'

import * as types from 'types';
import { InputType } from '.';

type PropertyDisplayProps = {
	itemData: types.GridItem;
	inputType: InputType;
	field: string;
	required?: boolean;
	/** If true then this input is for a label instead of value */
	label?: boolean;
	/** If true, will start in edit mode */
	startActive?: boolean;
}

/** Interactive display of one property */
const PropertyDisplay = ({ itemData, inputType, field, required, label, startActive }: PropertyDisplayProps) => {
	const [displayValue, setDisplayValue] = useState('');
	const [tempValue, setTempValue] = useState<string[]>([]);
	const [isEditing, setIsEditing] = useState(startActive || false);

	const value = itemData.properties[field];

	useEffect(() => {
		if (tempValue.length) {
			setDisplayValue(tempValue.join(', '));
		} else if (label) {
			setDisplayValue(itemData.properties[field].title);
		} else if (value.values[0]) {
			setDisplayValue(value.values.join(', '));
		} else {
			setDisplayValue('...');
		}
	}, [value, tempValue])

	// clear temp value when item is changed
	useEffect(() => {
		setTempValue([]);
	}, [itemData])

	return (
		<div className="input">
			{!isEditing ? (
				<>
					<button
						className={`btn-edit`}
						title="Click to edit"
						aria-label="Click to edit"
						onClick={() => setIsEditing(true)}
					>
						{displayValue}
						<FontAwesomeIcon icon={faPen} />
					</button>
				</>
			) : (
				<InputForm
					itemData={itemData}
					field={field}
					defaultValue={startActive ? [''] : tempValue.length ? tempValue : !label ? value.values : [itemData.properties[field].title]}
					inputType={inputType}
					onCancel={() => setIsEditing(false)}
					setTempValue={setTempValue}
					required={required}
					label={label}
				/>
			)}	
		</div>
	)
}

export default PropertyDisplay;