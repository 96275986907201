import React, { useState, useLayoutEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm, SubmitHandler } from "react-hook-form";

// Redux
import { useAppSelector, useAppDispatch } from 'app/hooks'
import { login, selectError, clearErrors } from 'slices/userSlice';

import './style.scss';

export type LoginFormInputs = {
	firstName: string;
	lastName: string;
	email: string;
	password: string;
}

const LoginForm = () => {
	const error = useAppSelector(selectError);
	const dispatch = useAppDispatch();

	useLayoutEffect(() => {
    // cleanup error state in Redux store on unmount
    return () => {
      dispatch(clearErrors());
    };
  }, [dispatch]);

	const { register, handleSubmit, formState: { errors } } = useForm<LoginFormInputs>({
		mode: "onTouched",
	});

	const handleLogin = (data: LoginFormInputs) => {
		dispatch(login(data))
	}

	return (
		<>
			<form onSubmit={handleSubmit(handleLogin)} className="form form-login">
				<h2>Log in</h2>

				<div className="inputs">
					<div className="input">
						<label htmlFor="email" className="sr-only">Email</label>
						<input
							type="email"
							autoComplete="email"
							id="email"
							placeholder="Email"
							{...register("email", { required: true })}
						/>
						{errors.email && <span className="error">* This field is required</span>}
					</div>
					<div className="input">
						<label htmlFor="password" className="sr-only">Password</label>
						<input
							type="password"
							autoComplete="new-password"
							id="password"
							placeholder="Password"
							{...register("password", { required: true })}
						/>
						{errors.password && <span className="error">* This field is required</span>}
					</div>
				</div>

				<button type="submit" className="btn btn-form">log in {'>'}</button>

				{error && <span className="error">{error}</span>}

				<div className="links">
					<p>
						{/* <Link to="/signup" className="Link">Create an account</Link> */}
					</p>
				</div>
			</form>
			<div className="pageBackground"></div>
		</>
	)
}

export default LoginForm;
