import React from 'react';
import Table, { useTable, useFlexLayout, useBlockLayout } from 'react-table';

import './style.scss';

type DotRowTableProps = {
	data: { [key: string]: string }[];
	columns: Table.Column<{ [key: string]: string }>[];
	className?: string;
	id?: string;
}

const DotRowTable = ({ columns, data, className, id }: DotRowTableProps) => {

	const defaultColumn = React.useMemo(
    () => ({
      minWidth: 25,
      width: 50,
      maxWidth: 200,
    }),
    []
  )

	const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
		// defaultColumn,
  },
	// useBlockLayout,
	useFlexLayout,
	)

  /** 
   * Function to replace default cell props with custom values.
   * Currently only used to increase the width of the first (label) column.
   */
  const customCellProps = (cellProps: Table.TableCellProps) => {
    const newCellProps = {...cellProps};

    if (cellProps.key.toString().includes('col0') && newCellProps.style) {
      const newWidth = Number((newCellProps.style?.width as string).replace('px', '')) + 60;

      newCellProps.style.width = `${newWidth}px`;
    }

    return newCellProps;
  };


	return (
		<table {...getTableProps()} className={`DotRowTable ${className}`} id={id}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...customCellProps(column.getHeaderProps())}>
								{/* <span> */}{column.render('Header')}{/* </span> */}
							</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
				<tr></tr>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return <td {...customCellProps(cell.getCellProps())}>
                  {cell.render('Cell')}</td>
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
	)
}

export default DotRowTable;
