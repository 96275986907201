import React, { useState, useEffect } from 'react';
import ImageUploading, { ImageListType } from "react-images-uploading";
import AvatarEditor from './AvatarEditor';
import Jimp from 'jimp';

// import anonymousUser from 'assets/images/anonymousUser.png';

// images
import { ReactComponent as IconUserDefault } from 'assets/images/icon-user-default.svg';
import { ReactComponent as IconOrganizationDefault } from 'assets/images/icon-organization-default.svg';

type ImageUploadProps = {
	image: string;
	setImage: React.Dispatch<React.SetStateAction<string>>;
	placeholder: string;
	round?: boolean;
}

const ImageUpload = ({image, setImage, placeholder, round}: ImageUploadProps) => {
	// const [images, setImages] = React.useState([]);
  // const maxNumber = 1;
	const [images, setImages] = useState<ImageListType>([]);

	const [isEditing, setIsEditing] = useState(false);
	// const [finalImage, setFinalImage] = useState('');

	/** The image after editing */
	const [editedImage, setEditedImage] = useState('');

	// const [resizedImage, setResizedImage] = useState('');

  const onChange = (
    imageList: ImageListType,
    addUpdateIndex: number[] | undefined
  ) => {

    setImages(imageList);

		// console.log(imageList);
		if (imageList.length) {
			setIsEditing(true);
		}
  };

	useEffect(() => {
		(async () => {
			if (editedImage) {
				setIsEditing(false);

				const result = await Jimp.read(editedImage)
					.then(img => {
						return img
							.resize(150, Jimp.AUTO)
							.getBase64Async(Jimp.MIME_PNG)
							// .write(`thumbnail.${img.getExtension()}`);
					})
					.catch(error =>
						console.error(error)
					);
				// console.log(result);
				if (result) {
					setImage(result);
				}
			}
		})();
	}, [editedImage])


	return (
		<div className="ImageUpload">
			<ImageUploading
        // multiple
        value={images}
        onChange={onChange}
				// maxFileSize={1000000} // 1MB max size
        // maxNumber={maxNumber}
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
					errors
        }) => (
          // write your building UI
          <div className="upload__image-wrapper">
						
					{(!image && !isEditing) ?
						<>
							<button
								type="button"
								className="btn-drag"
								style={isDragging ? { color: "red" } : undefined}
								onClick={onImageUpload}
								{...dragProps}
							>
								{/* <img src={placeholder} alt="Default Image" className="default-avatar" />  */}
								{placeholder === 'organization' ?
									<IconOrganizationDefault className="IconDefault IconDefault-organization" />
								:
									<IconUserDefault className="IconDefault" />
								}
							</button>
							<span className="instruction" onClick={onImageUpload} {...dragProps}>Click or drop here to upload image.</span>
						</>
					: ''
					}
            {/* {images.length ? <button type="button" onClick={onImageRemoveAll} className="btn btn-control">Remove all images</button> : ''} */}
            {/* {!editedImage ? imageList.map((image, index) => ( */}

						{(isEditing && imageList.length) ?
							<AvatarEditor 
								image={imageList[0].dataURL!} 
								setEditedImage={setEditedImage} 
								round={round}
								onCancel={() => {
									// onImageRemove(0);
									setIsEditing(false);
								}} 
								changePicture={() => onImageUpdate(0)}
							/>
							: ''
						}

						{(editedImage && !isEditing) ?
							<div key={0} className="image-item">
								<button 
									type="button"
									onClick={() => setIsEditing(!isEditing)}
									className="image-button"
									title="Edit Image"
								>
									<img
										src={image}
										alt="Uploaded Image"
										width="150"
										className="image-preview"
										style={{
											borderRadius: round ? '50%' : '0.5rem',
										}}
									/>
								</button>
								<div className="image-item__btn-wrapper">
									<button 
										type="button" 
										onClick={() => {
											onImageUpdate(0);
											// setEditedImage('');
											// setResizedImage('');
										}} 
										className="btn btn-form btn-form--white"
									>
										change picture
									</button>
									<button 
										type="button" 
										onClick={() => {
											onImageRemove(0);
											setEditedImage('');
											setImage('');
										}} 
										className="btn btn-form btn-form--white">
										remove picture
									</button>
								</div>
							</div>
						: ''
						}

						{/* {errors?.maxFileSize && <span className="error">File is too large, max size is 1MB</span> } */}
          </div>
        )}
      </ImageUploading>
		</div>
	)
}

export default ImageUpload;
