import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import Modal from '../VentureMappingTool/Modal';

import OrganizationForm from 'components/forms/OrganizationForm';
import ProjectForm from 'components/forms/ProjectForm';

// Redux
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { selectUser, getSession } from 'slices/userSlice';

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faBuilding, faCrown } from '@fortawesome/free-solid-svg-icons';

import * as organizationsApi from 'api/organizations';
import * as userApi from 'api/user';
import * as imageApi from 'api/image';

// import building from 'assets/images/building.svg';
import { ReactComponent as IconBuilding } from 'assets/images/icon-organization-default.svg';
import { ReactComponent as IconUserDefault } from 'assets/images/icon-user-default.svg';
import { ReactComponent as IconPlus } from 'assets/images/icon-plus.svg';


import * as types from 'types';
import './style.scss';
import { clearProject } from 'slices/projectSlice';


const HomePage = () => {
	const [userOrganizations, setUserOrganizations] = useState<types.Organization[]>([]);

	const [showOrganizationForm, setShowOrganizationForm] = useState(false);
	const [showProjectForm, setShowProjectForm] = useState(false);
	/** The organization to create a new project within */
	const [selectedOrg, setSelectedOrg] = useState<types.Organization>();

	// /** Whether currently editing avatar */
	// const [isEditingAvatar, setIsEditingAvatar] = useState(false);
	// /** The edited avatar */
	// const [newAvatar, setNewAvatar] = useState();

	const user = useAppSelector(selectUser);
	const dispatch = useAppDispatch();
	
	/** Clear project in state when navigating to this page */
	useEffect(() => {
		dispatch(clearProject());
	}, [])

	// fetch user organizations by ID
	useEffect(() => {
		console.log('fetch organizations');
		fetchOrganizations();
	}, [user.data.organizationIds])


	const fetchOrganizations = async () => {
		console.log('fetchOrganizations');
		try {
			const newOrganizations: types.Organization[] = [];

			/* const userData =  */await userApi.fetchUserData();

			await Promise.all(user.data.organizationIds.map(async (id) => {
				const { data }: any = await organizationsApi.fetchbyId(id);
					if (data) {
						// fetch organization logo
						const logo = data.logoId ? await imageApi.fetchImage(data.logoId) : undefined;
						if (logo) data.logo = logo.data.data;
						else data.logo = '';

						newOrganizations.push(data)
					};
			}));

			newOrganizations.sort((a, b) => Date.parse(a.createdAt!) - Date.parse(b.createdAt!))

			setUserOrganizations(newOrganizations);
		} catch (error) {
			console.error(error);
		}
	}

	/** Create a new organization */
	const handleCreateOrganization = () => {
		setShowOrganizationForm(true);
	}

	const handleOrganizationFormSubmit = () => {
		setShowOrganizationForm(false);

		dispatch(getSession());
		// fetchOrganizations();
	}

	const handleCreateProject = (organization: types.Organization) => {
		setSelectedOrg(organization);
		setShowProjectForm(true);
	}

	const handleProjectFormSubmit = () => {
		setShowProjectForm(false);
		dispatch(getSession());
	}

	return (
		<>
			<div className="HomePage">
					<h2 className="userName">
						Welcome
						{user.avatar ?
							<img className="userAvatar" src={user.avatar} alt="User Avatar" />
						:
							<IconUserDefault className="userAvatar" />
						}
							{user.data.firstName}!
					</h2>
					<div className="organizationHeader">
						<h3>Your Organizations:</h3>
						<button onClick={handleCreateOrganization} className="btn btn-form btn-form--white">{/* <FontAwesomeIcon icon={faPlus} /> */}Create New Organization <IconPlus /></button>
					</div>
					
					<ul className="organizationList">
						{userOrganizations.length ? userOrganizations.map(organization =>
							<li key={organization.name}>
								<div className="organizationName">
									<h4>
										{organization.logo ?
											<img src={organization.logo} alt="Logo" className="organizationLogo" />
										:
											<IconBuilding className="IconBuilding" />
										}
										{organization.name}
									</h4>
									{/* <span className="options">
										{user.data._id === organization.ownerId &&
											<FontAwesomeIcon icon={faCrown} title="You are the owner of this organization" />
										}
									</span> */}
								</div>
								<p>{organization.description}</p>
								{/* <p>{organization.description}</p> */}
								<ul className="projectList">
									{organization.projects.map((project) =>
										<li key={project._id}>
											<Link to={`/project/${organization.uri}/${project.uri}`}>
												{<>
													<h5 title={project.name}>{project.name}</h5>
													<p title={project.description}>{project.description}</p>
												</>}
											</Link>
										</li>
									)}
									<li>
										<button onClick={() => handleCreateProject(organization)} className="btn btn-form btn-form--white">{/* <FontAwesomeIcon icon={faPlus} /> */}Create New Project <IconPlus /></button>
									</li>
								</ul>
							</li>
						)
						: <li>No organizations found!</li>
						}
					</ul>
					<Modal
						closeModal={() => setShowOrganizationForm(false)}
						modalIsOpen={showOrganizationForm}
						style={{backgroundColor: '#e0dedc'}}
					>
						<OrganizationForm onSubmit={handleOrganizationFormSubmit} />
					</Modal>
					{selectedOrg &&
						<Modal
							closeModal={() => setShowProjectForm(false)}
							modalIsOpen={showProjectForm}
							style={{backgroundColor: '#e0dedc'}}
						>
							<ProjectForm organization={selectedOrg} onSubmit={handleProjectFormSubmit} />
						</Modal>
					}

			</div>
			<div className="pageBackground"></div>
		</>
	)
}

export default HomePage;
