import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import CreatableSelect from 'react-select/creatable';
// import get from 'lodash/get';

import ReactSelect from 'components/VentureMappingTool/ReactSelect';

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinusCircle } from '@fortawesome/free-solid-svg-icons'

// Redux
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { selectFilters } from 'slices/miscSlice';

import * as types from 'types';
import { InputType } from '.';
import { editItem } from 'slices/projectSlice';



export type InputFormInputs = {
	[key: string]: string;
	// title?: string,
	// description?: string,
	// properties?: types.GridItemProperties;
}

type InputFormProps = {
	itemData: types.GridItem;
	field?: string;
	defaultValue: string[];
	inputType: InputType;
	onCancel: () => void;
	/** Set the temporary value of this field so input doesn't wait for db to update */
	setTempValue?: React.Dispatch<React.SetStateAction<string[]>>;
	required?: boolean;
	/** If true then this input is for a label instead of value */
	label?: boolean;
	propertyNames?: {[key: string]: boolean};
}

const InputForm = ({ itemData, field, defaultValue, inputType, onCancel, setTempValue, required, label, propertyNames }: InputFormProps) => {

	const [error, setError] = useState('');
	// clear error after timeout
	useEffect(() => {
		if (error) {
			setTimeout(() => {
				setError('');
			}, 2000)
		}
	}, [error])

	const dispatch = useAppDispatch();

	const { register, handleSubmit, formState: { errors }, getValues, watch } = useForm<InputFormInputs>({
		mode: "onTouched",
	});

	const watchInput = watch('item-input'); // when pass nothing as argument, you are watching everything

	// useEffect(() => {
	// 	console.log(watchAllFields);
	// 	// console.log(value);
	// }, [watchAllFields])

	// const [propertyTypes, setPropertyTypes] = useState<types.FilterOption[]>([]);

	/** Object containing all properties used for filtering */
	const filters = useAppSelector(selectFilters);
	// The options for selects
	const [options, setOptions] = useState<types.FilterOption[]>();

	// set options from filter state
	useEffect(() => {
		// only if a field is provided
		if (field) {
			const fieldTitle: string = itemData.properties[field].title;

			if (fieldTitle && filters[fieldTitle]) {
				const fieldOptions = filters[fieldTitle].options;
				
				if (fieldOptions) {
					setOptions(fieldOptions)
				}
			}
		}
	}, [filters])

	const [selectedOptions, setSelectedOptions] = useState<types.FilterOption[]>([]);

	let isDeleting = false;

	// useEffect(() => {
	// 	console.log(selectedOptions);
	// }, [selectedOptions])

	// // populate propertyTypes array
	// useEffect(() => {
	// 	const newPropertyTypes: types.FilterOption[] = [];
	// 	for (let i in filters) {
	// 		newPropertyTypes.push({ value: i, label: i });
	// 	}
	// 	setPropertyTypes(newPropertyTypes);
	// }, [filters])

	// populate selectedOptions array
	useEffect(() => {
		// let options: types.FilterOption[] = [];
		
		// if (inputType === 'select' && defaultValue) {
		// 	options = [{ value: defaultValue, label: defaultValue }]
		// } else if (property) {
		// 	options = property.values.map(value => {
		// 		return {
		// 			value: value,
		// 			label: value,
		// 		}
		// 	});
		// }
		if (defaultValue instanceof Array) {
			setSelectedOptions(defaultValue.map(value => {
				return { value: value, label: value };
			}));
		}
	}, [defaultValue])


	const handleSelectChange = (newValue: any, actionMeta: any) => {
		// console.group('Value Changed');
    // console.log(newValue);
    // console.log(`action: ${actionMeta.action}`);
    // console.groupEnd();

		setSelectedOptions(newValue);
	}


	/** Submit form on blur */
	const handleBlur = () => {
		// const value = getValues('item-input');
		console.log(watchInput);

		const value = (inputType === 'input' || inputType === 'textarea')
		? [watchInput]
		: selectedOptions.map(option => option.value);

		// console.log(value);
		// console.log(value[0]);

		// if there is no value in a required field, cancel input
		if ((required && !value[0])) {
			return onCancel();
		}

		console.log(required);
		// submit and cancel on a timer to give time for interacting with buttons (eg. delete)
		setTimeout(() => {
			handleSubmit(submit)();
		}, 100);
	}



	const submit = (data: InputFormInputs) => {
		// only submit if not deleting
		if (!isDeleting) {
			/** The value to send */
			const value = (inputType === 'input' || inputType === 'textarea')
				? [watchInput]
				: selectedOptions.map(option => option.value);
	
			setTempValue && setTempValue(value);
	
			console.log(label, propertyNames, value);

			// if (label) {
			dispatch(editItem({
				_id: itemData._id,
				page: itemData.page,
				row: itemData.row,
				subPhase: itemData.subPhase,
				field: field ? field : value[0], // if field isn't provided, then create new field using input value
				value: field ? value : [], // if field isn't provided, then value is empty
				label: label,
			}))

			onCancel();
			// }
		}
	}


	const handleDelete = () => {
		console.log('delete');
		isDeleting = true;

		if (field) {
			dispatch(editItem({
				_id: itemData._id,
				page: itemData.page,
				row: itemData.row,
				subPhase: itemData.subPhase,
				field: field,
				value: 'delete',
				// label: label,
			}))
	
			onCancel();
		} else {
			console.error('No field provided');
		}
	}

	return (
		<form /* ref={ref} */ onSubmit={handleSubmit((data) => submit(data))} className="InputForm">
			<label htmlFor="item-input" className="sr-only">Edit</label>
				{(inputType === 'select' || inputType === 'multiSelect') ?
					<ReactSelect
						creatable
						isClearable
						isMulti={inputType === 'multiSelect'}
						id="item-input"
						// placeholder={filters[filterKey].title}
						className="Select"
						options={options}
						onChange={handleSelectChange}
						value={selectedOptions}
						onBlur={handleBlur}
						autoFocus
					/>
				: inputType === 'input' ? 
					<input
						type="text"
						id="item-input"
						defaultValue={defaultValue}
						placeholder='Enter a title...'
						autoFocus
						{...register('item-input', { required })}
						onBlur={handleBlur}
					/>
				:
					<textarea
						id="item-input"
						defaultValue={defaultValue}
						placeholder='Add a description...'
						autoFocus
						{...register('item-input', { required })}
						onBlur={handleBlur}
					/>
				}
			{/* <button type="submit" className="btn btn-control btn-save" title="Save" aria-label="Save"><FontAwesomeIcon icon={faCheckCircle} /></button> */}
			{/* <button onClick={onCancel} type="button" className="btn btn-control btn-save" title="Cancel" aria-label="Cancel"><FontAwesomeIcon icon={faTimesCircle} /></button> */}
			{label &&
				<button onClick={handleDelete} type="button" className="btn btn-control btn-save" title="Delete Property" aria-label="Delete Property"><FontAwesomeIcon icon={faMinusCircle} /></button>
			}

			{errors['item-input'] && <div className="error">* This field is required</div>}

			{error && <span className="error">{error}</span>}
		</form>
	)
}

export default InputForm;