import React, { useState, useEffect } from 'react';
import ReactModal from 'react-modal';

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

// images
import { ReactComponent as IconXCircle } from 'assets/images/icon-x-circle.svg';

import './style.scss';

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
		// minWidth: '50%',
  }
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
ReactModal.setAppElement('#root')

type ModalProps = {
	children: React.ReactNode;
	openModal?: () => void;
	closeModal: () => void;
	modalIsOpen: boolean;
	style?: React.CSSProperties;
	className?: string;
	controls?: React.ReactNode;
}

const Modal = ({ children, openModal, closeModal, modalIsOpen, style, className, controls }: ModalProps) => {
	const [useStyle, setUseStyle] = useState<ReactModal.Styles>(customStyles);
	
	useEffect(() => {
		if (style) {
			setUseStyle({content: {...customStyles.content, ...style }})
		}
	}, [style])


	return (
		<ReactModal
			// className="test"
			isOpen={modalIsOpen}
			onRequestClose={closeModal}
			style={useStyle}
			contentLabel="Modal"
			portalClassName={className}
		>
			<div className="Modal-content">
				<div className="controls">
					{controls}
					
					<button 
						className="btn btn-control btn-close"
						title="Close"
						aria-label="Close"
						onClick={closeModal}
					>
						<IconXCircle />
					</button>
				</div>

				{children}

			</div>
		</ReactModal>
	)
}

export default Modal;
