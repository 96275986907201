import React from 'react';
import { Route, Redirect } from 'react-router-dom';

// Redux
import { useAppSelector } from 'app/hooks'
import { selectLoggedIn } from 'slices/userSlice';

/** Route accessible only if logged in */
const PrivateRoute = ({component: Component, ...rest}) => {
	const isLoggedIn = useAppSelector(selectLoggedIn);

	return (
		// Show the component only when the user is logged in
		// Otherwise, redirect the user to /login page
		<Route {...rest} render={props => (
			isLoggedIn ?
				<Component {...props} />
			: <Redirect to="/login" />
		)} />
	);
};


export default PrivateRoute;