import React, { useState, useRef } from 'react';
import { useOutsideCallback } from 'app/hooks';

type DropdownMenuProps = {
	children: React.ReactNode;
	heading: React.ReactNode;
}

const DropdownMenu = ({ children, heading }: DropdownMenuProps) => {
	const ref = useRef<HTMLDivElement>(null);
	useOutsideCallback(ref, () => setDropdownActive(false));

	const [dropdownActive, setDropdownActive] = useState(false);

	return (
		<div className="dropdown" ref={ref}>
			<button onClick={() => setDropdownActive(!dropdownActive)} className="btn btn-control dropdown-button btn-menu">
				{heading}
			</button>
			<ul
				className="dropdown-list"
				style={{
					display: !dropdownActive ? 'none' : 'unset',
				}}
			>
				{children}
			</ul>
		</div>
	)
}

export default DropdownMenu