import axios from "axios";
import { SignupFormInputs } from "components/forms/SignupForm";

import * as types from 'types';

const url = "/auth";

export const login = (credentials: types.StringObject) => axios.post(`${url}/login`, credentials);

export const signup = async (newUser: SignupFormInputs) => axios.post(`${url}/signup`, newUser);

export const getUserSession = () => axios.get(`${url}/session`);

export const logout = () => axios.delete(`${url}/session`);
