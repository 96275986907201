import React from 'react';
import { Route, Redirect } from 'react-router-dom';

// Redux
import { useAppSelector } from 'app/hooks'
import { selectLoggedIn } from 'slices/userSlice';

/** Route available to everyone (if restricted, it will only be available when not logged in) */
const PublicRoute = ({component: Component, restricted = false, ...rest}) => {
	const isLoggedIn = useAppSelector(selectLoggedIn);

	return (
		// restricted = false meaning public route
		// restricted = true meaning restricted route
		<Route {...rest} render={props => (
			isLoggedIn && restricted ?
				<Redirect to="/" />
			: <Component {...props} />
		)} />
	);
};


export default PublicRoute;