import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import { useWindowDimensions } from 'app/hooks';

// import { SizeMe, withSize } from 'react-sizeme';
// import ZoomPanPinch, { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
// import { PropsList as TransformWrapperProps } from 'react-zoom-pan-pinch/dist/store/interfaces/propsInterface';
import { ErrorBoundary } from 'react-error-boundary';

// rows import
import GridRowContainer from './GridRow/GridRowContainer';

import DotRow from './DotRow';

// overlay import
import ScenarioMapOverlay from './Overlay/ScenarioMapOverlay';

import Menu from './Menu';

// Redux
import { useAppSelector, useAppDispatch } from 'app/hooks';
// import { selectLoggedIn } from 'slices/userSlice';
import {
  fetchProject,
  resetProjectToDefault,
  selectPages,
  selectColumns,
  selectTotalColumns,
  selectRowLoading,
  selectEditMode,
  selectProject,
} from 'slices/projectSlice';
import {
  selectSize,
  setScale,
  setSize,
  selectRowHeight,
} from 'slices/dimensionSlice';
import {
  setCurrentPage,
  selectCurrentPage,
  initializeFilters,
  selectShowFilterPanel,
} from 'slices/miscSlice';

import * as types from '../../types';

// react-grid-layout styles
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

import './style.scss';

type ErrorFallbackProps = {
  error: Error;
  resetErrorBoundary: () => void;
};

/** What to display when an error boundary is triggered */
function ErrorFallback({ error, resetErrorBoundary }: ErrorFallbackProps) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <p>
        Try{' '}
        <button className="Link" onClick={() => window.location.reload()}>
          refreshing the page
        </button>
        .
      </p>
      <p>
        You may want to{' '}
        <button className="Link" onClick={resetErrorBoundary}>
          reset this app
        </button>{' '}
        back to its default state. Warning - this is irreversible!
      </p>
    </div>
  );
}

type TabProps = {
  activeTab: string;
  children: React.ReactNode;
  handleClick: React.MouseEventHandler<HTMLButtonElement>;
};

/** A tab along the top of the tool */
const Tab = ({ activeTab, children, handleClick }: TabProps): JSX.Element => {
  return (
    <li>
      <button
        onClick={handleClick}
        className={activeTab === children ? 'active' : undefined}
      >
        {children}
      </button>
    </li>
  );
};

/* type VentureMappingToolProps = {
	size: types.sizeme;
}; */

/** The main app itself */
const VentureMappingTool =
  (/* { size }: VentureMappingToolProps */): JSX.Element => {
    // disable vertical overflow and set background color when in the tool
    useEffect(() => {
      const body = document.body;
      // const main = document.querySelector('main');
      body.style.overflowY = 'hidden';
      // if (main) {
      // 	main.style.backgroundColor = '#fbf9f7';
      // }

      // restore vertical overflow & background color when the user leaves the tool
      return () => {
        body.style.overflowY = '';
        // if (main) {
        // 	main.style.backgroundColor = 'unset';
        // }
      };
    }, []);

    const history = useHistory();
    const { org, project } = useParams<{ org: string; project: string }>();

    const { height, width } = useWindowDimensions();

    // const isLoggedIn = useAppSelector(selectLoggedIn);

    const columns = useAppSelector(selectColumns);
    const phases = columns.phases.columns;
    const subPhases = columns.subPhases.columns;

    const totalColumns = useAppSelector(selectTotalColumns);

    // const allRows = useAppSelector(selectRows);
    const projectData = useAppSelector(selectProject);
    const pages = useAppSelector(selectPages);
    const rowLoading = useAppSelector(selectRowLoading);

    const showFilterPanel = useAppSelector(selectShowFilterPanel);

    // const editMode = useAppSelector(selectEditMode);

    // const [editScale, setEditScale] = useState<number | undefined>(undefined);
    // const [position, setPosition] = useState<{positionX: number, positionY: number} | undefined>(undefined);

    // const [rowsHeight, setRowsHeight] = useState(0);

    const dispatch = useAppDispatch();

    const currentPage = useAppSelector(selectCurrentPage);

    /** Array of the rows to display */
    const [rowsToDisplay, setRowsToDisplay] = useState<types.GridRow[]>([]);
    useEffect(() => {
      const rows = pages
        .find((page) => page.name === currentPage)
        ?.rows.map((row) => row);
      setRowsToDisplay(rows || []);

      // console.log({rows, currentPage});
    }, [pages, currentPage]);

    const size = useAppSelector(selectSize);
    const rowHeight = useAppSelector(selectRowHeight);

    const phaseColors = ['#B7C0C3', '#A2BBC3', '#9ABEC9'];

    const subPhaseColors = [
      '#d7dfde',
      '#cdd9d8',
      '#c5d8d8',
      '#c0d9d9',
      '#b7d8d7',
      '#aed9d8',
      '#a4d9d9',
    ];

    // update size when window is resized
    useEffect(() => {
      // console.log(height, width);
      dispatch(setSize({ width, height }));
    }, [width /* , height */]);

    const [zoomWrapperStyle, setZoomWrapperStyle] =
      useState<React.CSSProperties>({});

    // set tool height on screen height change
    useEffect(() => {
      setTimeout(() => {
        setToolHeight();
      }, 201);
    }, [height, showFilterPanel, currentPage]);

    /** Set tool height based on screen dimensions */
    const setToolHeight = () => {
      const menu = document.querySelector('.Menu');
      const menuRect = menu?.getBoundingClientRect();
      console.log(menuRect);
      // const footer = document.querySelector('footer');
      // const footerRect = footer?.getBoundingClientRect();
      // console.log(menuRect);

      if (menuRect) {
        const newStyle = {
          maxHeight: height - Math.ceil(menuRect.bottom),
        };

        setZoomWrapperStyle(newStyle);
      }
    };

    /** Resets the app to default */
    const resetAppToDefault = async (urlParams: types.ProjectUrlParams) => {
      dispatch(resetProjectToDefault(urlParams));
    };

    //* ===== RESET ALL ROWS TO DEFAULT - FOR DEV USE ONLY ===== *//
    // useEffect(() => {
    // 	// fetch project based on url params
    // 	// dispatch(fetchProject({org, project}));

    // 	// reset app
    // 	resetAppToDefault({org, project});
    // }, [])
    //* ===== RESET ALL ROWS TO DEFAULT - FOR DEV USE ONLY ===== *//

    useEffect(() => {
      // get height of tabs (for calculating background size)
      // const tabs = document.querySelector('ul.tabs');
      // tabs && setTabHeight(tabs.clientHeight);

      (async () => {
        // fetch project based on url params
        const { payload } = await dispatch(fetchProject({ org, project }));
        if (payload === 404) {
          history.push('/404');
        }
      })();
    }, []);

    // /** Get height of rows using ref */
    // const rowsRef = useRef<HTMLDivElement>(null);
    // useEffect(() => {
    // 	if (rowsRef.current) {
    // 		setRowsHeight(rowsRef.current.getBoundingClientRect().height);
    // 	}
    // }, [rowsRef.current?.clientHeight])

    // useEffect(() => {
    // 	// zoom out when entering edit mode
    // 	if (editMode) {
    // 		setEditScale(1);
    // 		setPosition({positionX: 0, positionY: 0})
    // 	}
    // 	else {
    // 		setEditScale(undefined);
    // 		setPosition(undefined);
    // 	}

    // }, [editMode])

    // initialize filters once project data is loaded
    useEffect(() => {
      if (projectData.pages) {
        const items = projectData.pages
          .find((page) => page.name === 'Experience Landscape')
          ?.rows.find((row) => row.title === 'Actions')?.items;
        if (items) {
          dispatch(
            initializeFilters(
              projectData.pages
                .find((page) => page.name === 'Experience Landscape')
                ?.rows.find((row) => row.title === 'Actions')?.items!
            )
          );
        }
      }
    }, [projectData.pages]);

    const handleTabClick = (
      event: React.MouseEvent<HTMLButtonElement>
    ): void => {
      const target = event.target as HTMLButtonElement;
      const newTab = target.innerHTML;
      // setActiveTab(newTab);
      dispatch(setCurrentPage(newTab));
    };

    // const handleResetButtonClick = () => {
    // 	console.log('reset button click');
    // 	dispatch(resetChallengesRow())
    // }

    // /** the count for total width of columns - used when mapping through columns to generate backgrounds */
    // let widthTotal = 0;

    // const handleZoomChange = ({ state }: ZoomPanPinch.ReactZoomPanPinchRef) => {
    // 	// console.log(data);
    // 	dispatch(setScale(state.scale));
    // }

    // const zoomRef = useRef<ZoomPanPinch.ReactZoomPanPinchRef>(null);

    // useLayoutEffect(() => {
    // 	console.log(zoomRef.current);
    // }, [zoomRef.current])

    // const handleRowScroll = (e: React.UIEvent<HTMLDivElement>) => {
    // 	console.log(e);
    // }

    return (
      <div className="VentureMappingTool" id="VentureMappingTool">
        <div className="background-main"></div>

        <ul className="tabs">
          <Tab activeTab={currentPage} handleClick={handleTabClick}>
            Dashboard
          </Tab>
          <Tab activeTab={currentPage} handleClick={handleTabClick}>
            Experience Landscape
          </Tab>
          <Tab activeTab={currentPage} handleClick={handleTabClick}>
            Opportunity Compass
          </Tab>
          <Tab activeTab={currentPage} handleClick={handleTabClick}>
            Scenario Mapping
          </Tab>
          <Tab activeTab={currentPage} handleClick={handleTabClick}>
            Innovation Management
          </Tab>
        </ul>

        <Menu />

        <ErrorBoundary
          FallbackComponent={ErrorFallback}
          onReset={() => {
            resetAppToDefault({ org, project });
          }}
        >
          {/* <div className="zoom-container" style={zoomContainerStyle}> */}
          {/* <TransformWrapper
							onZoom={handleZoomChange}
							panning={editMode ? {
								excluded: ['modalButton', 'react-resizable-handle', 'noZoom'],
							} : undefined}
							maxScale={editMode ? 1 : 2}
							// minScale={0.75}
							initialScale={editScale}
							initialPositionX={position?.positionX}
							initialPositionY={position?.positionY}
							velocityAnimation={{}}
							// ref={zoomRef}
							// maxPositionY={0}
							// minPositionY={0}
						> */}
          {/* <TransformComponent
								wrapperStyle={zoomWrapperStyle}
							> */}

          <div
            className="rows-container"
            style={zoomWrapperStyle} /*  onScroll={handleRowScroll} */
          >
            <div className="rows" /*  ref={rowsRef} */>
              {/* BACKGROUNDS */}
              {/* Phases */}
              {phases.map((column, index) => {
                // const borderWidth = (index === 2 || index === 5) ? '2px' : '1px';
                return (
                  <div
                    key={column.key}
                    className={`col-bg`}
                    style={{
                      width: `${
                        (size.width * 0.8 * column.w) / totalColumns +
                        (0.4 * 7) / 3
                      }px`,
                      height: 2 * 25,
                      top: `0`,
                      left: `${
                        size.width * 0.1 +
                        (size.width * 0.8 * column.x) / totalColumns +
                        (0.4 * index * 7) / 3
                        + 20
                      }px`,
                      backgroundColor: phaseColors[index],
                      // borderLeft: index > 0 ? `2px solid #A7A7A7` : undefined,
                      borderRight:
                        index < phases.length - 1
                          ? `2px solid #A7A7A7`
                          : undefined,
                    }}
                  ></div>
                );
              })}
              {/* Sub-phases */}
              {subPhases.map((column, index) => {
                const borderWidth = index === 1 || index === 4 ? '2px' : '1px';

                return (
                  <div
                    key={column.key}
                    className={`col-bg col-bg-${index}`}
                    style={{
                      width: `${
                        (size.width * 0.8 * column.w) / totalColumns + 0.4
                      }px`,
                      height: `calc(100% - ${2 * 25}px)`,
                      top: `${2 * 25}px`,
                      left: `${
                        size.width * 0.1 +
                        (size.width * 0.8 * column.x) / totalColumns +
                        0.4 * index
                        + 20
                      }px`,
                      // backgroundColor: `hsl(${160 + (12 * index)}, 45%, 85%)`,
                      backgroundColor: subPhaseColors[index],
                      // borderLeft: index > 0 ? `${borderWidth} solid #A7A7A7` : undefined,
                      borderRight:
                        index < subPhases.length - 1
                          ? `${borderWidth} solid #A7A7A7`
                          : undefined,
                    }}
                  ></div>
                );
              })}

              {/* ROWS */}
              {rowLoading ? (
                'loading...'
              ) : (
                <>
                  {size && size.width && (
                    <>
                      {/* Header row */}
                      {projectData.headerRow && (
                        <GridRowContainer
                          rowData={projectData.headerRow}
                          key={projectData.headerRow._id}
                        />
                      )}
                      {/* Other rows */}
                      {rowsToDisplay.map((row) => {
                        // console.log(row);
                        return <GridRowContainer rowData={row} key={row._id} />;
                      })}
                      {/* {activeTab === 'Scenario Map' &&
											<ScenarioMapOverlay size={size} />
										} */}
                      {/* Opportunity Compass */}
                      {currentPage === 'Opportunity Compass' && (
                        <>
                          <DotRow
                            type={{
                              title: 'Locations',
                              key: 'activityType',
                              label: 'Action Type',
                            }}
                          />
                          <DotRow
                            type={{
                              title: 'Touchpoints',
                              key: 'touchpoints',
                              label: 'Digital Touchpoint(s)',
                            }}
                          />
                          <DotRow
                            type={{
                              title: 'Pain Points',
                              key: 'challenges',
                              label: 'Challenge / Pain Point',
                            }}
                          />
                          <DotRow
                            type={{
                              title: 'Team',
                              key: 'people',
                              label: 'Team',
                            }}
                          />
                          <DotRow
                            type={{
                              title: 'Data Sources',
                              key: 'dataSource',
                              label: 'Data Source',
                            }}
                          />
                          <DotRow
                            type={{
                              title: 'Revenue Type',
                              key: 'revenueStream',
                              label: 'Revenue Stream',
                            }}
                          />
                          <DotRow
                            type={{
                              title: 'Opportunities',
                              key: 'opportunities',
                              label: 'Opportunity(ies)',
                            }}
                          />
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
          {/* </TransformComponent> */}
          {/* </TransformWrapper> */}
          {/* </div> */}
        </ErrorBoundary>
        {/* </>
			} */}
      </div>
    );
  };

export default VentureMappingTool;
