import React from 'react';
import { Link } from 'react-router-dom';

const NotFoundPage = () => {
	return (
		<div>
			<h2>404 - Page Not Found</h2>
			<p>Return <Link to="/">home</Link></p>
		</div>
	)
}

export default NotFoundPage;