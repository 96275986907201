import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import Modal from '../VentureMappingTool/Modal';

import OrganizationForm from 'components/forms/OrganizationForm';
import ProjectForm from 'components/forms/ProjectForm';

// Redux
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { selectUser, getSession } from 'slices/userSlice';

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import * as organizationsApi from 'api/organizations';
import * as userApi from 'api/user';

import * as types from 'types';
import './style.scss';


const AccountPage = () => {
	const [userOrganizations, setUserOrganizations] = useState<types.Organization[]>([]);

	const [showOrganizationForm, setShowOrganizationForm] = useState(false);
	const [showProjectForm, setShowProjectForm] = useState(false);
	/** The organization to create a new project within */
	const [selectedOrg, setSelectedOrg] = useState<types.Organization>();

	const user = useAppSelector(selectUser);
	const dispatch = useAppDispatch();
	
	// fetch user organizations by ID
	useEffect(() => {
		fetchOrganizations();
	}, [user.data.organizationIds])

	// // fetch user organizations by ID
	// useEffect(() => {
	// 	fetchOrganizations();
	// }, [user.data.organizationIds])



	const fetchOrganizations = async () => {
		console.log('fetchOrganizations');
		try {
			const newOrganizations: types.Organization[] = [];

			const userData = await userApi.fetchUserData();
			console.log(userData);

			// user.data.organizationIds.forEach(async (id) => {
			// 	const { data }: any = await organizationsApi.fetchbyId(id);
			// 	console.log(data);
			// 	newOrganizations.push(data);
			// })
			console.log(user.data.organizationIds);

			await Promise.all(user.data.organizationIds.map(async (id) => {
				const { data }: any = await organizationsApi.fetchbyId(id);
					// console.log(data);
					if (data) newOrganizations.push(data);
			}));

			newOrganizations.sort((a, b) => Date.parse(a.createdAt!) - Date.parse(b.createdAt!))

			setUserOrganizations(newOrganizations);
		} catch (error) {
			console.error(error);
		}
	}

	/** Create a new organization */
	const handleCreateOrganization = () => {
		setShowOrganizationForm(true);
	}

	const handleOrganizationFormSubmit = () => {
		setShowOrganizationForm(false);

		// dispatch(getSession());
		fetchOrganizations();
	}

	const handleCreateProject = (organization: types.Organization) => {
		console.log('Create project');
		console.log('Org:', organization);

		setSelectedOrg(organization);
		setShowProjectForm(true);
	}

	const handleProjectFormSubmit = () => {
		setShowProjectForm(false);

		dispatch(getSession());
	}

	return (
		<div className="AccountPage">
			<h2>Your Account</h2>
			<p>Welcome {user.data.firstName}!</p>

			<h3>Your Organizations</h3>
			<ul>
				{userOrganizations.length ? userOrganizations.map(organization =>
					<li key={organization.name}>
						<h4>{organization.name}</h4>
						{/* <p>{organization.description}</p> */}
						<ul>
							{organization.projects.map(project =>
								<Link to={`/project/${organization.uri}/${project.uri}`}>{project.name}</Link>	
							)}
						</ul>
						<button onClick={() => handleCreateProject(organization)}><FontAwesomeIcon icon={faPlus} /> New Project</button>
					</li>
				)
				: <li>No organizations found!</li>
				}
			</ul>

			<button onClick={handleCreateOrganization} className="btn btn-form"><FontAwesomeIcon icon={faPlus} /> Create New Organization</button>

			<Modal
				closeModal={() => setShowOrganizationForm(false)}
				modalIsOpen={showOrganizationForm}
				style={{backgroundColor: '#fbf9f7'}}
			>
				<OrganizationForm onSubmit={handleOrganizationFormSubmit} />
			</Modal>

			{selectedOrg && 
				<Modal
					closeModal={() => setShowProjectForm(false)}
					modalIsOpen={showProjectForm}
					style={{backgroundColor: '#fbf9f7'}}
				>
					<ProjectForm organization={selectedOrg} onSubmit={handleProjectFormSubmit} />
				</Modal>
			}

		</div>
	)
}

export default AccountPage;
