import { createAsyncThunk, createSlice, PayloadAction, ThunkDispatch, AnyAction } from '@reduxjs/toolkit'
import type { RootState } from 'app/store'
import GridLayout from 'react-grid-layout'

import * as layoutApi from 'api/layout';
import * as projectsApi from 'api/projects';
import * as organizationsApi from 'api/organizations';
import * as imageApi from 'api/image';

import * as types from 'types';

export interface ProjectState extends types.Project {
	// rows: types.GridRows;
	columns: types.GridColumns;
	totalColumns: number;
	// rowHeight: number;
	dragging: boolean;
	loading: boolean;
	/** Are nested grids still loading */
	nestedLoading: boolean;
	currentRequestId?: string;
	actionState: string;
	editMode: boolean;
	/** The arrow currently being drawn */
	currentArrow?: types.Arrow;
	orgLogo?: string;
}

const initialState: ProjectState = {
	_id: '',
	name: '',
	uri: '',
	description: '',
	organizationId: '',
	organizationName: '',
	authorizedUsers: [],
	createdAt: '',
	pages: [],
	headerRow: undefined,
	// rows: [],
	columns: {
		phases: {
			columns: [],
			dimensions: {},
		},
		subPhases: {
			columns: [],
			dimensions: {},
		}
	},
	totalColumns: 60,
	// rowHeight: 25,
	dragging: false,
	loading: true,
	nestedLoading: true,
	currentRequestId: undefined,
	actionState: 'normal',
	editMode: false,
	currentArrow: undefined,
	orgLogo: undefined,
	projectType: undefined,
}

export const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
		loadProject(state, action: PayloadAction<types.Project>) {
			// clear state first
			// for (let i in state) {
			// 	//@ts-ignore
			// 	state[i] = initialState[i];
			// }

			//load payload into state
			for (let i in action.payload) {
				//@ts-ignore
				state[i] = action.payload[i];
			}

      // state._id = action.payload._id;
      // state.authorizedUsers = action.payload.authorizedUsers;
      // state.columns = action.payload.columns;
      // state.createdAt = action.payload.createdAt;
      // state.description = action.payload.description; 
      // state.name = action.payload.name;
      // state.organizationId = action.payload.organizationId;
			// state.organizationName = action.payload.organizationName;
			// state.organizationLogoId = action.payload.organizationLogoId;
      // state.pages = action.payload.pages;
      // state.uri = action.payload.uri;
			// state.headerRow = action.payload.headerRow;
    },
		setRow(state, action: PayloadAction<types.GridRow>) {
			state.pages.forEach((page, pageIdx) => {
				if (page.name === action.payload.page) {
					page.rows.forEach((row, rowIdx) => {
						if (row.title === action.payload.title) {
							// set defaults
							state.pages[pageIdx].rows[rowIdx] = action.payload;
		
							// copy of items, can be modified
							const updatedItems: types.GridItem[] = [];

							// create layout from item data-grids
							const layout = action.payload.items.map((item, index) => {
								
								// if item has nested items, create layout for it
								const newNestedLayout: GridLayout.Layout[] = [];
								if (item.items) {
									item.items.forEach(item => {
										newNestedLayout.push(item['data-grid']);
									})
		
									updatedItems[index] = {...item, layout: newNestedLayout}
		
								} else {
									updatedItems[index] = {...item}
								}

								return item['data-grid'];
							});
		
							state.pages[pageIdx].rows[rowIdx].items = updatedItems;
							state.pages[pageIdx].rows[rowIdx].layout = layout;
						}
					})
				}
			})

		},
		updateItem(state, action: PayloadAction<types.GridItem>) {
			console.log('updateItem');
			const newItem = action.payload;
			const pageIdx = state.pages.findIndex(page => page.name === newItem.page);
			const rowIdx = state.pages[pageIdx].rows.findIndex(row => row.title === newItem.row);

			let itemIdx;
			let nestedIdx;

			// for non-nested rows
			if (state.pages[pageIdx].rows[rowIdx].type !== 'nested') {
				itemIdx = state.pages[pageIdx].rows[rowIdx].items.findIndex(item => item._id == newItem._id);

				if (newItem.new) {
					delete newItem.new;
					state.pages[pageIdx].rows[rowIdx].items.push(newItem);
					state.pages[pageIdx].rows[rowIdx].layout.push(newItem['data-grid']);
				} else if (newItem.deleted) {
					state.pages[pageIdx].rows[rowIdx].items.splice(itemIdx, 1);
				} else {
					state.pages[pageIdx].rows[rowIdx].items[itemIdx] = newItem;
				}

			// for nested rows
			} else {
				nestedIdx = state.pages[pageIdx].rows[rowIdx].items.findIndex(nestedItem => nestedItem.subPhase === newItem.subPhase);
				itemIdx = state.pages[pageIdx].rows[rowIdx].items[nestedIdx].items!.findIndex(item => item._id == newItem._id);

				if (newItem.new) {
					delete newItem.new;
					state.pages[pageIdx].rows[rowIdx].items[nestedIdx].items?.push(newItem);
					state.pages[pageIdx].rows[rowIdx].items[nestedIdx].layout?.push(newItem['data-grid']);
				} else if (newItem.deleted) {
					state.pages[pageIdx].rows[rowIdx].items[nestedIdx].items!.splice(itemIdx, 1);
				} else {
					state.pages[pageIdx].rows[rowIdx].items[nestedIdx].items![itemIdx] = newItem;
				}
			}
		},
		refreshRow(state, action: PayloadAction<types.GridRow>) {
			console.log('refreshRow');
			const pageIdx = state.pages.findIndex(page => page.name === action.payload.page);
			const rowIdx = state.pages[pageIdx].rows.findIndex(row => row.title === action.payload.title);
			state.pages[pageIdx].rows[rowIdx] = action.payload;
		},
		setColumns(state, action: PayloadAction<types.GridColumns>) {
			state.columns.phases = action.payload.phases;
			state.columns.subPhases = action.payload.subPhases;
		},
		setTotalColumns(state, action: PayloadAction<number>) {
			state.totalColumns = action.payload
		},
		// setRowHeight(state, action: PayloadAction<number>) {
		// 	state.rowHeight = action.payload
		// },
		setLoading(state, action: PayloadAction<boolean>) {
			state.loading = action.payload;
		},
		setNestedLoading(state, action: PayloadAction<boolean>) {
			state.nestedLoading = action.payload;
		},
		setDragging(state, action: PayloadAction<boolean>) {
			state.dragging = action.payload;
		},
		setActionState(state, action: PayloadAction<string>) {
			state.actionState = action.payload;
		},
		setEditMode(state, action: PayloadAction<boolean>) {
			state.editMode = action.payload;
		},
		setCurrentArrow(state, action: PayloadAction<types.Arrow>) {
			state.currentArrow = action.payload;
		},
		setOrgLogo(state, action: PayloadAction<string>) {
			state.orgLogo = action.payload;
		},
		clearProject(state) {
			// console.log('clearProject');
			// state = initialState;

			for (let i in initialState) {
				//@ts-ignore
				state[i] = initialState[i];
			}
		},
  },
})


/** Apply default properties to rows */
const initializeRows = (project: types.Project, defaultHeight: number) => {
	// Set up data before loading into state
	// default each row to minimized
	project.pages.forEach(page => {
		page.rows.forEach(row => {
			row.isMinimized = true;

			// initialize row height for nested rows
			if (row.type === 'nested') {
				row.height = calculateRowHeight(row, defaultHeight);
			}
		})
	})
}

/** 
 * Fetch project from database
 * note: org and project are uri values
 */
export const fetchProject = createAsyncThunk(
	'project/fetch',
	async ({ org, project }: { org: string, project: string }, ThunkAPI) => {
		const getState = ThunkAPI.getState as () => RootState;
		const dispatch = ThunkAPI.dispatch;

		try {
			const response = await projectsApi.fetch(org, project);
			const data: types.Project = response.data;

			console.log(data);

			initializeRows(data, getState().dimension.rowHeight);

			// load project into state
			dispatch(loadProject(data));

			const logo = data.organizationLogoId ? await imageApi.fetchImage(data.organizationLogoId) : undefined;
			if (logo) dispatch(setOrgLogo(logo.data.data));

			dispatch(setLoading(false));

		} catch (error) {
			console.error(error);
			return ThunkAPI.rejectWithValue(error.response.status);
		}
	}
);

/** Reset project to demo default state */
export const resetProjectToDefault = createAsyncThunk(
	'project/resetToDefault',
	async (urlParams: types.ProjectUrlParams, ThunkAPI) => {
		const getState = ThunkAPI.getState as () => RootState;
		// const { organizationId, _id } = getState().project;
		const dispatch = ThunkAPI.dispatch;

		// if url params are provided, then use those, otherwise use Ids
		// const org = urlParams ? urlParams.org : organizationId;
		// const project = urlParams ? urlParams.project : _id;

		try {
			// dispatch(setLoading(true));

			const { data } = await projectsApi.resetToDefault(urlParams.org, urlParams.project);

			initializeRows(data, getState().dimension.rowHeight);

			dispatch(loadProject(data));

			// dispatch(setLoading(false));

		} catch (error) {
			console.error(error);
			return ThunkAPI.rejectWithValue(error.response.data.message);
		}
	}
);

/** 
 * Update specified project 
 * * WIP
*/
// export const updateProject = createAsyncThunk(
// 	'project/update',
// 	async ({urlParams, updatedProject}: {urlParams: types.ProjectUrlParams, updatedProject: types.Project}, ThunkAPI) => {
// 		// const dispatch = ThunkAPI.dispatch;

// 		try {
// 			const { data } = await projectsApi.update(urlParams, updatedProject);
// 			console.log(data);

// 		} catch (error) {
// 			console.error(error);
// 			return ThunkAPI.rejectWithValue(error.response.data.message);
// 		}
// 	}
// )

/** Update row */
export const updateRow = createAsyncThunk(
	'project/updateRow',
	async (rowData: types.GridRow, ThunkAPI) => {
		// console.log('updateRow:', rowData);
		const dispatch = ThunkAPI.dispatch;
		const getState = ThunkAPI.getState as () => RootState;
		const orgId = getState().project.organizationId;
		const projectId = getState().project._id;

		dispatch(setRow(rowData));

		try {
			await projectsApi.update({orgId, projectId}, getState().project);

		} catch (error) {
			console.error(error);
			return ThunkAPI.rejectWithValue(error.response.data.message);
		}
	}
)


/** 
 * Update height of row containing nested grids, and toggle minimized
 * @param title The name of the row to update
 * @param isMinimized optional - row will keep existing value if not specified
 */
export const updateRowHeight = createAsyncThunk(
	'row/updateHeight',
	async ({ title, isMinimized }: { title: string, isMinimized?: boolean }, ThunkAPI) => {
		try {
			const getState = ThunkAPI.getState as () => RootState;
			const rowHeight = getState().dimension.rowHeight;
			const rowData = getState().project.pages.find(page => page.name === 'Experience Landscape')?.rows.find(row => row.title === title);

			if (!rowData) console.error('No rowData found!');
			else {
				const newRow = {...rowData};
				newRow.isMinimized = isMinimized !== undefined ? isMinimized : rowData.isMinimized;
				newRow.height = calculateRowHeight(newRow, rowHeight);
				// console.log(newRow.isMinimized);
				// console.log(title, isMinimized);
				// console.log(newRow);
				// console.log(newRow);
				ThunkAPI.dispatch(setRow(newRow));
			}
		} catch (error) {
			console.error(error);
		}
	}
)


/**
 * Calculate the height of the provided nested row based on data-grid of items
 * @param row - The row to find the height of
 * @param itemHeight - The default height of each item within a grid
 */
const calculateRowHeight = (row: types.GridRow, itemHeight: number): number => {
	if (row.type !== 'nested') throw new Error('This function requires a nested row');

	const { items } = row;
	let height = 0;

	// if maximized, expand nested grids to height of tallest grid
	if (!row.isMinimized) {
		let tallestHeight = 0;

		// loop through all nested grid items and get tallest y + h
		items.forEach(item => {
			item.items!.forEach(nestedItem => {
				const itemHeight = nestedItem['data-grid'].y + nestedItem['data-grid'].h;
				// console.log(nestedItem);
				if (itemHeight > tallestHeight) {
					tallestHeight = itemHeight;
				}
			})
		})

		height = tallestHeight * (itemHeight + 5) + 15; // 5 for item margin, 10 for grid margin

	// if minimized, loop through nested grids and reset height to default (6)
	} else {
		height = (6 * itemHeight) + (itemHeight + 5) + 5; // 5 for margin, 5 for grid margin
	}

	// console.log(height);
	return height;
}


/** Initiate interface for drawing an arrow between elements */
export const drawArrow = createAsyncThunk(
	'project/drawArrow',
	async (arrowData: { start: string }, ThunkAPI) => {
		console.log('drawArrow', arrowData);
		ThunkAPI.dispatch(setActionState('drawArrow'));
		ThunkAPI.dispatch(setCurrentArrow({
			start: arrowData.start,
			end: '',
			key: '',
		}));
	}
);


export const addItems = createAsyncThunk(
	'project/addItems',
	async (data: projectsApi.AddItemsData, ThunkAPI) => {
		console.log(data);
		const getState = ThunkAPI.getState as () => RootState;
		const orgId = getState().project.organizationId;
		const projectId = getState().project._id;

		try {
			const { data: createdItems } = await projectsApi.addItems({orgId, projectId}, data);


			if (createdItems.length === 1) {
				ThunkAPI.dispatch(updateItem({...createdItems[0], new: true}));
			}

			// console.log(createdItems);
			return createdItems;

		} catch (error) {
			console.error(error);
			return ThunkAPI.rejectWithValue(error.response.data.message);
		}
	}
);


/** Edit specific item */
export const editItem = createAsyncThunk(
	'project/editItem',
	async (data: projectsApi.EditItemData, { getState, rejectWithValue, dispatch }) => {
		console.log('editItem:', data);
		
		const state = getState() as RootState;
		const orgId = state.project.organizationId;
		const projectId = state.project._id;
		// const { field, value, property } = data;
		console.log(data);

		/** The original item (to restore in state if db update fails) */
		const item = findItem(state.project, data);

		try {
			// edit item in database
			const fetchedItem = await projectsApi.editItem({orgId, projectId}, data);
			console.log(fetchedItem.data);

			dispatch(updateItem(fetchedItem.data));

		} catch (error) {
			console.error(error);

			// reset item to original if update in database failed
			if (item) dispatch(updateItem(item));

			return rejectWithValue(error.response.data.message);
		}
	}
)

/** Find and return the specified item */
const findItem = (project: types.Project, data: projectsApi.EditItemData) => {
	let item: types.GridItem | undefined;
	
	const foundRow = project.pages
		.find(dbPage => dbPage.name === data.page)?.rows
		.find(dbRow => dbRow.title === data.row);

	if (foundRow) {
		// for non-nested rows
		if (foundRow.type !== 'nested') {
			item = foundRow.items.find(dbItem => dbItem._id == data._id);
	
		// for nested rows
		} else {
			item = foundRow.items
				.find(nestedItem => nestedItem.subPhase === data.subPhase)?.items
				?.find(dbItem => dbItem._id == data._id);
		}
	}

	return item;
}



export const {
	setRow,
	loadProject,
	setColumns,
	setLoading,
	setNestedLoading,
	setDragging,
	setActionState,
	setCurrentArrow,
	setEditMode,
	setOrgLogo,
	updateItem,
	clearProject,
	refreshRow,
} = projectSlice.actions;

// export const selectRows = (state: RootState, row: string) => {
// 	return state.project.rows
// };
export const selectProject = (state: RootState) => state.project;
export const selectPages = (state: RootState) => state.project.pages;
// export const selectRows = (state: RootState) => state.project.rows;
// export const selectAllRows = (state: RootState) => state.project.rows;
export const selectColumns = (state: RootState) => state.project.columns;
export const selectTotalColumns = (state: RootState) => state.project.totalColumns
// export const selectDefaultRowHeight = (state: RootState) => state.project.rowHeight
export const selectRowLoading = (state: RootState) => state.project.loading;
export const selectNestedLoading = (state: RootState) => state.project.nestedLoading;
export const selectDragging = (state: RootState) => state.project.dragging;
export const selectActionState = (state: RootState) => state.project.actionState;
export const selectEditMode = (state: RootState) => state.project.editMode;
export const selectCurrentArrow = (state: RootState) => state.project.currentArrow;

export default projectSlice.reducer