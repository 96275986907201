import React, { useState, useEffect } from 'react'

import Popup from '.';

// Redux
import { useAppSelector, useAppDispatch } from 'app/hooks'
import { updateRow } from 'slices/projectSlice';
import { selectSize } from 'slices/dimensionSlice';

import * as types from "types";
import './style.scss';

type PopupProps = {
	rowData: types.GridRow;
	parentId: string;
	containerId: string;
	popupPosition: {x: number, y: number};
	closePopup: () => void;
}

const ArrowPopup = ({ rowData, parentId, containerId, popupPosition, closePopup }: PopupProps) => {
	// const parent = document.getElementById(parentId);
	// const popupRoot = document.getElementById(row);
	// const popupRoot = document.getElementById("root");

	// const [style, setStyle] = useState({});
	// const [loading, setLoading] = useState(true);

	const size = useAppSelector(selectSize);
	const dispatch = useAppDispatch();

	// useEffect(() => {
	// 	setStyle({ 
	// 		top: popupPosition.y,
	// 		left: popupPosition.x,
	// 	})

	// 	setLoading(false);
	// }, [rowData.layout, size?.width, popupPosition])

	/** Delete the arrow */
	const handleDelete = () => {
		console.log('delete', parentId);
		const updatedRow = {...rowData};
		const updatedArrows = updatedRow.arrows!.filter(arrow => arrow.key !== parentId);
		updatedRow.arrows = updatedArrows;
		
		dispatch(updateRow(updatedRow));
	}

	return (
		// loading ? <div></div> :
			<Popup
				// style={style}
				// popupRoot={popupRoot!}
				parentId={parentId}
				closePopup={closePopup}
			>
				<button onClick={handleDelete} className='btn'>Delete arrow</button>
			</Popup>
	)
}

export default ArrowPopup
