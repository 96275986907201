import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { createPortal } from 'react-dom';

import { useWindowDimensions, useOutsideCallback } from 'app/hooks';

// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

// images
import { ReactComponent as IconXCircle } from 'assets/images/icon-x-circle.svg';


// Redux
// import { useAppSelector, useAppDispatch } from 'app/hooks'
// import { setCurrentPopup, selectCurrentPopup } from 'slices/miscSlice';
// import { selectScale } from 'slices/dimensionSlice';

import './style.scss';

type PopupProps = {
	children: React.ReactNode;
	// style: React.CSSProperties;
	/** The container to place the popup inside of */
	// popupRoot: HTMLElement;
	/** The ID of the item this popup is attached to */
	parentId: string;
	closePopup: () => void;
	controls?: React.ReactNode;
	// ref?: React.MutableRefObject<HTMLDivElement | null>; 
}

const Popup = ({ children,/*  style, */ /* popupRoot, */ parentId, closePopup/* , ref */, controls }: PopupProps) => {
	const ref = useRef<HTMLDivElement>(null);
	useOutsideCallback(ref, closePopup);

	const { height, width } = useWindowDimensions();
	
	// console.log('popupRoot', popupRoot);
	const parent = document.getElementById(parentId);
	// const parentContainer = parent?.parentElement;
	

	const popupRoot = document.getElementById('root');

	// const popupRoot = document.getElementById(row);
	// const currentPopup = useAppSelector(selectCurrentPopup);
	// const scale = useAppSelector(selectScale);
	// const dispatch = useAppDispatch();

	const [thisStyle, setThisStyle] = useState<React.CSSProperties>({});

	// useEffect(() => {
	// 	dispatch(setCurrentPopup({ popupRootId: 'root', parentId: parentId }));
	// 	// dispatch(setCurrentPopup({ popupRootId: popupRoot.id, parentId: parentId }));
	// }, [])

	/** True if this component is on it's first render */
  // const firstUpdate = useRef(true);

  // useLayoutEffect(() => {
	// 	if (firstUpdate.current) {
	// 		firstUpdate.current = false;
	// 		return;
	// 	}
	// 	if (currentPopup?.parentId !== parentId) closePopup();
	// }, [currentPopup?.parentId])


	// Get location/dimensions of popup and change position if overflowing edge of window
	useLayoutEffect(() => {
		/* Old code - saving for posterity (and for future reference)
		// popupRoot.style.zIndex = '501';
		// console.log(ref.current);

		// const rect = ref.current?.getBoundingClientRect();
		// console.log(rect);

		// const container = document.querySelector('.rows');
		// const containerRect = container?.getBoundingClientRect();

		// const parent = document.getElementById(parentId);
		// const parentRect = parent?.getBoundingClientRect();

		// console.log(rect, containerRect, parentRect);
		
		// if (rect && containerRect && parentRect) {
		// 	const newStyle = {...style};
		// 	console.log(newStyle);
		// 	const transforms = [0, 0];

		// 	// pull out transform values if they exist
		// 	const newTransforms = newStyle.transform
		// 		? newStyle.transform
		// 				.split(",")
		// 				.map((transform) => parseInt(transform.replace(/\D/g, "")))
		// 		: [0,0];

		// 	newTransforms.forEach((transform, i) => {
		// 		transforms[i] = transform
		// 	})

		// 	let transformX = transforms[0] + (parentRect.width / scale) + 5;
		// 	let transformY = transforms[1] + 10;

		// 	const left = style.left ? style.left : 0; 
		// 	const top = style.top ? style.top : 0;

		// 	// console.log(style.left);

		// 	// adjust horizontal position if needed
		// 	//@ts-ignore
		// 	if (rect.right + transformX + left > containerRect.right) {
		// 		transformX = transforms[0] - (rect.width / scale) - 5;
		// 	} 

		// 	console.log(rect.bottom + transformY);
		// 	console.log(containerRect.bottom);

		// 	// adjust vertical position if needed
		// 	//@ts-ignore
		// 	if (rect.bottom + transformY + top > containerRect.bottom) {
		// 		transformY = transforms[1] - (rect.height / scale) + 5;
		// 	}

		// 	newStyle.transform = `translate(${transformX}px, ${transformY}px)`

		// 	console.log(newStyle);

		// 	setThisStyle(newStyle)
		// } */
	
		const rect = ref.current?.getBoundingClientRect();
		// const parent = document.getElementById(parentId);
		const parentRect = parent?.getBoundingClientRect();

		// const parentContainer = parent?.parentElement;
		// const parentContainerRect = parentContainer?.getBoundingClientRect();
		// console.log(parentContainerRect);

		if (rect && parentRect/*  && parentContainerRect */) {
			let translateX = parentRect.right /* - parentContainerRect.left */ + 5;
			let translateY = parentRect.top /* - parentContainerRect.top */ + 10;

			
			// console.log(translateX, rect.width, width);

			// adjust horizontal position if overflowing screen
			if (translateX + rect.width > width) {
				console.log('horizontal overflow');
				translateX = parentRect.left - rect.width - 5;
			} 

			// adjust vertical position if overflowing screen
			if (translateY + rect.height > height) {
				console.log('vertical overflow');
				translateY = parentRect.top - rect.height + 10;
			}

			const newStyle = {
				transform: `translate(${translateX}px, ${translateY}px)`
			};
	
			setThisStyle(newStyle);
		}
	}, [width, height])


	return createPortal(
		<div className="Popup draggableCancel" style={thisStyle} id={`popup-${parentId}`} ref={ref}>
			<div className="content">
				<div className="controls">
					{controls}
					
					<button 
						className="btn btn-control btn-close"
						title="Close"
						aria-label="Close"
						onClick={closePopup}
					>
						{/* <FontAwesomeIcon icon={faTimes} /> */}
						<IconXCircle />
					</button>
				</div>


				{children}

			</div>
		</div>
		, popupRoot!
		// , parentContainer!
	)
}

export default Popup
